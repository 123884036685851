.bg-color {
  background-color: #fff;
  padding: 20px;
  .addresheading {
    font-size: 21px;
    font-weight: 900;
    text-transform: capitalize;
  }

  .addreschanges-formwrapp {
    width: 100%;
    label {
      font-weight: bold;
      font-size: 14px;
      display: block;
      padding-bottom: 8px;
    }

    .countryTab-wrapper {
      width: 100%;
      height: 50px;
      margin-bottom: 20px;
      background-color: #f3f3f3;
      border: none;
      outline: none;
      padding: 8px 16px;
      font-size: 13px;
      -webkit-appearance: none;
      appearance: none;
    }

    .select-wrapper {
      position: relative;

      &::after {
        content: ">";
        font-size: 16px;
        top: 14px;
        right: 15px;
        transform: rotateZ(90deg);
        position: absolute;
      }
    }

    .Addres-input-wrapp {
      width: 100%;
      height: 50px;
      margin-bottom: 20px;
      background-color: #f3f3f3;
      border: none;
      outline: none;
      padding: 8px 16px;
      font-size: 13px;
    }
    .Addres-input-numwrapp {
      width: 100%;
      height: 50px;
      margin-bottom: 20px;
      background-color: #f3f3f3;
      border: none;
      outline: none;
      padding: 8px 16px;
      font-size: 13px;
    }
    .Addres-input-wrapp::-webkit-outer-spin-button,
    .Addres-input-wrapp::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    .Addres-input-numwrapp::-webkit-outer-spin-button,
    .Addres-input-numwrapp::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    .Preferencesuse {
      width: 100%;
      font-size: 16px;
    }
    .addinstructionsline {
      font-size: 18px;
      font-weight: bold;
    }
  }
  .AddAddressbtn {
    background-color: #f19c43;
    color: #fff;
    padding: 8px 16px;
    border-radius: 70px;
    outline: none;
    font-weight: bold;
    border: none;
    width: 20%;
  }
}

@media (max-width: 767px) {
  .bg-color {
    background-color: #fff;
    padding: 10px;
    .addresheading {
      font-size: 16px;
      font-weight: 900;
      text-transform: capitalize;
    }

    .addreschanges-formwrapp {
      width: 100%;

      .Preferencesuse {
        font-size: 10px;
      }
      .addinstructionsline {
        font-size: 14px;
      }
    }
    .AddAddressbtn {
      width: 100%;
    }
  }
}
