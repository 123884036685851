.bestSeller-wrapp {
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  .Side-benner-wrapper {
    width: 20%;
    height: 423px;
    border-radius: 10px;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .addto-cart-btn-already {
    background-color: transparent;
    border: 1px solid #f4a34d;
    border-radius: 20px;
    padding: 5px 20px;
    outline: none;
    width: 100%;
    color: #f4a34d;
    font-weight: 500;
    margin-top: 20px;
  }
  .bestSells-wrappe {
    padding: 0 0px 0 15px;
    width: 80%;

    .topline-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding-left: 15px;
      .BestSellerstag {
        font-size: 24px;
        color: #000;
        font-weight: 500;
        text-transform: uppercase;
      }
      .viewallbtn {
        background-color: #f4a34d;
        color: #fff;
        text-transform: uppercase;
        font-size: 13px;
        padding: 10px 26px;
        border-radius: 50px;
        border: none;
        outline: none;

        cursor: pointer;
      }
    }
  }
  .web-responsive {
    .slick-arrow {
      display: none !important;
    }
    .productitems-wraper {
      // border: 1px solid #f6f6f6;
      margin-top: 15px;
      .product-wrapper {
        // border-right: 2px solid #f6f6f6;
        border: 1px solid #f6f6f6;
        position: relative;
        height: 370px;
        margin-left: 13px;
        .hover-wish-list-wrappper {
          position: absolute;
          cursor: pointer;
          width: 25%;
          height: 50%;
          padding: 10px;
          transition: 2s;
          right: 0;
          display: none;
          .wish-list-imgs {
            float: right;
            img {
              margin-bottom: 10px;
            }
          }
        }

        .product-imgs-wrap {
          // padding: 30px;
          align-items: center;
          height: 200px;
          width: 100%;
          cursor: pointer;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .items-content-wrappers {
          padding: 15px;
          cursor: pointer;
          .items-name {
            font-size: 14px;
            font-weight: 500;
            height: 40px;
            margin-bottom: 10px;
            display: -webkit-box;
            overflow: hidden;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .instock {
            font-size: 11px;
            text-transform: uppercase;
            font-weight: bold;
            color: #f4a34d;
            margin-bottom: auto;
          }
          .last-price {
            font-size: 15px;
            color: #c3c8c9;
            margin-right: 10px;
          }
          .latest-price {
            font-size: 16px;
            font-weight: bold;
            color: #ed1c24;
          }
          .addto-cart-btn {
            background-color: transparent;
            border: 1px solid #f4a34d;
            border-radius: 20px;
            padding: 5px 20px;
            outline: none;
            width: 100%;
            color: #f4a34d;
            font-weight: 500;
            margin-top: 15px;
          }

          .addto-cart-btn .loader-spinner .loading-gif {
            animation-name: spin;
            animation-duration: 2000ms;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
          }
          .addto-cart-btn:hover {
            background-color: #f4a34d;
            color: #fff;
          }
        }
      }

      .product-wrapper:hover {
        .hover-wish-list-wrappper {
          display: block;
        }
      }
    }
  }
  .mobresponsive-Slide {
    display: none;
    .slick-arrow {
      display: none !important;
    }
  }
}

@media (max-width: 1200px) {
  .bestSeller-wrapp {
    flex-direction: column;
    .slick-dots {
      display: none !important;
    }
    .Side-benner-wrapper {
      // order: 2;
      // margin-top: 50px;
      // width: 100%;
      // img {
      //   width: 100%;
      // }
      display: none;
    }
    .bestSells-wrappe {
      order: 1;
      padding: 0 15px;
      width: 100%;
    }
  }
}

@media (max-width: 992px) {
  .bestSeller-wrapp {
    margin-top: 50px;
    margin-bottom: 50px;
    display: flex;
    justify-content: space-between;

    .bestSells-wrappe {
      padding: 0px;
      width: 100%;

      .topline-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .BestSellerstag {
          font-size: 24px;
          color: #000;
          font-weight: 500;
        }
        .viewallbtn {
          background-color: #f4a34d;
          color: #fff;
          text-transform: uppercase;
          font-size: 13px;
          padding: 6px 20px;
          border-radius: 50px;
          border: none;
          outline: none;
          cursor: pointer;
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .bestSeller-wrapp {
    .web-responsive {
      .productitems-wraper {
        .product-wrapper {
          height: auto;
          margin-left: 0px;
          .product-imgs-wrap {
            img {
              object-fit: contain;
            }
          }
          .items-content-wrappers {
            padding: 15px;

            .addto-cart-btn {
              padding: 5px 8px;
            }
          }
        }
      }
    }
    .bestSells-wrappe {
      .topline-wrapper {
        padding-left: 0px;
        .BestSellerstag {
          font-size: 24px;
        }
      }
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
