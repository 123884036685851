.checkoutbg {
  background-color: #f8f8f8;
  padding-top: 50px;
  padding-bottom: 150px;

  .yourAll_address {
    margin-bottom: 20px;
    margin-top: 20px;
    .billingAddressList {
      width: 100%;
      border-radius: 10px;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      label {
        width: 30%;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        cursor: pointer;
        input {
          margin-right: 5px;
          cursor: pointer;
        }
        h5 {
          margin-bottom: 0;
        }
      }
      h5 {
        font-size: 18px;
      }
    }
    select {
      width: 50%;
      height: 35px;
      border-radius: 5px;
      outline: none;
    }
    .save-Address-wrapper {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      .save-Address-wrapp {
        padding: 15px;
        border: 1px solid #e1e0e0;
        border-radius: 5px;
        width: 47.5%;
        margin-right: 15px;
        margin-bottom: 15px;
        cursor: pointer;
        h6 {
          font-size: 14px;
          margin-bottom: 3px;
        }
        .delevar-address {
          margin-bottom: 0;
          font-size: 14px;
        }
      }
      .save-Address-wrappActive {
        padding: 15px;
        border: 1px solid #f4a34d;
        border-radius: 5px;
        width: 50%;
        margin-right: 20px;
        cursor: pointer;
        h6 {
          font-size: 14px;
          margin-bottom: 3px;
        }
        .delevar-address {
          margin-bottom: 0;
          font-size: 14px;
        }
      }
    }
  }

  .checkout-form-wrapper {
    display: flex;
    justify-content: space-between;
    .billingFormwrapper {
      width: 60%;

      p {
        font-size: 25px;
        font-weight: 500;
      }

      .form-wrapper {
        background-color: #fff;
        padding: 20px;
        border: 1px solid #e1e0e0;
        .form-wrapperInput {
          width: 100%;
          height: 50px;
          padding: 8px 16px;
          border: 1px solid #e6e6e6;
          margin-bottom: 20px;
          outline: none;
          cursor: pointer;

          -webkit-appearance: none;
          appearance: none;

          &::placeholder {
            font-size: 14px;
            color: #7b7b7b;
          }
        }
        .select-wrapper {
          position: relative;

          &::after {
            content: ">";
            font-size: 16px;
            top: 14px;
            right: 15px;
            transform: rotateZ(90deg);
            position: absolute;
          }
        }
        .form-wrapperInputTextArea {
          width: 100%;
          height: 150px;
          padding: 8px 16px;
          border: 1px solid #e6e6e6;
          margin-bottom: 20px;
          outline: none;
          cursor: pointer;

          &::placeholder {
            font-size: 14px;
            color: #7b7b7b;
          }
        }
      }
      .Shiptowrapper {
        margin-top: 30px;
        .checkdifferent {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          input {
            margin-right: 10px;
          }
          span {
            font-size: 25px;
            font-weight: 500;
          }
        }

        .yourorder {
          width: 100%;
          background-color: #fff;
          // padding: 20px;
          select {
            width: 100%;
            height: 50px;
            padding: 8px 16px;
            border: 1px solid #e6e6e6;
            margin-bottom: 20px;
            outline: none;
            cursor: pointer;
            font-size: 16px;
          }
          p {
            font-size: 14px;
            color: #7b7b7b;
          }
          textarea {
            width: 100%;
            padding: 20px;
            &::placeholder {
              font-size: 14px;
              color: #7b7b7b;
            }
          }
        }
      }
    }
    .Yourorderwrap {
      width: 37%;
      p {
        font-size: 25px;
        font-weight: 500;
      }

      .subtotal-wrapp {
        border: 1px solid #e1e0e0;
        background-color: #fff;
        .products-tag {
          display: flex;
          padding: 15px 20px;
          border-bottom: 1px solid #e6e6e6;
          justify-content: space-between;
          span {
            font-size: 16px;
            margin-bottom: 0px;
            font-weight: 500;
          }
        }
        .products-tagLine {
          padding: 10px 20px;
          border-bottom: 1px solid #e6e6e6;
          justify-content: space-between;
          .products-tagLineStore {
            display: flex;
            justify-content: space-between;
            margin-top: 10px;
            .tagLineStore-item {
              padding: 8px 20px;
              background-color: #0075be;
              border-radius: 20px;
              color: #fff;
              cursor: pointer;
              .tagLineStore-title {
                margin-right: 10px;
              }
            }
            .tagLineStore-itemActive {
              padding: 8px 20px;
              background-color: #f4a34d;
              border-radius: 20px;
              color: #fff;
              cursor: pointer;
              .tagLineStore-title {
                margin-right: 10px;
              }
            }
          }
          span {
            font-size: 16px;
            margin-bottom: 0px;
            font-weight: 500;
          }
        }
        .carts-btn-wrapper {
          // display: flex;
          justify-content: space-between;
          padding: 20px 20px;
          border-bottom: 1px solid #e6e6e6;
          .Applycoupon {
            font-weight: 500;
          }
          .apply-wrap {
            display: flex;
            align-items: center;
            margin-top: 10px;

            input {
              width: 85%;
              height: 45px;
              background-color: #f5f5f5;
              outline: none;
              padding: 8px 16px;
              border: 1px solid #c2c2c2;
              &::placeholder {
                color: #9b9b9b;
                font-size: 15px;
              }
            }
            button {
              height: 45px;
              width: 130px;
              background-color: #ed1c24;
              font-size: 11px;
              border: none;
              outline: none;
              padding: 8px 16px;
              color: #fff;
              cursor: pointer;
            }
            button .loader-spinner .loading-gif {
              animation-name: spin;
              animation-duration: 2000ms;
              animation-iteration-count: infinite;
              animation-timing-function: linear;
            }
          }
        }
        .products-tagger {
          border-bottom: 1px solid #e6e6e6;
          padding: 15px 20px;
          .products-tagwrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 10px 0px;
            .product_left {
              display: flex;
              align-items: center;
              width: 80% !important;
              img {
                width: 50px;
                height: 50px;
                object-fit: cover;
                margin-right: 15px;
              }
            }
            .product_leftPrice {
              width: 20%;
              text-align: right;
            }
          }
        }

        .Touchtag {
          display: flex;
          padding: 15px 0;
          border-bottom: 1px solid #e6e6e6;
          justify-content: space-between;
          span {
            font-size: 14px;
            margin-bottom: 10px;
            color: #8b8b8b;
          }
        }
        .Shipping-tag {
          display: flex;
          flex-direction: column;
          padding: 15px 0;
          border-bottom: 1px solid #e6e6e6;
          justify-content: space-between;
          .applyvoucherwrapper {
            width: 100%;
            input {
              border: 1px solid #6666;
              padding: 7px;
              border-radius: 5px;
              border-radius: 5px 0px 0px 5px;
              width: 80%;
            }
            button {
              border-style: none;
              background-color: #f4a34d;
              color: white;
              border-radius: 0 5px 5px 0;
              padding: 8px;
              width: 20%;
            }
          }
          .shiphead {
            font-size: 16px;
            font-weight: 500;
            padding: 0 5px;
          }
          span {
            font-size: 12px;
            margin-bottom: 10px;
            font-weight: 500;
            padding: 0 5px;
          }

          .shipngtagdatawrappers {
            display: flex;
            flex-direction: column;
            padding: 10px;
            border: 1px solid #6666;
            border-radius: 6px;
          }
          .paymenttags {
            display: flex;
            margin-bottom: 10px;
            color: #666;
            padding-left: 10px;
            align-items: center;
          }
          .hedingofvender {
            font-size: 12px !important;
            margin: 10px;
          }
          .pricedttiles {
            font-size: 11px;
            margin-bottom: 0px;
            font-weight: 500;
          }
        }
        .Total-tag {
          display: flex;
          padding: 10px 0;
          align-items: center;
          justify-content: space-between;
          span {
            font-size: 16px;
            margin-bottom: 0px;
            font-weight: 500;
          }
          label {
            font-size: 18px;
            font-weight: 500;
            margin-bottom: 0px;
            color: #15242e;
          }
        }

        .Paymentrefundtag {
          display: flex;
          padding: 10px 0;
          // align-items: center;
          // justify-content: space-between;
          .ifanycartline {
            padding-right: 10px;
            font-size: 11px;
            margin-bottom: 0px;
            font-weight: 500;
            width: 100%;
          }
          .storeradios-line {
            display: flex;
            flex-direction: row;
            width: 100%;
            flex-direction: column;
            label {
              justify-content: space-evenly;
              display: flex;
              align-items: center;
              // padding: 0px 15px;
              font-size: 11px;
              justify-content: space-between;
              input {
                margin: 0 5px;
              }
            }
          }
        }

        .payments-wrappe {
          padding: 10px 20px;
          .stripcardaddbtnwrpp {
            width: 30%;
            display: flex;
            justify-content: flex-end;
            .stripe-btn {
              border-radius: 15px;
              padding: 10px 15px;
              outline: none;
              width: 100%;
              border: none;
              text-transform: uppercase;
              font-size: 10px;
              background-color: #f4a34d;
              color: #fff;
            }
          }
          .paymenttags {
            display: flex;
            padding: 10px 0;
            align-items: center;
            input {
              margin-right: 10px;
              cursor: pointer;
            }
            span {
              font-size: 14px;
              font-weight: 500;
              cursor: pointer;
            }
          }

          .comment-wrapper {
            position: relative;
            .strippayments-wrapper {
              padding: 10px;
              font-size: 14px;
              background-color: #f3f3f3;
              display: flex;
              justify-content: space-between;
              align-items: flex-start;
              .cardnamestrip-wrapp {
                width: 60%;
              }
            }
            p {
              padding: 10px;
              font-size: 14px;
              background-color: #f3f3f3;
            }

            .property-down-aroww {
              position: absolute;
              top: -16px;
              left: 30px;
              color: #f3f3f3;

              transform: rotate(180deg);
            }

            .bootams-item:hover .property-down-aroww i:hover {
              color: #0075be;
            }

            .property-down-aroww i {
              font-size: 38px;
            }
          }
        }
      }
      .place-btn {
        border-radius: 30px;
        padding: 15px 16px;
        margin: auto;
        width: 100%;
        outline: none;
        border: none;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 600;
        background-color: #f4a34d;
        color: #fff;
        margin-top: 15px;
      }
    }
  }
}

@media (max-width: 1200px) {
  .products-tagLine {
    .products-tagLineStore {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      .tagLineStore-item {
        margin-bottom: 10px;
      }
      .tagLineStore-itemActive {
        margin-bottom: 10px;
      }
    }
  }
}

@media (max-width: 767px) {
  .checkoutbg {
    padding-top: 30px;
    padding-bottom: 150px;

    .checkout-form-wrapper {
      display: flex;
      flex-direction: column;
      .Shiptowrapper {
        .checkdifferent {
          span {
            font-size: 16px !important;
          }
        }
      }
      .billingFormwrapper {
        width: 100%;
      }
      .Yourorderwrap {
        width: 100%;
        margin-top: 20px;

        .subtotal-wrapp {
          .Paymentrefundtag {
            .ifanycartline {
              font-size: 9px;
            }
          }
        }
      }
    }
  }
}
