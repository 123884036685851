.changepaswordwrapper {
  background-color: #f6f6f6;
  padding: 50px 0 150px 0;
  .changesinputwrapper {
    display: flex;
    align-content: center;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    .changeinputformwrapp {
      border-radius: 10px;
      padding: 20px 20px 20px 20px;
      margin-top: 0;
      box-shadow: 0 7px 32px rgb(191 198 205 / 35%);
      background: none;
      width: 35%;
      flex-direction: column;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      h3 {
        font-size: 20px;
        margin-bottom: 20px;
      }
      .inputswrpappers {
        width: 80%;
        margin-bottom: 30px;
        margin-top: 10px;
        .newpwdinptwrapp {
          width: 100%;
          padding: 10px 10px 10px 20px;
          border-radius: 5px;
          outline: none;
          border: 1px solid #eceff0;
          background-color: #f9f9f9;
          &::placeholder {
            font-size: 11px;
          }
        }
      }
      .chanegpwdbtn {
        background-color: #1abf5d;
        width: 40%;
        padding: 8px 16px;
        color: #fff;
        border: none;
        font-weight: 700;
        margin-top: 30px;
        border-radius: 50px;
        outline: none;
        cursor: pointer;
      }
    }
  }
}

@media (max-width: 768px) {
  .changepaswordwrapper {
    background-color: #f6f6f6;
    padding: 50px 0 100px 0;
    .changesinputwrapper {
      .changeinputformwrapp {
        width: 100%;
      }
    }
  }
}
