.conect-bg {
  background-color: #f8f8f8;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 100px;

  .info-bg {
    background-color: #f4a34d;
    height: 100%;
    padding: 50px;
    .location-wrapper {
      display: flex;
      margin-bottom: 30px;
      align-items: center;
    }
    .location-texts {
      ul {
        list-style: none;
        margin: 0 0 0 0px;
        padding: 0 0 0 20px;
        li {
          font-size: 13px;
          a {
            color: #fff;
            text-decoration: none;
            font-weight: bold;
          }
        }
        .locationt-hading {
          font-size: 15px;
          color: #fff;
        }
      }
    }
    .social-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 200px;

      ul {
        list-style: none;
        text-align: center;
        margin: 0px;
        padding: 0;
        li {
          float: left;
          width: 80px;
          a {
            text-decoration: none;
            color: #fff;
          }
          i {
            font-size: 31px;
          }
        }
      }
    }
  }
  .contact-form-wrapper {
    background-color: #fff;
    padding: 30px;
    height: 100%;
    .contact-text {
      font-size: 16px;
      font-style: italic;
      color: #f4a34d;
      font-weight: 400;
      margin-bottom: 0px;
    }
    .Get-in-touch {
      font-size: 41px;
      font-weight: bold;
      margin-bottom: 30px;
    }

    .contecttextinpt {
      height: 50px;
      width: 100%;
      margin-bottom: 20px;
      background-color: #f3f4f7;
      border: 4px solid#eeeef0;
      padding: 8px 16px;
      font-size: 14px;
      border: none;
      outline: none;
    }

    .mobnumcon::-webkit-outer-spin-button,
    .mobnumcon::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    .contect-form-txtrea {
      background-color: #f3f4f7;
      border: none;
      width: 100%;
      outline: none;
      padding: 20px;
      font-size: 14px;
    }
    .send-btn {
      background-color: #f4a34d;
      border-radius: 50px;
      padding: 10px 16px;
      font-size: 18px;
      outline: none;
      color: #fff;
      margin-top: 30px;
      margin-bottom: 20px;
    }
  }
}

@media (max-width: 1400px) {
  .conect-bg {
    .info-bg {
      padding: 45px;
      .social-wrapper {
        ul {
          li {
            float: left;
            width: 70px;
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .conect-bg {
    .contact-form-wrapper {
      .Get-in-touch {
        font-size: 30px;
        margin-bottom: 20px;
      }

      .send-btn {
        font-size: 16px;
      }
    }
  }
}
@media (max-width: 1024px) {
  .conect-bg {
    .info-bg {
      padding: 30px;
      .location-wrapper {
        margin-bottom: 30px;
      }

      .social-wrapper {
        ul {
          li {
            float: left;
            width: 70px;
          }
        }
      }
    }

    .contact-form-wrapper {
      .Get-in-touch {
        font-size: 28px;
      }

      .send-btn {
        font-size: 16px;
      }
    }
  }
}
@media (max-width: 767px) {
  .conect-bg {
    padding-top: 20px;
    #formSec {
      order: 1;
    }
    #infoSec {
      order: 2;
      margin-top: 15px;
    }
    .info-bg {
      .social-wrapper {
        margin-top: 100px;
        margin-bottom: 80px;
        ul {
          li {
            float: left;
            width: 65px;
          }
        }
      }
    }
  }
}

@media (max-width: 568px) {
  .conect-bg {
    padding-top: 20px;
    padding-bottom: 100px;

    .info-bg {
      padding: 15px;
      .location-wrapper {
        margin-bottom: 30px;
      }
      .location-texts {
        ul {
          li {
            font-size: 13px;
          }
        }
      }
      .social-wrapper {
        margin-top: 70px;
        ul {
          li {
            float: left;
            width: 65px;
          }
        }
      }
    }
    .contact-form-wrapper {
      padding: 15px;
      .contact-text {
        font-size: 13px;
      }
      .Get-in-touch {
        font-size: 24px;
        margin-bottom: 20px;
      }

      .send-btn {
        font-size: 11px;
        margin-top: 20px;
      }
    }
  }
}
