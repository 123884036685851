.best-dealsitems-wrappers {
  color: #000;
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  .bidproduct-wrapp {
    border: 1px solid #f6f6f6;
    margin-top: 15px;
    // width: 400px !important;
    background-color: #fff;
    position: relative;
    margin-right: 17px;
    width: 18%;
    .hover-wish-list-wrappper {
      position: absolute;
      cursor: pointer;
      width: 25%;
      height: 50%;
      padding: 10px;
      transition: 2s;
      right: 0;
      display: none;
      .wish-list-imgs {
        float: right;
        img {
          margin-bottom: 10px;
        }
      }
    }
    .product-img-wrapper {
      align-items: center;
      width: 100%;
      height: 200px;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      .hover-wish-list-wrappper {
        position: absolute;
        cursor: pointer;
        width: 25%;
        height: 50%;
        padding: 10px;
        transition: 2s;
        right: 0;
        top: 0;
        display: none;
        .wish-list-imgs {
          float: right;
          img {
            margin-bottom: 10px;
          }
        }
      }
    }
    .product-description-wrapper {
      padding: 15px;
      cursor: pointer;
      .items-name {
        font-size: 14px;
        font-weight: 500;
        height: 40px;
        color: #000;
        margin-bottom: 10px;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .instock {
        font-size: 11px;
        text-transform: uppercase;
        font-weight: bold;
        color: #f4a34d;
        margin-bottom: auto;
      }
      .last-price {
        font-size: 15px;
        color: #000;
        margin-right: 10px;
        font-weight: 500;
      }
      .bidamount {
        font-size: 16px;
        font-weight: bold;
        color: #ed1c24;
      }
      .bid-now-btn {
        background-color: transparent;
        border: 1px solid #f4a34d;
        border-radius: 20px;
        padding: 5px 20px;
        outline: none;
        width: 100%;
        color: #f4a34d;
        font-weight: 500;
        margin-top: 20px;
      }

      .bid-now-btn .loader-spinner .loading-gif {
        animation-name: spin;
        animation-duration: 2000ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
      }
      .bid-now-btn:hover {
        background-color: #f4a34d;
        color: #fff;
      }
    }
  }
  .bidproduct-wrapp:hover {
    .hover-wish-list-wrappper {
      display: block;
    }
  }
}

@media (max-width: 1200px) {
  .best-dealsitems-wrappers {
    color: #000;
    .bidproduct-wrapp {
      width: 23% !important;
    }
    .bidproduct-wrapp:hover {
      .hover-wish-list-wrappper {
        display: block;
      }
    }
  }
}

@media (max-width: 1023px) {
  .best-dealsitems-wrappers {
    color: #000;
    .bidproduct-wrapp {
      width: 30% !important;
      margin-right: 20px;
    }
  }
}

@media (max-width: 767px) {
  .best-dealsitems-wrappers {
    .bidproduct-wrapp {
      width: 45% !important;
      margin-right: 15px;
    }
  }
}

@media (max-width: 481px) {
  .best-dealsitems-wrappers {
    .bidproduct-wrapp {
      width: 100% !important;
    }
  }
}
