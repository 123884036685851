.shopsbg {
  background-color: #f6f6f6;
  padding-top: 50px;
  padding-bottom: 90px;
  .Shops-wrappper {
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-around;

    .shop-wrapp {
      width: 23%;
      background-color: #fff;
      border: 1px solid #f6f6f6;
      margin-bottom: 30px;
      height: 310px;
      margin-left: 10px;
      margin-right: 10px;
      a {
        text-decoration: none;
        color: #000;
      }

      .shopeimg {
        width: 100%;
        height: 200px;
      }
      .shops-detais-wrapp {
        padding: 15px 10px;
        position: relative;
        background: #fff;
        // height: 200px;
        .shoplogowrapper {
          position: absolute;
          width: 50px;
          height: 50px;
          top: -23px;
          right: 10px;
          border-radius: 30px;
          border: 2px solid #15242e;
          background-image: url("../../images/shoplogo-2.png");
          background-size: cover;
          background-position: center;
        }
        .shopNameline {
          font-size: 16px;
          text-transform: capitalize;
          margin-bottom: 15px;
          font-weight: 700;
        }
        .shop-addres {
          font-size: 13px;
          color: #656572;
          margin-bottom: 0px;
          height: 20px;
          display: -webkit-box;
          overflow: hidden;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
        .visit-store-btn {
          background: none;
          border: none;
          font-size: 12px;
          color: #15242e;
          padding: 0;
          height: 20px;
        }
      }
    }
  }
}
@media (max-width: 1200px) {
  .shopsbg {
    .Shops-wrappper {
      .shop-wrapp {
        width: 22.8%;
      }
    }
  }
}

@media (max-width: 1024px) {
  .shopsbg {
    .Shops-wrappper {
      .shop-wrapp {
        width: 30.4%;
      }
    }
  }
}

@media (max-width: 767px) {
  .shopsbg {
    padding-top: 20px;
    padding-bottom: 60px;
    .Shops-wrappper {
      // flex-direction: column;
      .shop-wrapp {
        width: 45%;
        margin-left: 10px;
        margin-right: 10px;
        .shopeimg {
          width: 100%;
        }
        .shops-detais-wrapp {
          padding: 15px 10px;
        }
      }
    }
  }
}
@media (max-width: 576px) {
  .shopsbg {
    .Shops-wrappper {
      .shop-wrapp {
        width: 100%;
      }
    }
  }
}
