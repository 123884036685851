.terms-page-wrapper {
  padding-top: 50px;
  padding-bottom: 100px;
  li,
  p {
    line-height: 26px;
  }
  ul {
    margin-bottom: 0px;
  }
  h5 {
    margin-top: 20px;
  }
}
.registration_bg {
  background-image: url("../../public//img/1655975309.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 90px 0px;
  h1 {
    font-size: 35px;
    font-weight: 800;
  }
  p {
    width: 48%;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0px;
  }
}
.registration_bg_change {
  background-image: url("../../public//img/Untitled-1.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 90px 0px;
  h1 {
    font-size: 35px;
    font-weight: 800;
  }
  p {
    width: 48%;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0px;
  }
}
@media (max-width: 1200px) {
  .terms-page-wrapper {
    padding-top: 10px;
    padding-bottom: 70px;
  }
}
