.Order-trackWrapper {
  background-color: #fff;
  display: flex;
  border: 1px solid #e6e6e6;
  justify-content: space-between;
  padding: 25px 30px;

  .orderimg-wrapper {
    display: flex;
    width: 50%;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    .product-imgwrapper {
      display: flex;
      flex-direction: column;
      width: 25%;
      .processing-wrapperline {
        font-size: 16px;
        text-align: center;
        font-weight: 500;
        margin-bottom: 10px;
      }
      .Delivered-wrapperline {
        font-size: 16px;
        text-align: center;
        font-weight: 500;
        margin-bottom: 10px;
        color: #f4a34d;
        display: none;
      }
    }

    .ordertrackedpoinwrap {
      display: flex;
      flex-direction: column;
      width: 55%;
      height: 100%;
      .order-wrapperpointfirst {
        height: 15%;
        border-left: 2px solid #f4a34d;
        padding-left: 10px;
        position: relative;
        padding-top: 0px;
        padding-bottom: 50px;
        .orderfirstpoint-wrapp {
          display: flex;
          justify-content: flex-start;
          flex-direction: column;
          // top: -6px;
          bottom: 0;

          position: absolute;
        }

        .order-tagwrapp {
          font-size: 16px;
          color: #f4a34d;
          font-weight: 500;
        }
        .order-subdetails-line {
          font-size: 11px;
          color: #9c9c9c;
        }
      }
      .order-wrapperpointsecond {
        height: 15%;
        border-left: 2px solid #e6e6e6;
        padding-left: 10px;
        position: relative;
        padding-top: 0px;
        padding-bottom: 45px;
        .orderfirstpoint-wrapp {
          display: flex;
          justify-content: flex-start;
          flex-direction: column;
          // top: -6px;
          bottom: 0;
          position: absolute;
        }

        .order-tagwrapp {
          font-size: 16px;
          font-weight: 500;
        }
        .order-subdetails-line {
          font-size: 11px;
          color: #9c9c9c;
        }
      }
      .order-wrapperpointfirst:before {
        content: ".";
        position: absolute;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        left: -9px;
        top: 0px;
        background-color: #f4a34d;
        color: #fff;
      }
      .order-wrapperpointsecond:before {
        content: ".";
        position: absolute;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        left: -9px;
        // top: 0px;
        bottom: 0;
        border: 1px solid #bababa;
        background-color: #fff;
        color: #fff;
      }
      .shiped-tagwrapp {
        display: flex;
        flex-direction: column;
        height: 25%;
        border-left: 2px solid #f4a34d;
        padding-left: 10px;
        position: relative;
        padding-top: 28px;
        padding-bottom: 20px;
        justify-content: center;
        .Shipped-tagwrapp {
          font-size: 16px;
          font-weight: 500;
          color: #f4a34d;
        }
        .Shipped-subdetails-line {
          font-size: 11px;
          color: #9c9c9c;
        }
      }
      .shiped-tagwrapp::before {
        content: ".";
        position: absolute;
        width: 15px;
        height: 15px;
        border: 1px solid #bababa;
        border-radius: 50%;
        left: -9px;
        top: 24px;
        background-color: #f4a34d;
        color: #fff;
      }
      .shiped-tagwrapp2 {
        display: flex;
        flex-direction: column;
        height: 25%;
        border-left: 2px solid #bababa;
        padding-left: 10px;
        position: relative;
        padding-top: 20px;
        padding-bottom: 0px;
        justify-content: center;
        .Shipped-tagwrapp {
          font-size: 16px;
          font-weight: 500;
        }
        .Shipped-subdetails-line {
          font-size: 11px;
          color: #9c9c9c;
        }
      }
      .shiped-tagwrapp2Active {
        display: flex;
        flex-direction: column;
        height: 25%;
        border-left: 2px solid #f4a34d;
        padding-left: 10px;
        position: relative;
        padding-top: 20px;
        padding-bottom: 0px;
        justify-content: center;
        .Shipped-tagwrapp {
          font-size: 16px;
          font-weight: 500;
          color: #f4a34d;
        }
        .Shipped-subdetails-line {
          font-size: 11px;
          color: #9c9c9c;
        }
      }
      .shiped-tagwrapp2::before {
        content: ".";
        position: absolute;
        width: 15px;
        height: 15px;
        border: 1px solid #bababa;
        border-radius: 50%;
        left: -9px;
        top: 24px;
        background-color: #fff;
        color: #fff;
      }
      .shiped-tagwrapp2Active::before {
        content: ".";
        position: absolute;
        width: 15px;
        height: 15px;
        // border: 1px solid #bababa;
        border-radius: 50%;
        left: -9px;
        top: 24px;
        background-color: #f4a34d;
        color: #fff;
      }
      .Expected-tagwrapp {
        height: 10%;
        padding-top: 35px;
        border-left: 2px solid #bababa;
        padding-left: 10px;
        position: relative;
        .deliverd-wrapplastpoint {
          display: flex;
          justify-content: flex-end;
          flex-direction: column;
          position: absolute;
          bottom: -20px;
          .Expted-tagwrapp {
            font-size: 16px;
            font-weight: 500;
            color: #f4a34d;
          }
          .Expected-subdetails-line {
            font-size: 11px;
            color: #9c9c9c;
          }
        }
      }
      .Expected-tagwrapp::before {
        content: ".";
        position: absolute;
        width: 15px;
        height: 15px;
        border: 1px solid #bababa;
        border-radius: 50%;
        left: -9px;
        color: #fff;
        bottom: 0;
        background-color: #f4a34d;
      }
      .Expected-tagwrapp2 {
        height: 10%;
        padding-top: 35px;
        border-left: 2px solid #bababa;
        padding-left: 10px;
        position: relative;
        .deliverd-wrapplastpoint {
          display: flex;
          justify-content: flex-end;
          flex-direction: column;
          position: absolute;
          bottom: -20px;
          .Expted-tagwrapp {
            font-size: 16px;
            font-weight: 500;
          }
          .Expected-subdetails-line {
            font-size: 11px;
            color: #9c9c9c;
          }
        }
      }
      .Expected-tagwrapp2Active {
        height: 10%;
        padding-top: 35px;
        border-left: 2px solid #f4a34d;
        padding-left: 10px;
        position: relative;
        .deliverd-wrapplastpoint {
          display: flex;
          justify-content: flex-end;
          flex-direction: column;
          position: absolute;
          bottom: -20px;
          .Expted-tagwrapp {
            font-size: 16px;
            font-weight: 500;
            color: #f4a34d;
          }
          .Expected-subdetails-line {
            font-size: 11px;
            color: #9c9c9c;
          }
        }
      }
      .Expected-tagwrapp2::before {
        content: ".";
        position: absolute;
        width: 15px;
        height: 15px;
        border: 1px solid #bababa;
        border-radius: 50%;
        left: -9px;
        color: #fff;
        bottom: 0;
        background-color: #fff;
      }
      .Expected-tagwrapp2Active::before {
        content: ".";
        position: absolute;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        left: -9px;
        color: #fff;
        bottom: 0;
        background-color: #f4a34d;
      }
    }
  }
  .order-summary-details {
    width: 50%;
    .shipment-heading {
      font-size: 16px;
      font-weight: 500;
    }
    .shipment-info-wrapper {
      padding: 0;
      padding: 10px;
      border: 1px solid #e6e6e6;
      list-style: none;
      .shipment-detaisline {
        display: flex;
        justify-content: space-between;
        padding: 8px 8px;
        align-items: center;
        .ordertrackdate {
          font-size: 14px;
          font-weight: 600;
        }
        .ordertrack-viewbtn {
          cursor: pointer;
          font-weight: bold;
          font-size: 14px;
        }
        .infotxt-wrapp {
          font-size: 12px;
          font-weight: 400;
        }
        .infodetalstxt-wrapp {
          font-size: 12px;
          font-weight: 600;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .Order-trackWrapper {
    flex-direction: column;
    .orderimg-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-bottom: 50px;
      .product-imgwrapper {
        display: flex;
        flex-direction: column;
      }

      .ordertrackedpoinwrap {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;

        .order-wrapperpointfirst {
          padding: 20px 10px;
        }

        .shiped-tagwrapp {
          padding: 20px 10px;
        }
        .Expected-tagwrapp {
          padding: 20px 10px;
        }
      }
    }
    .order-summary-details {
      width: 100%;
    }
  }
}

@media (max-width: 576px) {
  .Order-trackWrapper {
    padding: 20px;
    .orderimg-wrapper {
      display: flex;
      flex-direction: column;

      width: 100%;
      margin-bottom: 50px;
      .product-imgwrapper {
        width: 50%;
        margin-bottom: 20px;
      }
    }
    .order-summary-details {
      .shipment-info-wrapper {
        .shipment-detaisline {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          .ordertrackdate {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}
