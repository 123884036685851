.nav-top-bar {
  width: 100%;
  background-color: #15242e;
  padding: 6px 0;
  color: #fff;
  font-size: 13px;
  border-bottom: 2px solid #414e56;

  .top-header-wrapper {
    display: flex;
    justify-content: space-between;

    .top-links-wrapper {
      justify-content: center;
      cursor: pointer;
      .top-acounts-link {
        color: #fff;
        text-decoration: none;
      }
      .top-acounts-link:hover {
        color: #fff;
      }
    }

    .top-links {
      margin-right: 15px;
    }

    .toprights-wrapper {
      display: flex;
      justify-content: space-between;
      .toplinks-wrapp {
        color: #fff;
        text-decoration: none;
        .headers-links-wrapper {
          padding: 0 10px;
        }
      }
      .toplinks-wrapp:hover {
        color: #fff;
      }

      .top-head-line {
        margin-right: 0px;
      }
      .select-option-wrapper {
        background: none;
        border: none;
        color: #fff;
        margin-left: 10px;
        padding: 0 5px;
        transition: 0.2s;
      }

      .select-option-wrapper option {
        background-color: #000;
        border: none;
        outline: none;
      }
    }
  }
}

@media (max-width: 767px) {
  .nav-top-bar {
    .top-header-wrapper {
      .toprights-wrapper {
        .top-head-line {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 581px) {
  .nav-top-bar {
    font-size: 9px;
    .top-header-wrapper {
      .toprights-wrapper {
        .select-option-wrapper {
          margin-left: 0px;
          padding: 0 5px;
        }

        .select-option-wrapper option {
          background-color: #000;
        }
      }
    }
  }
}
