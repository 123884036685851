.Homebanner-wrapper {
  width: 100%;
  padding: 0px 0 30px;

  .slick-arrow {
    display: none !important;
  }
  .home-banner {
    position: relative;
    height: 100%;
    width: 100%;
    background-size: cover;

    img {
      width: 100%;
    }
  }
  .home-bannermob {
    position: relative;
    height: 100%;
    width: 100%;
    background-size: cover;

    img {
      width: 100%;
    }
  }
  .slick-dots li button:before {
    font-size: 15px;
    color: #f4a34d;
    // content: "_";
    line-height: 0px;
  }
  .slick-dots {
    bottom: -50px;
    z-index: 1;
  }
  .slick-dots li {
    width: 20px;
    height: 20px;
  }
}

@media (max-width: 762px) {
  .Homebanner-wrapper {
    .slick-dots {
      bottom: -50px;
      z-index: 1;
    }
  }
  .home-banner {
    display: none;
  }
}

@media (min-width: 762px) {
  .home-bannermob {
    display: none;
  }
}
