.shopHeader-wrapper {
  // padding: 10px;
  .shopHeader-img {
    img {
      width: 100%;
    }
  }
  .Shop-items-headers {
    background-color: #f7f8fd;
    padding: 10px 20px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    .filters-wrapp {
      display: flex;
      align-items: center;

      .shops-viewicon {
        i {
          font-size: 16px;
          margin: 0 10px;
        }
        i:hover {
          font-size: 16px;
          cursor: pointer;
        }
      }
      .Filter-mobile-res-wrapper {
        display: none;
      }
    }
    .Shorts-wrapper {
      display: flex;

      .short-by-filter {
        padding: 10px;
        // border-right: 1px solid #c5d1e4;
        select {
          background: none;
          border: none;
          font-size: 14px;
          font-weight: 500;
        }
      }
      .show-viwe-wrapp {
        padding: 10px;
        select {
          background: none;
          border: none;
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .shopHeader-wrapper {
    padding: 10px;
    .shopHeader-img {
      img {
        width: 100%;
      }
    }
    .Shop-items-headers {
      .filters-wrapp {
        .shops-viewicon {
          display: none;
        }
        .Filter-mobile-res-wrapper {
          display: block;
        }
      }
    }
  }
}

@media (max-width: 568px) {
  .shopHeader-wrapper {
    padding: 10px;
    .shopHeader-img {
      img {
        width: 100%;
      }
    }
    .Shop-items-headers {
      background-color: #f7f8fd;

      .filters-wrapp {
        .Filter-mobile-res-wrapper {
          font-size: 11px;
        }
      }
      .Shorts-wrapper {
        display: flex;

        .short-by-filter {
          select {
            font-size: 11px;
          }
        }
        .show-viwe-wrapp {
          select {
            font-size: 11px;
          }
        }
      }
    }
  }
}
