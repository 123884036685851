.mian-products-wrapper {
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  .product-wrapper {
    border: 1px solid #f6f6f6;
    position: relative;
    width: 30%;
    height: 400px;
    .hover-wish-list-wrappper {
      position: absolute;
      cursor: pointer;
      width: 25%;
      right: 0;
      height: 50%;
      padding: 10px;
      transition: 2s;
      display: none;
      .wish-list-imgs {
        float: right;
        img {
          margin-bottom: 10px;
        }
      }
    }

    .product-imgs-wrap {
      // padding: 30px;
      align-items: center;
      height: 200px;
      width: 100%;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .items-content-wrappers {
      padding: 15px;
      height: 200px;
      .items-name {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 10px;
        height: 40px;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .instock {
        font-size: 11px;
        text-transform: uppercase;
        font-weight: bold;
        color: #15242e;
        margin-bottom: auto;
      }
      .last-price {
        font-size: 15px;
        color: #c3c8c9;
        margin-right: 10px;
      }
      .latest-price {
        font-size: 16px;
        font-weight: bold;
        color: #ed1c24;
      }
      .addto-cart-btn {
        background-color: transparent;
        border: 1px solid #f4a34d;
        border-radius: 20px;
        padding: 5px 20px;
        outline: none;
        width: 100%;
        color: #f4a34d;
        font-weight: 500;
        margin-top: 20px;
      }
      .addto-cart-btn .loader-spinner .loading-gif {
        animation-name: spin;
        animation-duration: 2000ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
      }
      .addto-cart-btn:hover {
        background-color: #f4a34d;
        color: #fff;
      }
      .addto-cart-btn-already {
        background-color: transparent;
        border: 1px solid #f4a34d;
        border-radius: 20px;
        padding: 5px 20px;
        outline: none;
        width: 100%;
        color: #f4a34d;
        font-weight: 500;
        margin-top: 20px;
      }
    }
  }
  .product-wrapper:hover {
    .hover-wish-list-wrappper {
      display: unset;
    }
  }
}
.paginationContainer1 {
  display: flex;
  justify-content: center;
  align-items: center;
  .paginationContainer2 {
    display: flex;
    justify-content: center;
  }
  .spanarrow {
    font: size 22px;
    cursor: pointer;
    background-color: #15242e;
    border-radius: 5px;
    padding: 0 12px;
    display: flex;
    color: white;
    align-items: center;
  }
  .pageLabel {
    font-size: 20px;
    color: #15242e;
    border-radius: 5px;
    padding: 0 12px;
    display: flex;
    border: 1px solid #15242e;
    align-items: center;
    margin: 0 5px;
  }
  .pageLabelnew {
    font-size: 20px;
    color: white;
    border-radius: 5px;
    padding: 0 12px;
    display: flex;
    background-color: #15242e;
    border: 1px solid #15242e;
    align-items: center;
    margin: 0 5px;
  }
}
@media (max-width: 767px) {
  .mian-products-wrapper {
    display: flex;
    flex-direction: column;

    .product-wrapper {
      border: 1px solid #f6f6f6;
      position: relative;
      width: 100%;
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
