.rendomPagebg {
  background-color: #dcedff;
  .rendompagewrapper {
    padding: 100px 0 150px 0;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    flex-direction: column;

    .bigtextline {
      font-size: 151px;
      color: #15242e;
      margin-bottom: auto;
    }
    .wearesorrylines {
      text-align: center;
      font-size: 18px;
      font-weight: 500;
      text-transform: capitalize;
    }
    .rendompagrbtn-wrapp {
      display: flex;
      width: 18%;
      .rendomgohomebtn {
        height: 50px;
        border-radius: 25px;
        outline: none;
        color: #fff;
        background-color: #15242e;
        border: none;
        padding: 8px 16px;
        font-weight: 500;
        font-size: 14px;
        margin-right: 10px;
      }
      .rendomcontect-btn {
        border-radius: 25px;
        height: 50px;
        outline: none;
        font-size: 14px;
        font-weight: 500;
        color: #000;
        background-color: transparent;
        border: 2px solid #15242e;
        padding: 8px 16px;
      }
    }
    .rendom-social-wrapper {
      width: 15%;
      margin-top: 20px;

      ul {
        list-style: none;
        text-align: center;
        margin: 0px;
        padding: 0;
        display: flex;
        justify-content: space-between;
        width: 100%;
        li {
          display: flex;
          justify-content: space-between;

          a {
            text-decoration: none;
            color: #15242e;
          }
          i {
            font-size: 16px;
          }
        }
      }
    }
  }
}

@media (max-width: 1440px) {
  .rendomPagebg {
    .rendompagewrapper {
      .rendompagrbtn-wrapp {
        width: 30%;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

@media (max-width: 1440px) {
  .rendomPagebg {
    .rendompagewrapper {
      .rendompagrbtn-wrapp {
        width: 50%;
      }
    }
  }
}

@media (max-width: 768px) {
  .rendomPagebg {
    padding: 16px 0 0px 0;
    .rendompagewrapper {
      padding: 1px 0px 70px;
      .bigtextline {
        font-size: 121px;
      }
      .rendompagrbtn-wrapp {
        width: 100%;
      }
      .wearesorrylines {
        font-size: 12px;
      }
      .rendom-social-wrapper {
        width: 50%;
        margin-top: 20px;
      }
    }
  }
}
