.shopDetailsbg {
  background-color: #fff;
  padding-top: 50px;
  padding-bottom: 150px;
  .shopdetails-wrapper {
    display: flex;
    justify-content: space-between;
    .ShopLeft-side-form {
      width: 25%;
      height: 100%;
    }
    .Shopright-side-form {
      width: 72%;
    }
  }
}

@media (max-width: 1024px) {
  .shopDetailsbg {
    .shopdetails-wrapper {
      flex-direction: column;
      .ShopLeft-side-form {
        width: 100%;
        height: 100%;
      }
      .Shopright-side-form {
        width: 100%;
        margin-top: 50px;
      }
    }
  }
}
