.pages-bg-img {
  // background-image: url("../../images/about-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  .aboutpage-tag-wrapper {
    padding: 55px 0px 0px 0px;
    .pages-tag {
      color: #f4a34d;
      font-size: 21px;
      font-style: italic;
    }
    .pages-uper-text {
      font-size: 31px;
      font-weight: bold;
      width: 55%;
      margin-top: 15px;
    }
    .our-story-section {
      display: flex;
    }
  }
}

.about-us-image {
  height: 530px !important;
  object-fit: cover;
  border-radius: 10px;
  margin-right: 10px !important;
}
.some-words-about {
  margin-left: 20px;
}
.cision-image {
  width: 100%;
}

@media (max-width: 1200px) {
  .pages-bg-img {
    .aboutpage-tag-wrapper {
      padding: 55px 0;
      .pages-uper-text {
        width: 75%;
      }
    }
  }
}

@media (max-width: 1023px) {
  .pages-bg-img {
    .aboutpage-tag-wrapper {
      padding: 55px 0;

      .pages-uper-text {
        width: 100%;
      }
    }
  }
  .some-words-about {
    margin-top: 30px;
    margin-left: 0px;
  }
}
@media (max-width: 768px) {
  .pages-bg-img {
    .aboutpage-tag-wrapper {
      .pages-tag {
        font-size: 18px;
      }
      .pages-uper-text {
        width: 100%;
        font-size: 16px;
      }
    }
  }
}

@media (max-width: 568px) {
  .pages-bg-img {
    .aboutpage-tag-wrapper {
      padding: 30px 0;
      .pages-tag {
        font-size: 16px;
      }
      .pages-uper-text {
        width: 100%;
        font-size: 14px;
      }
    }
  }
}
