.optionsize-wrappers {
  width: 100%;
  padding: 30px 20px;
  display: flex;
  .variation-info {
    width: 50%;
  }
  .sizeoptin-wrapp {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 14px;

    .sizeoption-drop {
      width: 100%;
      margin-bottom: 10px;
      padding: 10px 16px;
      border: 2px solid #f6f6f6;
      background-color: #f8f8f8;
    }
    .coloroption-drop {
      border: none;
      width: 100%;
      border: 2px solid #f6f6f6;
      background-color: #f8f8f8;
      padding: 10px 16px;
    }
  }
}

.addtobasket-btn {
  background-color: #213340;
  padding: 8px 16px;
  border-radius: 30px;
  font-size: 16px;
  color: #fff;
  text-align: center;
  // margin-top: 30px;
  width: 80%;
  cursor: pointer;
}
