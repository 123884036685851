.shop-bg {
  background-color: #fff;
  padding-top: 50px;
  padding-bottom: 150px;
  .shop-wrapper {
    display: flex;

    .shop-left-section {
      width: 25%;
      background-color: #fff;
    }
    .shop-right-section {
      width: 75%;
      background-color: #fff;
    }
  }
}

@media (max-width: 1024px) {
  .shop-bg {
    .shop-wrapper {
      .shop-left-section {
        display: none;
      }
      .shop-right-section {
        width: 100%;
      }
    }
  }
}

@media (max-width: 767px) {
  .shop-bg {
    padding-bottom: 80px;
  }
}
