.productscategory-wrapper {
  background-color: #fff;
  // padding: 0 10px;
  .product-heading {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 0;
    padding: 8px 0px;
  }
  .product-category-itmes-wrapper {
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      padding: 8px 0px;
      li {
        label {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 7px 38px 7px 0px;
          cursor: pointer;
          font-size: 12px;

          .Ckeckboxscls {
            margin-right: 5px;
          }

          label {
            display: flex;
            align-items: center;
            cursor: pointer;
          }
          i {
            font-size: 8px;
          }
        }
        p {
          font-size: 12px;
          color: #000;
          padding: 6px 0px 2px 34px;
          margin-bottom: 0px;
          cursor: pointer;
        }
        .active {
          display: block;
        }
        .inactive {
          display: none;
        }
      }
    }
  }
}
