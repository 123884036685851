.ProductlistStatus-wrapper {
  margin-top: 10px;
  .product-statuss-txt {
    padding: 0;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0;
  }

  .productliststok-wrapper {
    padding: 0;
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      li {
        label {
          display: flex;
          font-size: 12px;
          justify-content: space-between;
          align-items: center;
          padding: 7px 0px;
          cursor: pointer;
        }
        i {
          font-size: 8px;
        }
      }
    }
  }
}
