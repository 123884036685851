.Categories-dropdown-wrapper {
  width: 720px;
  position: absolute;
  top: 45px;
  z-index: 10;
  background-color: #ffff;
  border: 1px solid #f6f6f6;
  .categories-wrapp {
    padding: 10px;
    width: 100%;
    height: 400px;
    scroll-behavior: smooth;
    overflow-y: scroll;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #f4a34d;
      border-radius: 20px;
    }

    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      color: #000;
      transition: 0.2s;
      padding: 8px 0px;
      width: 30%;
      margin-bottom: 15px;
      a {
        text-decoration: none;
        color: #000;
      }
      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 4px 23px;
        font-size: 13px;
        cursor: pointer;
      }
      li:hover {
        text-decoration: underline;
      }
    }
  }
  .seeAllcategorieslink-wrapp {
    display: flex;
    justify-content: flex-end;
    padding: 10px 50px;
    border-top: 1px solid #f6f6f6;
    a {
      font-size: 13px;
      font-weight: 500;
      color: #0654ba;
      text-decoration: none;
    }
    a:hover {
      text-decoration: underline;
    }
  }
}
// .Categories-dropdown {
//   width: 275px;
//   height: 100%;
//   position: absolute;
//   top: 66px;
//   transition: 0.5s all ease-in-out;
//   z-index: 1;
//   ul {
//     padding: 0;
//     margin: 0;
//     list-style: none;
//     border: 1px solid #f6f6f6;
//     background-color: #ffff;
//     transition: 0.2s;
//     padding: 8px 0px;

//     a {
//       text-decoration: none;
//       color: #000;
//     }

//     li {
//       display: flex;
//       justify-content: space-between;
//       align-items: center;
//       padding: 9px 23px;
//       font-size: 14px;
//       cursor: pointer;
//       i {
//         font-size: 8px;
//       }
//       .catigores-itemswrapper {
//         display: none;
//         .Christms-bgwrapper {
//           position: absolute;
//           background-repeat: no-repeat;
//           background-position: bottom right;
//           right: -600px;
//           top: 0rem;
//           width: 600px;
//           height: 420px;
//           background-size: 250px;
//           background-color: #fff;
//           display: flex;
//           justify-content: space-around;
//           border-top: 2px solid #15242e;
//           border-left: none;
//           padding: 10px 20px;
//           background-origin: content-box, padding-box;
//           padding: 20px 40px 30px 0px;

//           .categorylists-wrapper {
//             .sub-catigors-wrapp {
//               border: none;
//               background: none;
//               .sub-catigors-items {
//                 list-style: none;
//                 padding: 8px 25px 8px 0px;
//               }
//             }
//             .items-border {
//               border-bottom: 1px solid #dedede;
//             }
//           }
//           .categoryrightsidewrapp {
//             height: 300px;
//             .sub-catigors-wrappd {
//               border: none;
//               background: none;
//               height: 150px;
//               .sub-catigors-itemss {
//                 list-style: none;
//                 padding: 8px 25px 8px 0px;
//               }
//             }
//             .rightsidewrapp {
//               height: 300px;
//               width: 200px;
//             }
//             .categoryimgwrappp {
//               width: 200px;
//               height: 200px;
//               img {
//                 width: 100%;
//                 height: 100%;
//               }
//             }
//           }
//         }
//       }
//     }
//     li:hover {
//       .catigores-itemswrapper {
//         display: unset;
//         transition: all 0.2s ease;
//       }
//     }
//   }
//   .viewall-category-dropdown {
//     display: flex;
//     justify-content: space-between;
//     padding: 9px 23px;
//     cursor: pointer;
//     font-size: 14px;
//     align-items: center;
//     color: #15242e;
//     background-color: #ffff;
//     border: 1px solid #f6f6f6;
//     a {
//       text-decoration: none;
//       color: #15242e;
//     }

//     i {
//       font-size: 8px;
//       transform: rotate(90deg);
//     }
//   }
// }

// @media (max-width: 1400px) {
//   .Categories-dropdown {
//     ul {
//       li {
//         padding: 7px 23px;
//         font-size: 13px;

//         .catigores-itemswrapper {
//           .Christms-bgwrapper {
//             padding: 20px 2px 10px 0px;
//           }
//         }
//       }
//       li:hover {
//         .catigores-itemswrapper {
//           display: unset;
//           transition: 0.2s;
//         }
//       }
//     }
//     .viewall-category-dropdown {
//       display: flex;
//       justify-content: space-between;
//       padding: 9px 23px;
//       cursor: pointer;
//       font-size: 14px;
//       align-items: center;
//       color: #15242e;
//       background-color: #ffff;
//       border: 1px solid #f6f6f6;
//       a {
//         text-decoration: none;
//         color: #15242e;
//       }

//       i {
//         font-size: 8px;
//         transform: rotate(90deg);
//       }
//     }
//   }
// }
