.supportform-wrapper-modal {
  .retrunform-wrapperline {
    background-color: #000;
    color: #fff;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 100;
    .fa-remove {
      cursor: pointer;
    }
  }
  .react-responsive-modal-closeButton {
    color: #fff !important;
    display: none !important;
  }
  .OrderCanelForm-wrapper {
    padding: 10px;
    display: flex;
    width: 100%;
    .cancelOrderFormside-wrapper {
      width: 50%;
      padding: 20px;
      .Caneclform-wrapper {
        display: flex;
        flex-direction: column;
        padding: 20px;
        .orderid-input {
          background-color: #f6f6f6;
          width: 100%;
          height: 50px;
          padding: 8px 16px;
          border: none;
          margin-bottom: 20px;
        }
        .orderselet-wrpp {
          background-color: #f6f6f6;
          width: 100%;
          height: 50px;
          padding: 8px 16px;
          border: none;
          margin-bottom: 20px;
        }
        .textwrappps {
          padding: 8px 16px;
          background-color: #f6f6f6;
          border: none;
          margin-bottom: 20px;
          width: 100%;
        }
        .submit-form-wrapp {
          background-color: #f4a34d;
          color: #f6f6f6;
          padding: 10px;
          border-radius: 70px;
          outline: none;
          font-weight: bold;
          border: none;
          margin-top: 20px;
          width: 60%;
        }
      }
    }
    .Cancelsuportform-rightside {
      width: 50%;
      padding: 20px;
      ul {
        padding: 0 0 0 20px;
        li {
          padding: 0;
          font-size: 14px;
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .supportform-wrapper-modal {
    padding: 10px;
    width: 100%;
    .OrderCanelForm-wrapper {
      flex-direction: column;
      .cancelOrderFormside-wrapper {
        width: 100%;
      }
      .Cancelsuportform-rightside {
        width: 100%;
        padding: 20px;
      }
    }
  }
}
