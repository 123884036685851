.Address-wrapper {
  width: 100%;
  background-color: #fff;
  padding: 30px;
  border: 1px solid #e6e6e6;
  .Addressrow-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .add-Address-wrapp {
      width: 30.5%;
      height: 250px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: dashed 1px #beb9b9;
      cursor: pointer;
      margin: 0px 10px 20px 10px;
    }
    .save-Address-wrapp {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 30.5%;
      height: 250px;
      border-radius: 10px;
      border: 1px solid #beb9b9;
      padding: 10px;
      margin: 0px 10px 20px 10px;
      .user-address-details {
        display: flex;
        flex-direction: column;

        .delevar-address {
          font-size: 12px;
          text-transform: capitalize;
          margin-bottom: 7px;
        }
      }
      .edit-btns-wrapp {
        .edit-btn {
          font-size: 12px;
          background: none;
          border: none;
          outline: none;
          font-weight: 700;
          color: #4596a5;
          padding-right: 10px;
          padding-left: 0;
          border-right: 1px solid #6666;
        }
        .remove-btn {
          font-size: 12px;
          background: none;
          border: none;
          outline: none;
          font-weight: 700;
          color: #4596a5;
          padding: 0px 16px;
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .Address-wrapper {
    .Addressrow-wrapper {
      flex-direction: column;
      .add-Address-wrapp {
        width: 100%;
      }
      .save-Address-wrapp {
        width: 100%;
        .user-address-details {
          flex-direction: column;
        }
      }
    }
  }
}
