.Footer-wrapper {
  background-color: #ffffff;
  color: #213340;
  float: left;
  width: 100%;
  padding: 50px 0 0;
  border-top: 1px solid #ebebeb;

  .footer-serch-wrapper {
    background-color: #ffffff;
    color: #213340;
    width: 100%;
    justify-content: space-between;
    margin-top: -45px;
    margin-bottom: 60px;
    // color: #fff;
    border-radius: 70px;
    padding: 15px 30px;
    .footer-exvlopwrapper {
      display: flex;

      .envalop-wrapeer {
        display: flex;
        align-items: center;
        width: 50%;
        .envlop-imgwrapper {
          width: 60px;
        }
        .sing-in-line {
          font-size: 22px;
          margin-left: 15px;
          font-weight: 600;
        }
      }
      .footinput-wrapeer {
        display: flex;
        width: 50%;
        align-items: center;
        position: relative;
      }
      .serch-inputs {
        width: 100%;
        height: 55px;
        padding: 10px 165px 10px 23px;
        border-radius: 50px;
        outline: none;
        border: none;
      }
      .search-btns-wrap {
        position: absolute;
        right: 8px;
        width: 150px;
        background-color: #ffffff;
        color: #213340;
        padding: 10px;
        border-radius: 70px;
        outline: none;
        font-weight: bold;
        border: none;
      }
    }
  }

  .footer-links-wrapeer {
    width: 100%;
    .categories-wrapperd {
      width: 100%;

      .categorilinks-wrap {
        position: relative;
        .Smileszy-SitesWrapper {
          display: flex;
          align-items: center;
          border: 1px solid #ddd;
          padding: 8px;
          margin-bottom: 20px;
          background: #f5f5f5;
          cursor: pointer;
          img {
            width: 15%;
          }
          span {
            font-size: 13px;
            margin: 0px 5px;
          }
          .fa-sort-desc {
            margin-top: -10px;
          }
        }
        .country-popupList {
          position: absolute;
          top: -160px;
          left: -400px;
          width: 500px;
          height: 200px;
          padding: 20px;
          display: flex;
          flex-wrap: wrap;
          background-color: #fff;
          border: 1px solid #ddd;
          box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
        }
        .footer-links-heading {
          font-size: 16px;
          font-weight: 800;
        }
        .categreslink-wraped {
          display: flex;
          .categorilinkswraped {
            list-style: none;
            padding: 0;
            margin-right: 30px;
            a {
              li {
                margin-bottom: 10px;
                cursor: pointer;
                font-size: 14px;
                color: #213340;
                i {
                  font-size: 18px;
                  color: #707070;
                }
              }
              li:hover {
                transition: 0.2s;
                text-decoration: underline;
                color: #f4a34d;
              }
            }
            a:hover {
              color: #f4a34d;
            }
          }
        }
      }
    }
    .Store-details-wrapee {
      // width: 25%;
      font-size: 14px;
      display: inline-block;
      .footer-link-heading {
        font-size: 16px;
        font-weight: 800;
      }
      a {
        color: #213340;
      }
      a:hover {
        color: #f4a34d;
        text-decoration: underline;
      }
      .store-loction-wrappe {
        font-size: 13px;
        width: 80%;
        margin-bottom: 10px;
      }
    }
    .footerlinks-wrap {
      display: inline-block;
      .footer-links-heading {
        font-size: 16px;
        font-weight: 800;
      }

      .foterlinkswraped {
        list-style: none;
        padding: 0;
        li {
          cursor: pointer;
          margin-bottom: 10px;
          font-size: 14px;
          img {
            width: 100px;
          }
        }
        li:hover {
          transition: 0.2s;

          text-decoration: underline;
        }
        a {
          color: #213340;
          text-decoration: none;
        }

        a:hover {
          color: #f4a34d;
          text-decoration: none;
        }
      }
    }
    .pages-link {
      color: #213340;
      text-decoration: none;
    }
  }
  .copyright-bg {
    background-color: #ffffff;
    color: #213340;
    font-size: 13px;
    padding: 15px 0;
    align-items: center;
    border-top: 1px solid #e6e1e1;
    .footer-copy-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .sociallinksWrapper {
        display: flex;
        a {
          text-decoration: none;
        }

        .sociallinksWrapper-faccebook {
          color: #213340;
          background-color: #365493;
          border-radius: 50%;
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .sociallinksWrapper-insta {
          background-color: #773045;
          color: #213340;
          border-radius: 50%;
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 20px;
        }
        .sociallinksWrapper-twitr {
          background-color: #3cf;
          color: #213340;
          border-radius: 50%;
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 20px;
        }
      }
    }
  }
}

a.effect-underline:after {
  content: "";
  position: absolute;
  left: 0;
  display: inline-block;
  height: 1em;
  width: 100%;
  border-bottom: 1px solid;
  margin-top: 10px;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: scale(0, 1);
  transform: scale(0, 1);
}

a.effect-underline:hover:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

@media (max-width: 992px) {
  .Footer-wrapper {
    .footer-serch-wrapper {
      .envalop-wrapeer {
        .sing-in-line {
          font-size: 16px;
          margin-left: 10px;
        }
      }
    }
    .footer-links-wrapeer {
      .Quick-Links {
        // margin-top: -75px;
      }
      .About-Smileszy {
        // margin-top: -95px;
      }
      .categories-wrapperd {
        .categorilinks-wrap {
          margin-top: 10px;
        }
      }
      .Store-details-wrapee {
        margin-top: 10px;
      }
      .footerlinks-wrap {
        margin-top: 10px;
      }
    }
  }
}

@media (max-width: 767px) {
  .Footer-wrapper {
    .footer-serch-wrapper {
      flex-direction: column;
      border-radius: 31px;
      margin-bottom: 30px;
      .footer-exvlopwrapper {
        flex-direction: column;
        .serch-inputs {
          padding: 8px 89px 8px 15px;
        }
        .search-btns-wrap {
          width: 80px;
          font-size: 14px;
        }
        .envalop-wrapeer {
          width: 100%;
          justify-content: space-between;
          .sing-in-line {
            font-size: 12px;
            margin-left: 10px;
          }
        }
        .footinput-wrapeer {
          width: 100%;
          margin-top: 10px;
        }
      }
    }
    .footer-links-wrapeer {
      .Quick-Links {
        margin-top: 0px;
      }
      .About-Smileszy {
        margin-top: 0px;
      }
      .categories-wrapperd {
        .categorilinks-wrap {
          margin-top: 10px;
        }
      }
      .Store-details-wrapee {
        margin-top: 10px;
      }
      .footerlinks-wrap {
        margin-top: 10px;
      }
    }
  }
}
@media (max-width: 568px) {
  .Footer-wrapper {
    .footer-serch-wrapper {
      padding: 15px;
      .envalop-wrapeer {
        img {
          width: 20%;
        }
      }
    }
    .footer-links-wrapeer {
      margin-bottom: 40px;
      .categories-wrapperd {
        .categorilinks-wrap {
          .categreslink-wraped {
            flex-direction: column;
            .categorilinkswraped {
              margin-right: 0;
              margin-bottom: 0;
            }
          }
        }
      }
      .Store-details-wrapee {
        margin-top: 10px;
      }
    }
    .copyright-bg {
      .footer-copy-wrapper {
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }
}
