.allfeatured-items-wrappers {
  display: flex;
  flex-wrap: wrap;
  margin-top: 25px;
  .product-wrapper {
    // border-right: 2px solid #f6f6f6;
    border: 1px solid #f6f6f6;
    position: relative;
    height: 370px;
    margin-left: 12px;
    margin-bottom: 20px;
    width: 19%;
    .hover-wish-list-wrappper {
      position: absolute;
      cursor: pointer;
      width: 25%;
      height: 50%;
      padding: 10px;
      transition: 2s;
      right: 0;
      display: none;
      .wish-list-imgs {
        float: right;
        img {
          margin-bottom: 10px;
        }
      }
    }

    .product-imgs-wrap {
      // padding: 30px;
      align-items: center;
      height: 200px;
      width: 100%;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .items-content-wrappers {
      padding: 15px;
      cursor: pointer;
      .items-name {
        font-size: 14px;
        font-weight: 500;
        height: 40px;
        margin-bottom: 10px;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .instock {
        font-size: 11px;
        text-transform: uppercase;
        font-weight: bold;
        color: #f4a34d;
        margin-bottom: auto;
      }
      .last-price {
        font-size: 15px;
        color: #c3c8c9;
        margin-right: 10px;
      }
      .latest-price {
        font-size: 16px;
        font-weight: bold;
        color: #ed1c24;
      }
      .addto-cart-btn {
        background-color: transparent;
        border: 1px solid #f4a34d;
        border-radius: 20px;
        padding: 5px 20px;
        outline: none;
        width: 100%;
        color: #f4a34d;
        font-weight: 500;
        margin-top: 15px;
      }

      .addto-cart-btn .loader-spinner .loading-gif {
        animation-name: spin;
        animation-duration: 2000ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
      }
      .addto-cart-btn:hover {
        background-color: #f4a34d;
        color: #fff;
      }
    }
  }
  .product-wrapper:hover {
    .hover-wish-list-wrappper {
      display: block;
    }
  }

  .mobresponsive-Slide {
    display: none;
    .slick-arrow {
      display: none !important;
    }
  }
}
@media (max-width: 1400px) {
  .allfeatured-items-wrappers {
    .product-wrapper {
      margin-left: 9px;
      width: 19%;
    }
  }
}
@media (max-width: 1024px) {
  .allfeatured-items-wrappers {
    .product-wrapper {
      width: 32%;
      .addto-cart-btn {
        padding: 5px 10px;
      }
    }
  }
}
@media (max-width: 767px) {
  .allfeatured-items-wrappers {
    .product-wrapper {
      margin-left: 9px;
      width: 47%;
      .addto-cart-btn {
        padding: 5px 10px;
      }
    }
  }
}
@media (max-width: 448px) {
  .allfeatured-items-wrappers {
    .product-wrapper {
      margin-left: 0px;
      width: 100%;
      .addto-cart-btn {
        padding: 5px 10px;
      }
    }
  }
}
