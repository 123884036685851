.bestFee-bg {
  background-color: #fff;
  padding: 80px 0;
  .bestfee-wrapper {
    display: flex;
    justify-content: space-between;
  }
  .img-wrap {
    width: 40%;
    img {
      width: 100%;

      border-radius: 10px;
    }
  }
  .best-text-wrapper {
    width: 57%;
    .best-fee-line {
      font-size: 37px;
      font-weight: 800;
      margin-bottom: 10px;
      line-height: 30px;
    }
    .Affordable-line {
      font-size: 15px;
      color: #f4a34d;
      font-weight: 500;
      margin-bottom: 10px;
    }
    .it-dose-line {
      font-size: 15px;
      line-height: 25px;
      font-weight: 500;
      margin-bottom: 10px;
    }
    .tools-wrappper {
      display: flex;
      justify-content: space-between;
      text-align: center;
      width: 60%;
      padding: 30px 0;
      align-items: flex-end;
      .tool-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 200px;
        height: 100px;
        img {
          width: 40px;
        }
        span {
          margin-top: 10px;
          font-size: 15px;
          font-weight: 700;
        }
      }
      .Lowfees-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 200px;
        height: 100px;
        img {
          width: 60px;
        }
        span {
          margin-top: 10px;
          font-size: 15px;
          font-weight: 700;
        }
      }
      .toolSupport-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 200px;
        height: 100px;
        img {
          width: 47px;
        }
        span {
          margin-top: 10px;
          font-size: 15px;
          font-weight: 700;
        }
      }
    }

    .Sell-withbtn {
      background-color: #f4a34d;
      font-size: 16px;
      color: #fff;
      padding: 12px 40px;
      border-radius: 30px;
      border: none;
      outline: none;
      cursor: pointer;
      text-transform: uppercase;
      font-weight: 500;
    }
  }
}

@media (max-width: 1024px) {
  .bestFee-bg {
    padding: 45px 0px;
    .bestfee-wrapper {
      flex-direction: column;
    }
    .img-wrap {
      width: 100%;
    }
    .best-text-wrapper {
      width: 80%;
      margin-top: 30px;

      .tools-wrappper {
        width: 60%;

        .tool-wrap {
          flex-direction: column;

          img {
            width: auto;
          }
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .bestFee-bg {
    .best-text-wrapper {
      .tools-wrappper {
        width: 100%;
      }
    }
  }
}
@media (max-width: 568px) {
  .bestFee-bg {
    padding: 40px 0;
    .best-text-wrapper {
      width: 100%;
      .best-fee-line {
        font-size: 21px;
      }

      .tools-wrappper {
        width: 100%;
      }
    }
  }
}
