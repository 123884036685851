.deknav-wrapeer {
  // border-bottom: 1px solid #e9e9e9;
  padding: 0px;
  width: 100%;
  background-color: #15242e;
  position: relative;
  .nav-links-wrapper {
    width: 100%;
    background-color: #15242e;
    color: #fff;
    position: relative;
    font-size: 13px;
    padding: 0;
    margin: 0;
    .nav-linkuls {
      display: flex;
      padding: 0;
      margin: 0;
      align-items: center;
      .navlinksd {
        list-style: none;
        padding: 6px 6px !important;
        cursor: pointer;
        a {
          text-decoration: none;
          color: #fff;
          font-size: 12px;
        }
        a:hover {
          text-decoration: underline;
        }
      }
      .navlinksd_active {
        list-style: none;
        padding: 8px 10px;
        cursor: pointer;
        a {
          text-decoration: none;
          color: #f4a34d;
        }
        a:hover {
          text-decoration: underline;
        }
      }
      .navlikslinr {
        list-style: none;
        padding: 6px 6px !important;
        cursor: pointer;
        font-size: 12px;
        a {
          text-decoration: none;
          color: #fff;
        }

        .navslists-wrapper {
          background-color: #ffff;
          border: 1px solid #6666;
          position: absolute;
          top: 29px;
          left: 0;
          padding: 15px;
          width: 1296px;
          height: auto;
          display: none;
          z-index: 1;
          .navlistsitem-wrapp {
            display: flex;
            margin: 0;
            list-style: none;
            padding: 0;
            .nav-lists-items {
              width: 19%;
              margin-right: 20px;
              height: 100%;
              .categriesheading-line {
                font-size: 14px;
                padding-bottom: 5px;
                border-bottom: 1px solid #191d21;
                color: #000;
                font-weight: 600;
                margin-bottom: 10px;
              }
              ul {
                list-style: none;
                padding: 0;
                a {
                  text-decoration: none;
                  color: #000;
                  li {
                    padding: 0;
                    margin: 0;
                    font-size: 12px;
                    margin-bottom: 10px;
                  }
                }
                a:hover {
                  text-decoration: underline;
                }
              }
            }
            .navitemsimgs-wraper {
              width: 60%;
              height: 100%;
              background-color: #15242e;
              img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
      .navlikslinr:hover {
        .navslists-wrapper {
          display: unset;
        }
      }
    }
  }
  // .category-btn {
  //   background-color: #15242E
  //   color: #fff;
  //   height: 55px;
  //   transition: 0.5s all ease-in-out;
  //   cursor: pointer;
  //   padding: 10px 20px;
  //   border: none;
  //   border-radius: 10px;
  //   span {
  //     margin-left: 25px;
  //     margin-right: 25px;
  //     font-size: 16px;
  //     font-weight: 500;
  //   }
  // }
  // .navlink-wraper {
  //   padding-left: 10px;
  //   ul {
  //     list-style: none;
  //     margin: 0;
  //     padding: 0;
  //     position: relative;
  //     li {
  //       float: left;
  //       font-size: 14px;
  //       margin: 0 5px;
  //       padding: 7px 23px;
  //       position: relative;
  //       cursor: pointer !important;
  //       i {
  //         font-size: 12px;
  //         color: #707070;
  //       }

  //       .sub-shops-wrapper {
  //         position: absolute;
  //         bottom: -355px;
  //         width: 100%;
  //         left: 0;
  //         background-color: #fff;
  //         .shop-sub-branch-wrapper {
  //           background-color: #fff;
  //           display: flex;
  //           width: 100%;
  //         }
  //       }
  //     }
  //     a {
  //       text-decoration: none;
  //       color: #000;
  //     }
  //     .activelists {
  //       background-color: #dcedff;
  //       cursor: pointer;
  //       border-radius: 20px;
  //       color: #15242E
  //       i {
  //         color: #15242E
  //       }
  //     }

  //     .hoverclasss:hover {
  //       background-color: #dcedff;
  //       cursor: pointer;
  //       border-radius: 20px;
  //       color: #15242E
  //       i {
  //         color: #15242E
  //       }
  //     }
  //   }
  // }
  // .wallet-wrapper {
  //   width: 3%;
  //   .wallet-icon {
  //     font-size: 18px;
  //     color: #15242E
  //     position: relative;
  //   }
  // }
}

@media (max-width: 1400px) {
  .deknav-wrapeer {
    width: 100%;

    .nav-links-wrapper {
      // padding: 0 50px;
      width: 100%;
      // background-color: #ffff;
      color: #fff !important;
      position: relative;
      font-size: 13px;

      .nav-linkuls {
        display: flex;
        padding: 0;
        margin: 0;

        .navlinksd {
          list-style: none;
          padding: 6px 10px;
          cursor: pointer;
          a {
            color: #fff;
            text-decoration: none;
            // color: #000;s
          }
          a:hover {
            text-decoration: underline;
          }
        }
        .navlikslinr {
          list-style: none;
          padding: 6px 9px;
          cursor: pointer;
          a {
            text-decoration: none;
            color: #fff;
          }
          a:hover {
            text-decoration: underline;
          }
          .navslists-wrapper {
            background-color: #ffff;
            border: 1px solid #6666;
            position: absolute;
            top: 30px;
            left: 0;
            padding: 15px;
            width: 100%;
            height: auto;
            display: none;
          }
        }
        .navlikslinr:hover {
          .navslists-wrapper {
            display: unset;
          }
        }
      }
    }
    //   .category-btn {
    //     padding: 10px 15px;
    //     width: 200px;
    //     span {
    //       margin-left: 2px;
    //       margin-right: 5px;
    //       font-size: 14px;
    //     }
    //     i {
    //       font-size: 11px;
    //     }
    //   }
    //   .navlink-wraper {
    //     margin-left: 0px;
    //     ul {
    //       li {
    //         margin-left: 0px;
    //         padding: 7px 15px;
    //         i {
    //           font-size: 12px;
    //         }
    //       }
    //     }
    //   }
  }
}

@media (max-width: 1200px) {
  .deknav-wrapeer {
    display: none;
  }
}
