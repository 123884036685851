.shop-items {
  padding-top: 20px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  .items-card-wrapper {
    border: 1px solid #f6f6f6;
    width: 23%;
    height: 370px;
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    margin: 8px 8px;
    .hover-wish-list-wrappper {
      position: absolute;
      cursor: pointer;
      width: 25%;
      right: 0;
      height: 50%;
      padding: 10px;
      transition: 2s;
      display: none;
      .wish-list-imgs {
        float: right;
        img {
          margin-bottom: 10px;
        }
      }
    }
    .itemsone-wrapper {
      position: relative;
      height: 100%;
      .product-imgs-wrap {
        cursor: pointer;
        // padding: 30px;
        align-items: center;
        width: 100%;
        height: 200px;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .items-content-wrappers {
        cursor: pointer;
        padding: 15px;

        .items-name {
          font-size: 14px;
          font-weight: 500;
          height: 40px;
          margin-bottom: 10px;
          display: -webkit-box !important;
          overflow: hidden;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .instock {
          font-size: 11px;
          text-transform: uppercase;
          font-weight: bold;
          color: #f4a34d;
          margin-bottom: auto;
        }
        .last-price {
          font-size: 15px;
          color: #c3c8c9;
          margin-right: 10px;
        }
        .latest-price {
          font-size: 16px;
          font-weight: bold;
          color: #ed1c24;
        }

        .addto-wishlist-btn {
          background-color: transparent;
          border: 1px solid #b8babb;
          border-radius: 20px;
          padding: 5px 12px;
          outline: none;
          width: 100%;
          color: #c3c8c9;
          font-weight: 500;
          margin-top: 20px;
        }
        .addto-cart-btn {
          background-color: transparent;
          border: 1px solid #f4a34d;
          border-radius: 20px;
          padding: 5px 20px;
          outline: none;
          width: 100%;
          color: #f4a34d;
          font-weight: 500;
          margin-top: 10px;
        }
        .addto-cart-btn .loader-spinner .loading-gif {
          animation-name: spin;
          animation-duration: 2000ms;
          animation-iteration-count: infinite;
          animation-timing-function: linear;
        }
        .addto-cart-btn:hover {
          background-color: #f4a34d;
          color: #fff;
        }
        .addto-cart-btn-already {
          background-color: transparent;
          border: 1px solid #f4a34d;
          border-radius: 20px;
          padding: 5px 20px;
          outline: none;
          width: 100%;
          color: #f4a34d;
          font-weight: 500;
          margin-top: 20px;
        }
      }
    }
  }
  .items-card-wrapper:hover {
    .hover-wish-list-wrappper {
      display: block;
    }
  }
}
.paginationContainer1 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  .paginationContainer2 {
    display: flex;
    justify-content: center;
  }
  .spanarrow {
    font: size 22px;
    cursor: pointer;
    background-color: #15242e;
    border-radius: 5px;
    padding: 0 12px;
    display: flex;
    color: white;
    align-items: center;
  }
  .pageLabel {
    font-size: 20px;
    color: #15242e;
    border-radius: 5px;
    padding: 0 12px;
    cursor: pointer;
    display: flex;
    border: 1px solid #15242e;
    align-items: center;
    margin: 0 5px;
  }
  .pageLabelnew {
    font-size: 16px;
    color: white;
    border-radius: 5px;
    padding: 8px 30px;
    background-color: #15242e;
    cursor: pointer;
  }
}
.prevButton {
  background-color: #15242e;
  padding: 5px 15px;
  margin-left: 5px;
  color: white;
  border-radius: 5px;
}
.prevButtonActive {
  background-color: #ffffff;
  padding: 5px 15px;
  margin-left: 5px;
  color: rgb(3, 3, 3);
  border-radius: 5px;
}
.viewcartpara {
  font-size: 12px;
  margin-bottom: 10px;
  .viewcartlink {
    margin-left: 5px;
    color: #15242e;
    font-weight: 700;
  }
}
@media (max-width: 1024px) {
  .shop-items {
    .items-card-wrapper {
      width: 30% !important;
      .itemsone-wrapper {
        align-items: flex-start;
        flex-direction: column !important;

        .product-imgs-wrap {
          text-align: center;
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .shop-items {
    width: 100%;
    flex-direction: column;
    .items-card-wrapper {
      width: 100% !important;
      .addto-wishlist-btn {
        width: 100%;
      }
      .addto-cart-btn {
        width: 100%;
      }
    }
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
