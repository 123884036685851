.blog_page_style {
  background-image: url("../../../public/img/Group\ 88.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 130px 0px;
  .blog_page_style_h1 {
    color: #fff;
    font-size: 50px;
    text-align: center;
    font-weight: 500;
    margin-bottom: 0px;
    text-align: center;
  }
}

.blog_Page_space {
  padding: 50px 0px;
  position: relative;
  .blog_Page_img {
    position: relative;
    img {
      position: relative;
      width: 100%;
      cursor: pointer;
    }
    a {
      position: absolute;
      bottom: 10px;
      left: 10px;
      font-size: 14px;
      background: #fff;
      font-weight: 900;
      color: #fab46e;
      padding: 8px 10px;
      border-radius: 6px;
    }
  }
  .blog_Page_deatils {
    padding: 20px 0px;
    .blog_Page_deatils_h1 {
      cursor: pointer;
      h1.blog_Page_deatils_h1 {
        font-size: 30px;
        font-weight: 800;
      }
    }
    .blog_Page_deatils_date {
      display: flex;
      align-items: center;
      p {
        font-size: 14px;
        color: #fab874;
        font-weight: 500;
        padding-right: 30px;
        i {
          padding-right: 10px;
        }
      }
    }
    p.blog_Page_deatils_P {
      font-size: 14px;
      font-weight: 400;
      span {
        color: #fab874;
        font-weight: 500;
      }
    }
    .blog_Page_deatils_a {
      padding-bottom: 30px;
      border-bottom: 2px solid #f26e31;
      i {
        color: #fff;
        background: #fab874;
        padding: 10px 10px;
        margin-right: 10px;
        border-radius: 4px;
      }
    }
    .blog_Page_deatils_comment {
      padding: 20px 0px;
      h1 {
        font-size: 30px;
        font-weight: 800;
      }
      .blog_Page_deatils_name_border {
        border-bottom: 1px solid #bfb2b2;
        .blog_Page_deatils_reply {
          padding: 0px 0px 0px 80px;
          h4 {
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 0px;
          }
          span {
            font-size: 12px;
            color: #8e8e8e;
            font-weight: 500;
          }
          p {
            font-size: 14px;
          }
          h6 {
            font-size: 14px;
            font-weight: 700;
          }
        }
      }
      .blog_Page_deatils_name {
        padding: 20px 0px;
        display: flex;
        .blog_Page_deatils_logo i {
          background-color: #f2f2f2;
          border-radius: 50%;
          padding: 18px 18px;
        }
        .blog_Page_deatils_reply {
          padding: 0px 0px 0px 25px;
          h4 {
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 0px;
          }
          span {
            font-size: 12px;
            color: #8e8e8e;
            font-weight: 600;
          }
          p {
            font-size: 14px;
          }
          h6 {
            font-size: 14px;
            font-weight: 700;
            cursor: pointer;
          }
          form.blog_Page_deatils_form_tag {
            padding: 20px 0px;
            input.blog_Page_deatils_form_input {
              width: 100%;
              padding: 10px 0px 10px 10px;
              margin-bottom: 20px;
              font-size: 14px;
              border: 1px solid #e8e8e8;
              outline: none;
            }
            .blog_Page_deatils_form_textarea {
              width: 100%;
              padding: 10px 0px 10px 10px;
              margin-bottom: 20px;
              font-size: 14px;
              border: 1px solid #e8e8e8;
              outline: none;
            }
            .blog_Page_deatils_form_checbox {
              display: flex;
              align-items: center;
              input {
                margin-right: 10px;
              }
              p {
                margin-bottom: 0px;
                font-size: 14px;
                color: #8e8e8e;
              }
            }
            .blog_Page_deatils_form_btn {
              margin-top: 35px;
              button {
                background-color: #f26e31;
                color: #fff;
                border: none;
                outline: none;
                padding: 14px 27px;
                font-size: 14px;
                font-weight: 500;
                border-radius: 6px;
              }
            }
          }
        }
      }
    }
    .blog_Page_deatils_form {
      padding: 20px 0px;
      h1 {
        font-size: 30px;
        font-weight: 800;
      }
      p.blog_Page_deatils_form_p {
        font-size: 14px;
        color: #8e8e8e;
        span {
          color: red;
        }
      }
      form.blog_Page_deatils_form_tag {
        padding: 20px 0px;
        input.blog_Page_deatils_form_input {
          width: 100%;
          padding: 10px 0px 10px 10px;
          margin-bottom: 20px;
          font-size: 14px;
          border: 1px solid #e8e8e8;
          outline: none;
        }
        .blog_Page_deatils_form_textarea {
          width: 100%;
          padding: 10px 0px 10px 10px;
          margin-bottom: 20px;
          font-size: 14px;
          border: 1px solid #e8e8e8;
          outline: none;
        }
        .blog_Page_deatils_form_checbox {
          display: flex;
          align-items: center;
          input {
            margin-right: 10px;
          }
          p {
            margin-bottom: 0px;
            font-size: 14px;
            color: #8e8e8e;
          }
        }
        .blog_Page_deatils_form_btn {
          margin-top: 35px;
          button {
            background-color: #f26e31;
            color: #fff;
            border: none;
            outline: none;
            padding: 14px 27px;
            font-size: 14px;
            font-weight: 500;
            border-radius: 6px;
          }
        }
      }
    }
  }
  .blog_Page_recent {
    h3 {
      font-size: 20px;
      font-weight: 700;
    }
    .blog_Page_recent_flex {
      display: flex;
      align-items: center;
      margin-top: 20px;
      .blog_Page_recent_img {
        height: 60px;
        display: flex;
        width: 60px;
        img {
          width: 100%;
          height: auto;
          object-fit: cover;
          cursor: pointer;
        }
      }
      .blog_Page_recent_title p {
        margin-bottom: 0px;
        font-size: 14px;
        font-weight: 600;
        margin-left: 15px;
        cursor: pointer;
      }
    }
  }
  .blog_Page_tags {
    margin-top: 30px;
    h3 {
      font-size: 20px;
      font-weight: 700;
    }
    .col-lg-4 {
      margin-bottom: 12px;
      cursor: pointer;
      p {
        padding: 10px 10px;
        text-align: center;
        border: 1px solid #e0e0e0;
        margin-bottom: 0px;
        font-size: 14px;
      }
    }
  }
  .blog_Page_gallery {
    padding: 20px 0px;
    h3 {
      font-size: 20px;
      font-weight: 700;
    }
    .blog_Page_gellery_img {
      height: 88px;
      display: flex;
      margin-bottom: 15px;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
}

@media (max-width: 470px) {
  .blog_page_style {
    padding: 80px 0px;
    .blog_page_style_h1 {
      font-size: 22px;
    }
  }
}
