.otppopup-modal {
  padding: 20px !important;
  .pin-wrapper {
    align-items: center;
    padding: 25px 0;
    .otp_message_heading {
      text-align: center;
      font-size: 30px;
      font-weight: 600;
      margin-bottom: 44px;
    }
    input {
      border: none;
      font-size: 1.4rem;
      outline: none;
      width: 60px !important;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 15px;
      margin: 0px 10px;
      padding: 3px 10px;
      border-radius: 5px;
      text-align: center;
    }
    .submit-btn1 {
      background-color: #ffa449;
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      margin-top: 10px;
      padding: 10px 30px;
      border: none;
      border-radius: 0px;
      margin-top: 30px;
      outline: none;
      width: 100%;
    }
  }
}
