.rtl_Arabic {
  direction: rtl;

  //   Header
  .nav-top-bar {
    .top-header-wrapper {
      .toprights-wrapper {
        .top-head-line {
          margin-left: 50px;
        }
      }
    }
  }
  //   Header

  // Side Navbar
  @media (max-width: 1200px) {
    .Sidebar-wrapper {
      width: 350px;
      height: 100%;
      padding: 20px;
      background-color: #fff;
      position: fixed;
      z-index: 1;
      top: 0;
      left: 0;
      display: unset;
      transition: 0.5s;
      display: unset;
      overflow-y: scroll;

      .Sidelogo-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        img {
          width: 120px;
        }
        .cut-icon {
          font-size: 21px;
          color: #000;
          cursor: pointer;
        }
      }
      .Sidebar-wrapper.active {
        left: 0;
        transition: 350m;
      }

      .sidebar-itemswrapper {
        margin-top: 20px;

        ul {
          list-style: none;
          padding: 0;
          a {
            text-decoration: none;
            color: #000;

            li {
              padding: 10px 16px;
              margin: 0px 0;
              border-right: 5px solid #fff;
              height: 50px;
            }
            .serchbarweapps {
              display: none;
            }
          }
          a:hover {
            li {
              cursor: pointer;
              color: #15242e;
              border-right: 3px solid #1abf5d;
            }
          }
        }
        .deliverylocatline {
          padding: 10px 16px;
          cursor: pointer;
          margin: 0px 0;
          border-right: 5px solid #fff;
          height: 50px;
        }
      }
    }
  }
  @media (max-width: 767px) {
    .Sidebar-wrapper {
      width: 100%;
      display: unset;
      overflow-y: scroll;

      .sidebar-itemswrapper {
        ul {
          a {
            .serchbarweapps {
              display: unset;
              .serch-bartopside {
                .serch-btn-top-bg {
                  right: 95%;
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 576px) {
    .Sidebar-wrapper {
      width: 100%;
      display: unset;
      overflow-y: scroll;

      .sidebar-itemswrapper {
        ul {
          a {
            li {
              font-size: 14px;
              .serch-bartopside {
                .serch-btn-top-bg {
                  right: 87% !important;
                  font-size: 19px;
                  width: 32px;
                  height: 32px;
                }
              }
            }
          }
        }
      }
    }
  }
  // Side Navbar

  //   Navbar
  .nav-main {
    .column-left {
      .threebar {
        display: none;
      }
    }
    .column-center {
      width: 69%;
      height: 65px;
      margin-right: 70px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .location-wrapeer {
        padding: 7px 5px;
        height: 45px;
        position: relative;
        width: 26%;
        border-radius: 23px;
        border: 1px solid #eceff0;
        background-color: #fff;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .current-locationline {
          margin-left: 10px;
          font-size: 11px;
        }

        .down-arow {
          right: 25px;
          font-size: 13px;
          padding: 5px;
          color: #858585;
        }
        .location-description {
          font-size: 15px;
          color: #858585;
          width: 50%;
        }
      }
      .serch-inputwrapperLoc {
        width: 100%;
        position: relative;

        .serch-bartop {
          width: 100%;

          input {
            position: relative;
            width: 100%;
            padding: 10px 10px 10px 20px;
            border-radius: 30px;
            outline: none;
            border: 1px solid #eceff0;
            background-color: #f9f9f9;
          }

          .serch-btn-top-bg {
            position: absolute;
            left: 5px;
            top: 5px;
            background-color: #f9f9f9;
            font-size: 19px;
            width: 37px;
            height: 37px;
            color: #fff;
            border: none;
            outline: none;
            border-radius: 25px;
          }
        }
        .ajexsBox-wrapper {
          width: 100%;
          position: absolute;
          padding: 5px 10px;
          z-index: 2;
          max-height: 400px;
          overflow-y: scroll;
          scroll-behavior: smooth;

          .ajesitems {
            display: flex;
            align-items: center;
            background-color: #fff;
            border: 1px solid #eceff0;
            list-style: none;
            padding: 8px 16px;
            border-radius: 3px;
            cursor: pointer;
            .ajexbox-imgwrapp {
              width: 30px;
              height: 30px;
              margin-right: 5px;
            }
            img {
              width: 100%;
              height: 100%;
            }
            span {
              font-size: 12px;
            }
          }

          .ajesitems:hover {
            display: flex;
            align-items: center;
            background-color: #f9f9f9;
            border: 1px solid #eceff0;
            list-style: none;
            padding: 8px 16px;
            border-radius: 3px;
            cursor: pointer;
            .ajexbox-imgwrapp {
              width: 30px;
              height: 30px;
              margin-right: 5px;
            }
            img {
              width: 100%;
              height: 100%;
            }
            span {
              font-size: 12px;
            }
          }
        }
        ::-webkit-scrollbar {
          width: 5px;
        }
      }
      .serch-inputwrapper {
        width: 60%;
        margin-left: 30px;
        margin-right: 30px;
        position: relative;

        .serch-bartop {
          width: 100%;
          font-size: 16px;

          .css-1s2u09g-control {
            border: 1px solid #eceff0;
            border-radius: 23px;
          }
          .css-1pahdxg-control {
            border: 1px solid #eceff0;
            box-shadow: none;
            border-radius: 23px;
          }

          input {
            position: relative;
            width: 100%;
            padding: 10px 70px 10px 20px;
            border-radius: 30px;
            outline: none;
            border: 1px solid #eceff0;
            height: 33px;
            background-color: #f9f9f9;
          }

          .serch-btn-top-bg {
            position: absolute;
            right: 91%;
            top: 5px;
            background-color: #15242e;
            font-size: 19px;
            width: 37px;
            height: 37px;
            color: #fff;
            border: none;
            outline: none;
            border-radius: 25px;
          }
        }
        .ajexsBox-wrapper {
          width: 100%;
          position: absolute;
          padding: 5px 10px;
          z-index: 2;
          max-height: 400px;
          overflow-y: scroll;
          scroll-behavior: smooth;

          .ajesitems {
            display: flex;
            align-items: center;
            background-color: #fff;
            border: 1px solid #eceff0;
            list-style: none;
            padding: 8px 16px;
            border-radius: 3px;
            cursor: pointer;
            .ajexbox-imgwrapp {
              width: 30px;
              height: 30px;
              margin-right: 5px;
            }
            img {
              width: 100%;
              height: 100%;
            }
            span {
              font-size: 12px;
            }
          }
          .ajesitems.selected {
            background-color: #ed1c24 !important;
          }
          .ajesitems:hover {
            display: flex;
            align-items: center;
            background-color: #f9f9f9;
            border: 1px solid #eceff0;
            list-style: none;
            padding: 8px 16px;
            border-radius: 3px;
            cursor: pointer;
            .ajexbox-imgwrapp {
              width: 30px;
              height: 30px;
              margin-right: 5px;
            }
            img {
              width: 100%;
              height: 100%;
            }
            span {
              font-size: 12px;
            }
          }
        }
        ::-webkit-scrollbar {
          width: 5px;
        }
      }
      .cammparee-imgwrapepr {
        transform: rotate(90deg);
        width: 25px;
        cursor: pointer;
      }
    }
    .column-right {
      display: flex;
      margin-right: 20px;
      justify-content: space-between;
      align-items: center;

      .search-txt {
        display: none;
      }
      .search-btn {
        i {
          display: none;
        }
      }

      span {
        margin-right: 10px;
        margin-left: 10px;
        font-weight: 400;
      }
      .cart-bgwrap {
        position: relative;
        margin-right: 0px !important;
        .cart-counting {
          background-color: #ed1c24;
          width: 17px;
          text-align: center;
          height: 17px;
          color: #fff;
          font-size: 10px;
          position: absolute;
          right: -10px;
          border-radius: 30px;
        }
      }
    }
  }

  @media (max-width: 1200px) {
    .nav-main {
      .column-left {
        display: flex;
        align-items: center;
        .threebar {
          margin-left: 20px;
          display: unset;
          font-size: 21px;
        }
      }
      .column-center {
        width: 69%;
        margin-right: 30px;
        margin-left: 0px;

        .location-wrapeer {
          display: none;
        }
        .serch-inputwrapper {
          width: 100%;
          margin-left: 0px;
          margin-right: 0px;
          .serch-bartop {
            .serch-btn-top-bg {
              right: 87%;
            }
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    .nav-main {
      justify-content: space-between;
      .column-center {
        display: none;
        margin-left: 0;
        width: 40%;

        .location-wrapeer {
          display: none;
        }
        .serch-inputwrapper {
          display: none;
        }
        .cammparee-imgwrapepr {
          display: none;
        }
      }
      .column-right {
        margin-left: 5px;
        position: relative;

        span {
          margin-left: 10px;
          font-weight: 400;
        }
        .cart-bgwrap {
          position: relative;
          margin-left: 20px;

          .cart-counting {
            background-color: #ed1c24;
            width: 15px;
            text-align: center;
            height: 15px;
            color: #fff;
            font-size: 9px;
            position: absolute;
            right: 8;
            border-radius: 30px;
          }
        }
      }
    }
  }

  @media (max-width: 568px) {
    .nav-main {
      padding: 10px 0px;
      justify-content: space-between;
      .column-left {
        display: flex;
        align-items: center;
        .logo-img {
          width: 50%;
          margin-left: 10px;
        }
        .threebar {
          display: block;
          font-size: 16px;
          margin-right: 0;
        }
      }
      .column-center {
        margin-left: 0;
        display: none;

        .location-wrapeer {
          display: none;
        }
        .serch-inputwrapper {
          display: none;
        }
      }
      .column-right {
        margin-left: 5px;

        .user-img {
          cursor: pointer;
          img {
            width: 30px;
            cursor: pointer;
          }
        }

        span {
          margin-left: 10px;
          font-weight: 400;
        }
        .cart-bgwrap {
          position: relative;
          margin-left: 20px;
          img {
            width: 30px;
          }
          .cart-counting {
            width: 9px;
            height: 9px;
            color: #fff;
            font-size: 6px;
            right: -10px;
          }
        }
      }
    }
  }

  //   Navbar

  //   Desk navbar

  .deknav-wrapeer {
    // border-bottom: 1px solid #e9e9e9;
    padding: 5px 0;
    width: 100%;
    background-color: #fff;
    position: relative;
    .category-btn {
      background-color: #15242e;
      color: #fff;
      height: 55px;
      transition: 0.5s all ease-in-out;
      cursor: pointer;
      padding: 10px 20px;
      border: none;
      border-radius: 10px;
      span {
        margin-left: 25px;
        margin-right: 25px;
        font-size: 16px;
        font-weight: 500;
      }
    }
    .navlink-wraper {
      padding-right: 10px;
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        position: relative;
        li {
          float: right;
          font-size: 14px;
          margin: 0 5px;
          padding: 7px 23px;
          position: relative;
          cursor: pointer !important;
          i {
            margin-left: 5px;
          }

          .sub-shops-wrapper {
            position: absolute;
            bottom: -355px;
            width: 100%;
            left: 0;
            background-color: #fff;
            .shop-sub-branch-wrapper {
              background-color: #fff;
              display: flex;
              width: 100%;
            }
          }
        }
        a {
          text-decoration: none;
          color: #000;
        }
        .activelists {
          background-color: #dcedff;
          cursor: pointer;
          border-radius: 20px;
          color: #15242e;
          i {
            color: #15242e;
          }
        }

        .hoverclasss:hover {
          background-color: #dcedff;
          cursor: pointer;
          border-radius: 20px;
          color: #15242e;
          i {
            color: #15242e;
          }
        }
      }
    }
    .wallet-wrapper {
      width: 3%;
      .wallet-icon {
        font-size: 18px;
        color: #15242e;
        position: relative;
      }
    }
  }

  @media (max-width: 1400px) {
    .deknav-wrapeer {
      width: 100%;
      .category-btn {
        padding: 10px 15px;
        width: 200px;
        span {
          margin-left: 2px;
          margin-right: 5px;
          font-size: 14px;
        }
        i {
          font-size: 11px;
        }
      }
      .navlink-wraper {
        margin-left: 0px;
        ul {
          li {
            margin-left: 0px;
            padding: 7px 15px;
            i {
              font-size: 12px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1200px) {
    .deknav-wrapeer {
      display: none;
    }
  }

  //   Desk navbar

  // Category

  .Categories-dropdown {
    width: 275px;
    height: 100%;
    position: absolute;
    top: 66px;
    transition: 0.5s all ease-in-out;
    z-index: 1;

    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      border: 1px solid #f6f6f6;
      background-color: #ffff;
      transition: 0.2s;
      padding: 8px 0px;

      a {
        text-decoration: none;
        color: #000;
      }

      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 9px 23px;
        font-size: 14px;
        cursor: pointer;
        i {
          font-size: 8px;
          transform: rotate(180deg);
        }
        .catigores-itemswrapper {
          display: none;
          .Christms-bgwrapper {
            right: 275px;

            .categorylists-wrapper {
              .sub-catigors-wrapp {
                border: none;
                background: none;
                .sub-catigors-items {
                  list-style: none;
                  padding: 8px 25px 8px 0px;
                }
              }
              .items-border {
                border-bottom: 1px solid #dedede;
              }
            }
            .categoryrightsidewrapp {
              height: 300px;
              .sub-catigors-wrappd {
                border: none;
                background: none;
                height: 150px;
                .sub-catigors-itemss {
                  list-style: none;
                  padding: 8px 25px 8px 0px;
                }
              }
              .rightsidewrapp {
                height: 300px;
                width: 200px;
              }
              .categoryimgwrappp {
                width: 200px;
                height: 200px;
                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
        }
      }
      li:hover {
        .catigores-itemswrapper {
          display: unset;
          transition: all 0.2s ease;
        }
      }
    }
    .viewall-category-dropdown {
      display: flex;
      justify-content: space-between;
      padding: 9px 23px;
      cursor: pointer;
      font-size: 14px;
      align-items: center;
      color: #15242e;
      background-color: #ffff;
      border: 1px solid #f6f6f6;
      a {
        text-decoration: none;
        color: #15242e;
      }

      i {
        font-size: 8px;
        transform: rotate(90deg);
      }
    }
  }

  @media (max-width: 1400px) {
    .Categories-dropdown {
      ul {
        li {
          padding: 7px 23px;
          font-size: 13px;

          .catigores-itemswrapper {
            .Christms-bgwrapper {
              padding: 20px 2px 10px 0px;
            }
          }
        }
        li:hover {
          .catigores-itemswrapper {
            display: unset;
            transition: 0.2s;
          }
        }
      }
      .viewall-category-dropdown {
        display: flex;
        justify-content: space-between;
        padding: 9px 23px;
        cursor: pointer;
        font-size: 14px;
        align-items: center;
        color: #15242e;
        background-color: #ffff;
        border: 1px solid #f6f6f6;
        a {
          text-decoration: none;
          color: #15242e;
        }

        i {
          font-size: 8px;
          transform: rotate(90deg);
        }
      }
    }
  }

  // Category

  // Wallet Amount
  .walletpopup-wrappers {
    background-color: white;
    //   position: relative;
    border-radius: 15px;
    text-align: center;
    width: 200px;
    padding: 20px 20px;
    position: absolute;
    z-index: 1;
    right: 81%;
    // left: -100px;
    top: 50px;
    box-shadow: 0px 0px 3px 2px rgba(190, 189, 189, 0.1);

    .walletamount-line {
      font-size: 31px;
      color: #15242e;
      margin-top: 10px;
      sub {
        bottom: 0em;
      }
    }
    .current-balance {
      font-size: 16px;
      color: black;
    }
    i {
      position: absolute;
      left: 0;
      color: white;
      right: 0;
      top: -10px;
      font-size: 31px;
      transform: rotate(180deg);
    }
  }

  @media (max-width: 1550px) {
    .walletpopup-wrappers {
      right: 86%;
    }
  }

  @media (max-width: 1400px) {
    .walletpopup-wrappers {
      right: 84%;
    }
  }

  // Wallet Amount

  //   Best Seller

  .bestSeller-wrapp {
    margin-top: 50px;
    margin-bottom: 50px;
    display: flex;
    justify-content: space-between;

    .Side-benner-wrapper {
      width: 25%;
      cursor: pointer;
    }
    .addto-cart-btn-already {
      background-color: transparent;
      border: 1px solid #f4a34d;
      border-radius: 20px;
      padding: 5px 20px;
      outline: none;
      width: 100%;
      color: #f4a34d;
      font-weight: 500;
      margin-top: 20px;
    }
    .bestSells-wrappe {
      padding: 0 15px;
      width: 75%;

      .topline-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .BestSellerstag {
          font-size: 24px;
          color: #000;
          font-weight: 500;
        }
        .viewallbtn {
          background-color: #f4a34d;
          color: #fff;
          text-transform: uppercase;
          font-size: 13px;
          padding: 5px 16px;
          border-radius: 50px;
          border: none;
          outline: none;

          cursor: pointer;
        }
      }
    }
    .web-responsive {
      .slick-arrow {
        display: none !important;
      }
      .productitems-wraper {
        border: 1px solid #f6f6f6;
        margin-top: 15px;
        .product-wrapper {
          border-right: 2px solid #f6f6f6;
          border-bottom: 2px solid #f6f6f6;
          position: relative;
          height: 400px;
          .hover-wish-list-wrappper {
            position: absolute;
            cursor: pointer;
            width: 25%;
            height: 50%;
            padding: 10px;
            transition: 2s;
            right: 0;
            display: none;
            .wish-list-imgs {
              float: right;
              img {
                margin-bottom: 10px;
              }
            }
          }

          .product-imgs-wrap {
            // padding: 30px;
            align-items: center;
            height: 200px;
            width: 100%;
            cursor: pointer;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .items-content-wrappers {
            //
            direction: rtl;
            padding: 15px;
            cursor: pointer;
            .items-name {
              font-size: 14px;
              font-weight: 500;
              height: 40px;
              margin-bottom: 10px;
              display: -webkit-box;
              overflow: hidden;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
            .instock {
              font-size: 11px;
              text-transform: uppercase;
              font-weight: bold;
              color: #15242e;
              margin-bottom: auto;
            }
            .last-price {
              font-size: 15px;
              color: #c3c8c9;
              margin-right: 10px;
            }
            .latest-price {
              font-size: 16px;
              font-weight: bold;
              color: #ed1c24;
            }
            .addto-cart-btn {
              background-color: transparent;
              border: 1px solid #f4a34d;
              border-radius: 20px;
              padding: 5px 20px;
              outline: none;
              width: 100%;
              color: #f4a34d;
              font-weight: 500;
              margin-top: 20px;
            }

            .addto-cart-btn .loader-spinner .loading-gif {
              animation-name: spin;
              animation-duration: 2000ms;
              animation-iteration-count: infinite;
              animation-timing-function: linear;
            }
            .addto-cart-btn:hover {
              background-color: #f4a34d;
              color: #fff;
            }
          }
        }
        .product-wrapper:hover {
          .hover-wish-list-wrappper {
            display: block;
          }
        }
      }
    }
    .mobresponsive-Slide {
      display: none;
      .slick-arrow {
        display: none !important;
      }
    }
  }

  @media (max-width: 1200px) {
    .bestSeller-wrapp {
      flex-direction: column;
      .Side-benner-wrapper {
        display: none;
      }
      .bestSells-wrappe {
        order: 1;
        padding: 0 15px;
        width: 100%;
      }
    }
  }

  @media (max-width: 992px) {
    .bestSeller-wrapp {
      margin-top: 50px;
      margin-bottom: 50px;
      display: flex;
      justify-content: space-between;

      .bestSells-wrappe {
        padding: 0px;
        width: 100%;

        .topline-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .BestSellerstag {
            font-size: 24px;
            color: #000;
            font-weight: 500;
          }
          .viewallbtn {
            background-color: #f4a34d;
            color: #fff;
            text-transform: uppercase;
            font-size: 13px;
            padding: 5px 16px;
            border-radius: 50px;
            border: none;
            outline: none;
            cursor: pointer;
          }
        }
      }
    }
  }

  @media (max-width: 576px) {
    .bestSeller-wrapp {
      .web-responsive {
        .productitems-wraper {
          .product-wrapper {
            height: auto;
            // .product-imgs-wrap {
            //   padding: 30px;
            // }
            .items-content-wrappers {
              padding: 15px;

              .addto-cart-btn {
                padding: 5px 10px;
              }
            }
          }
        }
      }
    }
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  //   Best Seller

  //   Testimonial Web
  .testimonial-bg {
    background-color: #f7f7f7;
    padding-top: 30px;
    padding-bottom: 150px;
    .slick-dots {
      bottom: 0 !important;
      li {
        width: 10px;
      }
    }
    .slick-arrow {
      display: none !important;
    }
    .Testimonial-review-wrap {
      //
      text-align: right;
      background-color: #fff;
      padding: 20px;
      padding-bottom: 40px;
      border: 1px solid #f8f8f8;
      height: 300px;
      .testi-heading {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 5px;
      }
      .TheBest-Marketplace {
        font-size: 12px;
        font-style: italic;
        font-weight: bold;
        margin-bottom: 05px;
      }
      .TheBest-para {
        font-size: 14px;
        color: #c2bebe;
        display: -webkit-box;
        -webkit-line-clamp: 6;
        overflow: hidden;
        -webkit-box-orient: vertical;
      }
      .hr-wrapper {
        display: flex;
        align-items: center;
        .hr-content-wrap {
          margin-left: 30px;
          .hr-name {
            font-size: 16px;
            font-weight: 500;
            color: #e11515;
          }
          .post-name {
            color: #868b8d;
            font-size: 15px;
          }
        }
      }
    }

    .testimonial-items-wrapper {
      background-color: #fff;
      // margin-top: 20px;
      a {
        text-decoration: none;
        color: #000;
      }
      .test-items-wrap {
        display: flex;
        padding: 15px;
        height: 150px;
        align-items: center;
        border: 1px solid #ededed;
        .testCatigory-imgs {
          width: 100px;
          padding: 10px;
          img {
            width: 100%;
            height: 100%;
          }
        }

        .productnameswrapper {
          display: flex;
          flex-direction: column;
          margin-left: 10px;

          .products-Name {
            font-size: 12px;
            font-weight: 800;
            color: #565a67;
          }
          .items-quantety {
            color: #a39d9d;
            font-size: 11px;
            margin-top: 5px;
          }
          .viewmorebtn {
            color: #15242e;
            text-transform: capitalize;
            font-size: 13px;
            padding: 5px 16px;

            cursor: pointer;
          }
        }
      }
    }
  }
  @media (max-width: 1024px) {
    .testimonial-bg {
      #testimonial-mob-view {
        order: 2;
        margin-top: 20px;
        // margin-bottom: 20px;
      }
    }
  }
  @media (max-width: 767px) {
    .testimonial-bg {
      .testimonial-mob-view {
        margin-bottom: 20px;
      }
    }
  }

  @media (max-width: 568px) {
    .testimonial-bg {
      // background-color: #f7f7f7;
      padding-top: 10px;
      padding-bottom: 80px;

      .testimonial-mob-view {
        order: 2;
      }

      .testimonial-items {
        order: 1;
      }

      .testimonial-items-wrapper {
        background-color: #fff;
        border: 2px solid #ededed;
        margin-top: 20px;
        .test-items-wrap {
          display: flex;
          flex-direction: column;
          padding: 0px 20px;
          justify-content: center;
          height: 170px;
          align-items: center;
          border: 1px solid #ededed;
          img {
            width: 80px;
            margin-bottom: 10px;
          }
          .products-Name {
            font-size: 12px;
            font-weight: 500;
            color: #565a67;
            text-align: center;
          }
        }
      }
    }
  }

  //   Testimonial Web
  // News Letter

  .Footer-wrapper {
    background-color: #15242e;
    color: #fff;
    float: left;
    width: 100%;

    .footer-serch-wrapper {
      background-color: #15242e;
      width: 100%;
      justify-content: space-between;
      margin-top: -45px;
      margin-bottom: 60px;
      color: #fff;
      border-radius: 70px;
      padding: 15px 30px;
      .footer-exvlopwrapper {
        display: flex;
        .envalop-wrapeer {
          display: flex;
          align-items: center;
          width: 50%;
          .envlop-imgwrapper {
            width: 60px;
          }
          .sing-in-line {
            font-size: 22px;
            margin-right: 15px;
            font-weight: 600;
          }
        }
        .footinput-wrapeer {
          display: flex;
          width: 50%;
          align-items: center;
          position: relative;
        }
        .serch-inputs {
          width: 100%;
          height: 55px;
          padding: 10px 20px 10px 165px;
          border-radius: 50px;
          outline: none;
          border: none;
        }
        .search-btns-wrap {
          position: absolute;
          right: 74%;
          width: 150px;
          background-color: #15242e;
          color: #fff;
          padding: 10px;
          border-radius: 70px;
          outline: none;
          font-weight: bold;
          border: none;
        }
      }
    }

    .footer-links-wrapeer {
      width: 100%;
      margin-bottom: 80px;
      .categories-wrapperd {
        width: 100%;

        .categorilinks-wrap {
          .footer-links-heading {
            font-size: 16px;
            font-weight: 800;
          }
          .categreslink-wraped {
            display: flex;
            .categorilinkswraped {
              list-style: none;
              padding: 0;
              margin-right: 30px;
              li {
                margin-bottom: 10px;
                cursor: pointer;
                font-size: 14px;
              }

              li:hover {
                transition: 0.2s;
                text-decoration: underline;
              }
            }
          }
        }
      }
      .Store-details-wrapee {
        // width: 25%;
        font-size: 14px;
        display: inline-block;
        .footer-link-heading {
          font-size: 16px;
          font-weight: 800;
        }
        .store-loction-wrappe {
          font-size: 13px;
          width: 80%;
          margin-bottom: 10px;
        }
      }
      .footerlinks-wrap {
        display: inline-block;
        .footer-links-heading {
          font-size: 16px;
          font-weight: 800;
        }
      }
      .pages-link {
        color: #fff;
        text-decoration: none;
        margin-right: 8px;
      }
    }
    .copyright-bg {
      background-color: #0069dd;
      color: #fff;
      font-size: 13px;
      padding: 15px 0;
      align-items: center;

      .footer-copy-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .sociallinksWrapper {
          display: flex;
          a {
            text-decoration: none;
          }

          .sociallinksWrapper-insta {
            margin-left: 6px;
            margin-right: 6px;
          }
        }
      }
    }
  }

  a.effect-underline:after {
    content: "";
    position: absolute;
    left: 0;
    display: inline-block;
    height: 1em;
    width: 100%;
    border-bottom: 1px solid;
    margin-top: 10px;
    opacity: 0;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    -webkit-transform: scale(0, 1);
    transform: scale(0, 1);
  }

  a.effect-underline:hover:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  @media (max-width: 1399px) {
    .Footer-wrapper {
      .footer-serch-wrapper {
        .footer-exvlopwrapper {
          .search-btns-wrap {
            right: 70%;
          }
        }
      }
    }
  }

  @media (max-width: 1200px) {
    .Footer-wrapper {
      .footer-serch-wrapper {
        .footer-exvlopwrapper {
          .search-btns-wrap {
            right: 64%;
          }
        }
      }
    }
  }
  @media (max-width: 992px) {
    .Footer-wrapper {
      .footer-serch-wrapper {
        .footer-exvlopwrapper {
          .search-btns-wrap {
            right: 60%;
          }
        }
        .envalop-wrapeer {
          .sing-in-line {
            font-size: 16px;
            margin-left: 10px;
          }
        }
      }
      .footer-links-wrapeer {
        .categories-wrapperd {
          .categorilinks-wrap {
            margin-top: 10px;
          }
        }
        .Store-details-wrapee {
          margin-top: 10px;
        }
        .footerlinks-wrap {
          margin-top: 10px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .Footer-wrapper {
      .footer-serch-wrapper {
        flex-direction: column;
        border-radius: 31px;
        margin-bottom: 30px;
        .footer-exvlopwrapper {
          flex-direction: column;
          .serch-inputs {
            padding: 8px 89px 8px 15px;
          }
          .search-btns-wrap {
            width: 80px;
            font-size: 14px;
            right: 85%;
          }
          .envalop-wrapeer {
            width: 100%;
            justify-content: space-between;
            .sing-in-line {
              font-size: 12px;
              margin-left: 10px;
            }
          }
          .footinput-wrapeer {
            width: 100%;
            margin-top: 10px;
          }
        }
      }
      .footer-links-wrapeer {
        .categories-wrapperd {
          .categorilinks-wrap {
            margin-top: 10px;
          }
        }
        .Store-details-wrapee {
          margin-top: 10px;
        }
        .footerlinks-wrap {
          margin-top: 10px;
        }
      }
    }
  }
  @media (max-width: 568px) {
    .Footer-wrapper {
      .footer-serch-wrapper {
        .footer-exvlopwrapper {
          .serch-inputs {
            padding: 8px 13px 8px 89px;
          }
          .search-btns-wrap {
            right: 62%;
          }
          .envalop-wrapeer {
            img {
              width: 20%;
            }
          }
          .footinput-wrapeer {
            width: 100%;
            margin-top: 10px;
          }
        }
      }
      .footer-links-wrapeer {
        margin-bottom: 40px;
        .categories-wrapperd {
          .categorilinks-wrap {
            .categreslink-wraped {
              flex-direction: column;
              .categorilinkswraped {
                margin-right: 0;
                margin-bottom: 0;
              }
            }
          }
        }
        .Store-details-wrapee {
          margin-top: 10px;
        }
      }
      .copyright-bg {
        .footer-copy-wrapper {
          flex-direction: column;
          align-items: flex-start;
        }
      }
    }
  }

  // News Letter

  //   Shop pages

  .shopsbg {
    background-color: #f6f6f6;
    padding-top: 50px;
    padding-bottom: 90px;
    .Shops-wrappper {
      display: flex;
      flex-wrap: wrap;
      // justify-content: space-around;

      .shop-wrapp {
        width: 23%;
        background-color: #fff;
        border: 1px solid #f6f6f6;
        margin-bottom: 30px;
        height: 310px;
        margin-left: 10px;
        margin-right: 10px;
        a {
          text-decoration: none;
          color: #000;
        }

        .shopeimg {
          width: 100%;
          height: 200px;
        }
        .shops-detais-wrapp {
          padding: 15px 10px;
          position: relative;
          background: #fff;
          // height: 200px
          .shoplogowrapper {
            position: absolute;
            width: 50px;
            height: 50px;
            top: -23px;
            right: 78%;
            border-radius: 30px;
            border: 2px solid #15242e;
            background-image: url("../../images/shoplogo-2.png");
            background-size: cover;
            background-position: center;
          }
          .shopNameline {
            font-size: 16px;
            text-transform: capitalize;
            margin-bottom: 15px;
            font-weight: 700;
          }
          .shop-addres {
            font-size: 13px;
            color: #656572;
            margin-bottom: 0px;
            height: 20px;
            display: -webkit-box;
            overflow: hidden;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }
          .visit-store-btn {
            background: none;
            border: none;
            font-size: 12px;
            color: #15242e;
            padding: 0;
            height: 20px;
          }
        }
      }
    }
  }
  @media (max-width: 1200px) {
    .shopsbg {
      .Shops-wrappper {
        .shop-wrapp {
          width: 22.8%;
          .shops-detais-wrapp {
            .shoplogowrapper {
              right: 68%;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1024px) {
    .shopsbg {
      .Shops-wrappper {
        .shop-wrapp {
          width: 30.4%;
          .shops-detais-wrapp {
            .shoplogowrapper {
              right: 75%;
            }
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .shopsbg {
      padding-top: 20px;
      padding-bottom: 60px;
      .Shops-wrappper {
        // flex-direction: column;
        .shop-wrapp {
          width: 45%;
          margin-left: 10px;
          margin-right: 10px;
          .shopeimg {
            width: 100%;
          }
          .shops-detais-wrapp {
            padding: 15px 10px;
            .shoplogowrapper {
              right: 75%;
            }
          }
        }
      }
    }
  }
  @media (max-width: 576px) {
    .shopsbg {
      .Shops-wrappper {
        .shop-wrapp {
          width: 100%;

          .shops-detais-wrapp {
            padding: 15px 10px;
            .shoplogowrapper {
              right: 78%;
            }
          }
        }
      }
    }
  }

  //   Shop pages

  // Shop details Page
  .sideform-wrapper {
    background-color: #f5f5f5;
    padding: 20px;
    .store-category-wrappers {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
      cursor: pointer;
      .store-category-heading {
        font-weight: 700;

        font-size: 18px;
      }
      .store-category-subheading {
        font-size: 13px;
        margin-bottom: 10px;
        color: #5d5d5d;
      }
    }
    .sideads-imgwrapper {
      width: 100%;
      height: 100%;
    }
  }

  .productscategory-wrappernew {
    background-color: #f5f5f5;
    padding: 0 10px;
    .product-heading {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 0;
      padding: 8px 16px;
    }
    .product-category-itmes-wrapper {
      ul {
        padding: 0;
        margin: 0;
        list-style: none;
        padding: 8px 0px;
        li {
          label {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 7px 16px;
            cursor: pointer;
            font-size: 12px;
            span {
              .checkboxclas {
                margin-right: 10px;
              }
            }

            label {
              display: flex;
              align-items: center;
              cursor: pointer;
            }
            i {
              font-size: 8px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1024px) {
    .sideform-wrapper {
      margin-top: 30px;
      .store-category-wrappers {
        .store-category-heading {
          font-weight: 700;
          font-size: 18px;
        }
      }
    }
  }

  // Shop details Page

  //   contect page

  .conect-bg {
    background-color: #f8f8f8;
    width: 100%;
    padding-top: 50px;
    padding-bottom: 150px;

    .info-bg {
      background-color: #15242e;
      height: 100%;
      padding: 50px;
      .location-wrapper {
        display: flex;
        margin-bottom: 30px;
      }
      .location-texts {
        ul {
          list-style: none;
          margin: 0 0 0 0px;
          padding: 0 20px 0 0px;
          //
          li {
            font-size: 13px;
            a {
              color: #fff;
              text-decoration: none;
              font-weight: bold;
            }
          }
          .locationt-hading {
            font-size: 15px;
            color: #fff;
          }
        }
      }
      .social-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 100px;

        ul {
          list-style: none;
          text-align: center;
          margin: 0px;
          padding: 0;
          li {
            float: left;
            width: 80px;
            a {
              text-decoration: none;
              color: #fff;
            }
            i {
              font-size: 31px;
            }
          }
        }
      }
    }
    .contact-form-wrapper {
      background-color: #fff;
      padding: 30px;
      height: 100%;
      .contact-text {
        font-size: 16px;
        font-style: italic;
        color: #e82d34;
        font-weight: 400;
        margin-bottom: 0px;
      }
      .Get-in-touch {
        font-size: 41px;
        font-weight: bold;
        margin-bottom: 30px;
      }

      .contecttextinpt {
        height: 50px;
        width: 100%;
        margin-bottom: 20px;
        background-color: #f3f4f7;
        border: 4px solid #eeeef0;
        padding: 8px 16px;
        font-size: 14px;
        border: none;
        outline: none;
      }

      .mobnumcon::-webkit-outer-spin-button,
      .mobnumcon::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      .contect-form-txtrea {
        background-color: #f3f4f7;
        border: none;
        width: 100%;
        outline: none;
        padding: 20px;
        font-size: 14px;
      }
      .send-btn {
        background-color: #e82d34;
        border-radius: 50px;
        padding: 10px 16px;
        font-size: 18px;
        outline: none;
        color: #fff;
        margin-top: 30px;
      }
    }
  }

  @media (max-width: 1400px) {
    .conect-bg {
      .info-bg {
        padding: 45px;
        .social-wrapper {
          ul {
            li {
              float: left;
              width: 70px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1200px) {
    .conect-bg {
      .contact-form-wrapper {
        .Get-in-touch {
          font-size: 30px;
          margin-bottom: 20px;
        }

        .send-btn {
          font-size: 16px;
        }
      }
    }
  }
  @media (max-width: 1024px) {
    .conect-bg {
      .info-bg {
        padding: 30px;
        .location-wrapper {
          margin-bottom: 30px;
        }

        .social-wrapper {
          ul {
            li {
              float: left;
              width: 70px;
            }
          }
        }
      }

      .contact-form-wrapper {
        .Get-in-touch {
          font-size: 28px;
        }

        .send-btn {
          font-size: 16px;
        }
      }
    }
  }
  @media (max-width: 767px) {
    .conect-bg {
      padding-top: 20px;
      #formSec {
        order: 1;
      }
      #infoSec {
        order: 2;
        margin-top: 15px;
      }
    }
  }

  @media (max-width: 568px) {
    .conect-bg {
      padding-top: 20px;
      padding-bottom: 100px;

      .info-bg {
        padding: 15px;
        .location-wrapper {
          margin-bottom: 30px;
        }
        .location-texts {
          ul {
            li {
              font-size: 13px;
            }
          }
        }
        .social-wrapper {
          margin-top: 70px;
          ul {
            li {
              float: left;
              width: 65px;
            }
          }
        }
      }
      .contact-form-wrapper {
        padding: 15px;
        .contact-text {
          font-size: 13px;
        }
        .Get-in-touch {
          font-size: 24px;
          margin-bottom: 20px;
        }

        .send-btn {
          font-size: 11px;
          margin-top: 20px;
        }
      }
    }
  }

  //   contect page

  // Deshbord pages Tabs

  .UserDeshbord-bg {
    .deshbordtabs-wrapper {
      .left-side-wrapp {
        .user-details-wrapper {
          .userimg-wrapper {
            margin-left: 10px;
          }
        }
        .Deshbord-tab-wrapp {
          p {
            .iconsboxsd {
              margin-left: 10px;
              margin-right: 0px;
            }
          }
        }
      }
    }
  }

  // My Order Tab

  .order-items-wrapper {
    .order-product-wrapper {
      .orders-btns {
        .trackpackage-btn {
          i {
            margin-right: 5px;
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  // My Order Tab

  // My Wallet Tab
  .mywallet-tabWrapper {
    .transection-linewrapper {
      .arows {
        margin-left: 20px;
      }
      .paid-arows {
        margin-left: 20px;
      }
    }
  }

  // My Wallet Tab

  // Payments Card
  .cards-wrapper {
    .Addnewcard-wrappeer {
      .stripe-btns {
        i {
          margin-left: 5px;
        }
      }
    }
  }

  // Payments Card

  // RMA tickect

  .rmatickets-wrapper {
    .rmaticketstops-wrapper {
      .serchticker-box-wrapper {
        input {
          margin-right: 5px;
        }
      }
    }
  }

  // RMA tickect

  // Deshbord pages

  // All  products Category

  .productscategory-wrapper {
    .product-category-itmes-wrapper {
      ul {
        li {
          label {
            .Ckeckboxscls {
              margin-right: 0px;
              margin-left: 5px;
            }
          }
        }
      }
    }
  }

  // All  products Category

  // Check Out Page
  .checkoutbg {
    .checkout-form-wrapper {
      .Yourorderwrap {
        width: 37%;
        p {
          font-size: 25px;
          font-weight: 500;
        }

        .subtotal-wrapp {
          .payments-wrappe {
            .paymenttags {
              input {
                margin-left: 10px;
              }
            }
          }
        }
      }
    }
  }

  // Check Out Page
}
