.orderplaced-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 50px 0px;

  .orderplaced {
    width: 80%;
    display: flex;
    background-color: #ebebeb;
    border-radius: 10px;
    img {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
    .thankyou-right {
      text-align: center;
      width: 46%;
      margin-top: 70px;
      margin-left: 50px;

      .myorder-button {
        background-color: #15242e;
        padding: 10px;
        margin-bottom: 20px;
        color: white;
        border-radius: 10px;
        font-weight: 500;
        margin-top: 30px;
        cursor: pointer;
      }
      .myshopping-button {
        background-color: #f4a34d;
        padding: 10px;
        margin-bottom: 20px;
        color: white;
        border-radius: 10px;
        font-weight: 500;
      }
    }
  }
}

@media (max-width: 1200px) {
  .orderplaced-wrapper {
    .orderplaced {
      width: 100%;
    }
  }
}

@media (max-width: 992px) {
  .orderplaced-wrapper {
    .orderplaced {
      width: 100%;
      img {
        width: 50%;
        height: 100%;
      }
      .thankyou-right {
        text-align: center;
        width: 43%;
        margin-top: 70px;
        margin-left: 25px;
      }
    }
  }
}

@media (max-width: 576px) {
  .orderplaced-wrapper {
    .orderplaced {
      width: 100%;
      flex-direction: column;
      img {
        width: 100%;
        height: 100%;
      }
      .thankyou-right {
        text-align: center;
        width: 100%;
        margin-left: 0px;
        padding: 30px 20px;
        margin-top: 0px;
      }
    }
  }
}
