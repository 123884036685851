.Newproduct-wrapper {
  display: flex;
  justify-content: space-between;
  margin: 50px 0;
  .trinding-product-wrapper {
    width: 25%;
  }
  .new-product-wrapper {
    width: 100%;
  }
}

.bestSells-wrappenewproduct {
  width: 100%;
}
.topline-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 15px;
  .BestSellerstag {
    font-size: 24px;
    color: #000;
    font-weight: 500;
    text-transform: uppercase;
  }
  .viewallbtn {
    background-color: #f4a34d;
    color: #fff;
    text-transform: uppercase;
    font-size: 13px;
    padding: 10px 26px;
    border-radius: 50px;
    border: none;
    outline: none;
    cursor: pointer;
  }
}

@media (max-width: 1023px) {
  .Newproduct-wrapper {
    flex-direction: column;
    .trinding-product-wrapper {
      width: 100%;
    }
    .new-product-wrapper {
      width: 100%;
    }
  }
}
