.ProductStatus-wrapper {
  margin-top: 20px;
  .product-status-txt {
    padding: 0px;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .product-stok-wrapper {
    margin-top: 200;
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      li {
        display: flex;
        font-size: 12px;
        justify-content: space-between;
        align-items: center;
        padding: 7px 0px;
        cursor: pointer;
        i {
          font-size: 8px;
        }
      }
    }
  }
}
