.Howwork-bg {
  background-color: #f7f7f7;
  padding-top: 50px;
  padding-bottom: 100px;
  .howits-work-line {
    text-align: center;
    font-size: 37px;
    font-weight: 800;
    margin-bottom: 10px;
  }
  .heres-step-line {
    text-align: center;
    font-size: 16px;
    color: #f4a34d;
    font-weight: 600;
    margin-bottom: 30px;
  }

  .stepsWrapper {
    display: flex;
    justify-content: space-between;

    .step-wrapper {
      width: 49%;
      display: flex;
      justify-content: space-between;
      .steps-wrapp {
        width: 48%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 30px 15px;
        background-color: #fff;
        border: 1px solid #e1e0e0;
        span {
          text-align: center;
          font-size: 15px;
          margin-top: 20px;
          font-weight: 500;
          width: 80%;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .Howwork-bg {
    padding-bottom: 100px;
  }
}
@media (max-width: 992px) {
  .Howwork-bg {
    .stepsWrapper {
      flex-direction: column;
      .step-wrapper {
        width: 100%;
        margin-bottom: 20px;
        .steps-wrapp {
          flex-direction: column;
        }
      }
    }
  }
}

@media (max-width: 568px) {
  .Howwork-bg {
    padding-top: 40px;
    padding-bottom: 80px;
    .stepsWrapper {
      flex-direction: column;

      .step-wrapper {
        flex-direction: column;
        margin-bottom: 0px;
        .steps-wrapp {
          flex-direction: column;
          width: 100%;
          margin-bottom: 20px;
        }
      }
    }
  }
}
