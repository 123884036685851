.react-responsive-modal-modal {
  padding: 0;
  .react-responsive-modal-closeButton {
    color: #fff !important;
  }
}

.Reset-formwrapper {
  width: 100%;
  .forgot-title {
    position: relative;
    .title-text {
      margin: 0;
      padding: 1rem;
      background: #15242e;
      color: #fff;
      font-size: 1.5rem;
    }
    .fa-times {
      position: absolute;
      top: 18px;
      right: 22px;
      font-size: 22px;
      z-index: 200;
      color: white;
      cursor: pointer;
    }
  }
  .forgot-formWrapper {
    display: flex;
    align-items: center;
    padding: 0px 20px;
    .forgot-form {
      padding: 30px 0px;
      display: flex;
      align-items: center;
      .countyr-code-wrapp {
        height: 45px;
        border: 1px solid #dddddd;
        border-radius: 3px;
        font-size: 1rem;
        padding: 0px 10px;
        background-color: #f6f6f6;
        margin-right: 15px;
        outline: none;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
    }
    .Submit-btn {
      border: none;
      padding: 10px 20px;
      border-radius: 0.3rem;
      background-color: #f4a34d;
      color: #fff;
      font-size: 17px;
      text-transform: uppercase;
      font-weight: 500;
    }
  }
}

@media (max-width: 576px) {
  .Reset-formwrapper {
    .forgot-formWrapper {
      flex-direction: column;
      padding: 20px;
      .forgot-form {
        display: flex;
        padding: 0px;
        .countyr-code-wrapp {
          margin-right: 0px;
        }
      }
    }

    button {
      margin-top: 10px;
    }
  }
}
