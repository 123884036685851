.loading-wrapper {
  /* width: 100%; */
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-wrapper img {
  width: 350px;
  overflow: hidden;
}
