.testimonial-bg {
  background-color: #f7f7f7;
  padding: 40px 0;
  .category-tag {
    font-size: 24px;
    font-weight: 500;
    text-transform: uppercase;
  }
  .slick-dots {
    bottom: 0 !important;
    li {
      width: 10px;
    }
  }
  .slick-arrow {
    display: none !important;
  }
  .Testimonial-review-wrap {
    background-color: #fff;
    padding: 20px;
    padding-bottom: 40px;
    border: 1px solid #f8f8f8;
    height: 300px;
    .testi-heading {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 5px;
    }
    .TheBest-Marketplace {
      font-size: 12px;
      font-style: italic;
      font-weight: bold;
      margin-bottom: 05px;
    }
    .TheBest-para {
      font-size: 14px;
      color: #c2bebe;
      display: -webkit-box;
      -webkit-line-clamp: 6;
      overflow: hidden;
      -webkit-box-orient: vertical;
    }
    .hr-wrapper {
      display: flex;
      align-items: center;
      .hr-content-wrap {
        margin-left: 30px;
        .hr-name {
          font-size: 16px;
          font-weight: 500;
          color: #e11515;
        }
        .post-name {
          color: #868b8d;
          font-size: 15px;
        }
      }
    }
  }

  .testimonial-items-wrapper {
    .category-wrapper {
      display: flex;
      flex-wrap: wrap;
      .categorytab-wrapp {
        width: 20%;
      }
      .viewmore-bg {
        background-color: #f6f6f6;
      }
    }
    a {
      text-decoration: none;
      color: #000;
    }
    .test-items-wrap {
      background-color: #fff;
      display: flex;
      padding: 15px;
      height: 150px;
      align-items: center;
      border: 1px solid#ededed;
      justify-content: center;
      .testCatigory-imgs {
        width: 46%;
        height: 80px;
        padding: 10px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .productnameswrapper {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        width: 50%;

        .products-Name {
          font-size: 12px;
          font-weight: 800;
          color: #565a67;
        }
        .items-quantety {
          color: #a39d9d;
          font-size: 11px;
          margin-top: 5px;
        }
        .viewmorebtn {
          color: #15242e;
          text-transform: capitalize;
          font-size: 13px;
          padding: 5px 16px;

          cursor: pointer;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .testimonial-bg {
    #testimonial-mob-view {
      order: 2;
      margin-top: 20px;
      // margin-bottom: 20px;
    }
    .testimonial-items-wrapper {
      .category-wrapper {
        .categorytab-wrapp {
          width: 25%;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .testimonial-bg {
    .testimonial-mob-view {
      margin-bottom: 20px;
    }
    .testimonial-items-wrapper {
      .category-wrapper {
        .categorytab-wrapp {
          width: 50%;
        }
      }
    }
  }
}

@media (max-width: 568px) {
  .testimonial-bg {
    // background-color: #f7f7f7;
    padding-top: 10px;
    padding-bottom: 30px;
    .testimonial-mob-view {
      order: 2;
    }

    .testimonial-items {
      order: 1;
    }

    .testimonial-items-wrapper {
      background-color: #fff;
      border: 2px solid#ededed;
      .test-items-wrap {
        display: flex;
        flex-direction: column;
        padding: 0px 0px;
        justify-content: center;
        height: 170px;
        align-items: center;
        border: 1px solid#ededed;
        img {
          width: 70px !important;
          margin-bottom: 10px;
        }
        .products-Name {
          font-size: 12px;
          font-weight: 500;
          color: #565a67;
          text-align: center;
        }
        .items-quantety {
          text-align: center;
        }
      }
    }
  }
}
