.welcome-cartbg-wrappp {
  background-color: #f5f5f5;
  padding: 20px 0 30px 0;
  .welcome-screenWrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 200px;
    .no-productwrapp {
      text-align: center;
      text-transform: capitalize;
      margin-bottom: 5px;
      color: #2b283a;
      font-size: 25px;
      font-weight: 900;
    }
    .no-producttext-wrapp {
      font-size: 14px;
      margin-bottom: 0;
      line-height: 25px;
      color: #4d4c52;
    }
    .shopp-btn-incart {
      padding: 14px 27px;
      outline: none;
      border: none;
      text-transform: uppercase;
      font-size: 16px;
      font-weight: 500;
      background-color: #15242e;
      color: #fff;
      cursor: pointer;
      border-radius: 6px;
    }
  }
}

@media (max-width: 576px) {
  .welcome-cartbg-wrappp {
    padding: 0px 0 50px 0;
    .welcome-screenWrapper {
      height: 250px;
      .no-productwrapp {
        font-size: 16px;
      }
      .no-producttext-wrapp {
        font-size: 12px;
        line-height: 20px;
      }
      .shopp-btn-incart {
        padding: 8px 16px;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
}

.welcome-cartbg-wrappp2 {
  background-color: #f5f5f5;
  padding: 20px 0 30px 0;
  .welcome-screenWrapper2 {
    display: flex;
    // justify-content: center;
    margin-top: 30px;
    flex-direction: column;
    width: 100%;
    height: 300px;
    .no-productwrapp2 {
      text-transform: capitalize;
      margin-bottom: 5px;
      color: #2b283a;
      font-size: 25px;
      font-weight: 900;
    }
    .no-producttext-wrapp2 {
      font-size: 14px;
      margin-bottom: 0;
      line-height: 25px;
      color: #4d4c52;
    }
    .shopp-btn-incart2 {
      padding: 14px 27px;
      outline: none;
      border: none;
      text-transform: uppercase;
      font-size: 16px;
      font-weight: 500;
      background-color: #f19c43;
      color: #fff;
      cursor: pointer;
      border-radius: 6px;
    }
  }
}

@media (max-width: 576px) {
  .welcome-cartbg-wrappp2 {
    padding: 0px 0 50px 0;
    .welcome-screenWrapper2 {
      height: 250px;
      .no-productwrapp2 {
        font-size: 16px;
      }
      .no-producttext-wrapp2 {
        font-size: 12px;
        line-height: 20px;
      }
      .shopp-btn-incart2 {
        padding: 8px 16px;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
}
