.store-items-wrappper {
  margin-top: 50px;
  .Store-items {
    display: flex;
    // border: 1px solid #f6f6f6;
    border-right: none;
    flex-wrap: wrap;
    // justify-content: space-between;
    .product-wrapper {
      border: 1px solid #f6f6f6;
      // border-bottom: 1px solid #f6f6f6;
      position: relative;
      height: 370px;
      width: 23%;
      margin: 20px 8px 0px 8px;
      .hover-wish-list-wrappper {
        position: absolute;
        cursor: pointer;
        right: 0;

        width: 25%;
        height: 50%;
        padding: 10px;
        transition: 2s;
        display: none;
        .wish-list-imgs {
          float: right;
          img {
            margin-bottom: 10px;
          }
        }
      }

      .product-imgs-wrap {
        // padding: 30px;
        align-items: center;
        height: 200px;
        width: 100%;
        cursor: pointer;
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
      .items-content-wrappers {
        padding: 15px;
        height: 200px;
        .items-name {
          font-size: 14px;
          font-weight: 500;
          margin-bottom: 10px;
          height: 40px;
          display: -webkit-box;
          overflow: hidden;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .instock {
          font-size: 11px;
          text-transform: uppercase;
          font-weight: bold;
          color: #f4a34d;
          margin-bottom: auto;
        }
        .last-price {
          font-size: 15px;
          color: #c3c8c9;
          margin-right: 10px;
        }
        .latest-price {
          font-size: 16px;
          font-weight: bold;
          color: #ed1c24;
        }
        .addto-cart-btn {
          background-color: transparent;
          border: 1px solid #f4a34d;
          border-radius: 20px;
          padding: 5px 20px;
          outline: none;
          width: 100%;
          color: #f4a34d;
          font-weight: 500;
          margin-top: 15px;
        }
        .addto-cart-btn .loader-spinner .loading-gif {
          animation-name: spin;
          animation-duration: 2000ms;
          animation-iteration-count: infinite;
          animation-timing-function: linear;
        }
        .addto-cart-btn-already {
          background-color: transparent;
          border: 1px solid #f4a34d;
          border-radius: 20px;
          padding: 5px 20px;
          outline: none;
          width: 100%;
          color: #f4a34d;
          font-weight: 500;
          margin-top: 20px;
        }
        .addto-cart-btn:hover {
          background-color: #f4a34d;
          color: #fff;
        }
      }
    }
    .product-wrapper:hover {
      .hover-wish-list-wrappper {
        display: block;
      }
    }
  }
}
.paginationContainer1 {
  display: flex;
  justify-content: center;
  align-items: center;
  .paginationContainer2 {
    display: flex;
    justify-content: center;
  }
  .spanarrow {
    font: size 22px;
    cursor: pointer;
    background-color: #15242e;
    border-radius: 5px;
    padding: 0 12px;
    display: flex;
    color: white;
    align-items: center;
  }
  .pageLabel {
    font-size: 20px;
    color: #15242e;
    border-radius: 5px;
    padding: 0 12px;
    display: flex;
    border: 1px solid #15242e;
    align-items: center;
    margin: 0 5px;
  }
  .pageLabelnew {
    font-size: 20px;
    color: white;
    border-radius: 5px;
    padding: 0 12px;
    display: flex;
    background-color: #15242e;
    border: 1px solid #15242e;
    align-items: center;
    margin: 0 5px;
  }
}

@media (max-width: 1200px) {
  .store-items-wrappper {
    margin-top: 50px;
    .Store-items {
      .product-wrapper {
        width: 31%;

        .product-imgs-wrap {
          text-align: center;
        }
      }
    }
  }
}

@media (min-width: 1024px) {
  .store-items-wrappper {
    margin-top: 50px;
    .Store-items {
      .product-wrapper {
        width: 23%;

        .product-imgs-wrap {
          text-align: center;
        }
      }
    }
  }
}

@media (max-width: 1023px) {
  .store-items-wrappper {
    margin-top: 50px;
    .Store-items {
      .product-wrapper {
        width: 23%;

        .product-imgs-wrap {
          text-align: center;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .store-items-wrappper {
    margin-top: 50px;
    .Store-items {
      .product-wrapper {
        width: 31%;

        .product-imgs-wrap {
          text-align: center;
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .store-items-wrappper {
    margin-top: 50px;
    .Store-items {
      flex-direction: column;
      .product-wrapper {
        width: 100%;

        .product-imgs-wrap {
          text-align: center;
        }
      }
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
