.mywallet-tabWrapper {
  background-color: #fff;
  border: 1px solid #e6e6e6;
  .mywalletheadeer-wrapper {
    display: flex;
    justify-content: space-between;
    background-color: #fffbf6;
    padding: 25px;
    .Walletamouuntwrapp {
      display: flex;
      flex-direction: column;
      span {
        color: #f4a34d;
        font-weight: 500;
        font-size: 17px;
        b {
          color: #000;
        }
      }
    }
    .addMoneybtn {
      background-color: #f4a34d;
      font-size: 14px;
      text-transform: uppercase;
      height: 40px;
      color: #fff;
      border: none;
      border-radius: 5px;
      padding: 8px 16px;
      font-weight: 500;
    }
  }
  .transection-linewrapper {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f6f6f6;
    padding: 20px 27px;
    .arows {
      transform: rotate(50deg);
      font-size: 31px;
      color: #15242e;
      margin-right: 20px;
    }
    .paid-arows {
      transform: rotate(229deg);
      font-size: 31px;
      color: #ed1c24;
      margin-right: 20px;
    }
    .shop-transectiondetils {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      .transection-shop-wrapper {
        display: flex;
        flex-direction: column;
        .paymentrecevid {
          font-size: 15px;
          color: #3b4a6a;
        }
        .shopname {
          font-size: 16px;
          font-weight: 800;
          color: #3b4a6a;
          text-transform: capitalize;
        }
      }
      .transection-amount {
        .craditamount {
          font-size: 16px;
          font-weight: 500;
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .mywallet-tabWrapper {
    background-color: #fff;
    padding: 20px 20px 0 20px;
    border: 1px solid #e6e6e6;
    .mywalletheadeer-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      span {
        color: #0baf0b;
        font-weight: 500;
      }
    }
    .transection-linewrapper {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #f6f6f6;
      padding: 20px 10px;
      .arows {
        transform: rotate(50deg);
        font-size: 21px;
        color: #0baf0b;
        margin-right: 20px;
      }
      .paid-arows {
        transform: rotate(229deg);
        font-size: 21px;
        color: #ed1c24;
        margin-right: 20px;
      }
      .shop-transectiondetils {
        .transection-shop-wrapper {
          .paymentrecevid {
            font-size: 12px;
            color: #3b4a6a;
          }
          .shopname {
            font-size: 13px;
          }
        }
        .transection-amount {
          .craditamount {
            font-size: 12px;
          }
        }
      }
    }
  }
}
