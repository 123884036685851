.todayDeals-bg-wrapp {
  padding: 30px 0;
  .todaydeal-wrapper {
    .todaydeal-heding {
      font-size: 24px;
      color: #3e445a;
      font-weight: 600;
      text-transform: uppercase;
    }

    .todays-dealsitems-wrapper {
      color: #000;

      .slick-slide {
        padding-left: 15px;
      }

      .slick-dots {
        display: none !important;
      }
      .slick-arrow {
        display: none !important;
      }
      .bestdealproduct-wrapp {
        border: 1px solid #f6f6f6;
        margin-top: 15px;
        background-color: #fff;
        position: relative;
        height: 400px;

        .product-img-wrapper {
          align-items: center;
          width: 100%;
          cursor: pointer;
          height: 200px;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
          .hover-wish-list-wrappper {
            position: absolute;
            cursor: pointer;
            width: 25%;
            height: 50%;
            padding: 10px;
            transition: 2s;
            right: 0;
            top: 0;
            display: none;
            .wish-list-imgs {
              float: right;
              img {
                margin-bottom: 10px;
              }
            }
          }
        }
        .product-description-wrapper {
          padding: 15px;
          cursor: pointer;
          .items-name {
            font-size: 14px;
            color: #000;
            font-weight: 500;
            height: 40px;
            margin-bottom: 10px;
            display: -webkit-box;
            overflow: hidden;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .instock {
            font-size: 11px;
            text-transform: uppercase;
            font-weight: bold;
            color: #f4a34d;
            margin-bottom: auto;
          }
          .last-price {
            font-size: 15px;
            color: #c3c8c9;
            margin-right: 10px;
          }
          .bidamount {
            font-size: 16px;
            font-weight: bold;
            color: #ed1c24;
          }
          .bid-now-btn {
            background-color: transparent;
            border: 1px solid #f4a34d;
            border-radius: 20px;
            padding: 5px 20px;
            outline: none;
            width: 100%;
            color: #f4a34d;
            font-weight: 500;
            margin-top: 20px;
          }

          .bid-now-btn .loader-spinner .loading-gif {
            animation-name: spin;
            animation-duration: 2000ms;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
          }
          .bid-now-btn:hover {
            background-color: #f4a34d;
            color: #fff;
          }
        }
      }
      .bestdealproduct-wrapp:hover {
        .hover-wish-list-wrappper {
          display: block;
        }
      }
    }
  }
}
