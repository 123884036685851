.Affiliates-wrapperWrapper {
  padding-top: 100px;
  padding-bottom: 100px;
  .Affiliates-heading {
    font-size: 55px;
    font-weight: 600;
  }
  .Affiliates-paraa {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 30px;
  }
  .vendor-registration {
    background-color: #f4a34d;
    color: #fff;
    padding: 8px 30px;
  }
  .Smileszy-Partner {
    padding-top: 100px;
    position: relative;
    h4 {
      font-weight: 600;
    }
    .Smileszy-PartnerItem {
      .smileszy-partnerImage {
        width: 35%;
        margin-top: 25px;
      }
      .smileszy-affiliateImage {
        width: 25%;
        margin-top: 25px;
      }
      .Smileszy-PartnerHeading {
        font-weight: 600;
        font-size: 17px;
        margin: 25px 0px;
      }
      .Smileszy-PartnerPara {
        font-size: 14px;
        color: #767676;
        font-weight: 300;
      }
    }
    .More-Portal {
      font-size: 40px;
      font-weight: 600;
      text-align: right;
      margin-top: 30px;
    }
    .More-PortalPara {
      font-size: 18px;
      font-weight: 400;
      padding-left: 70px;
      margin: 30px 0px;
    }

    .Smileszy-PartnerLogin {
      width: 300px;
      position: absolute;
      top: 80px;
    }
    .Smileszy-PartnerDashboard {
      width: 400px;
      position: absolute;
      bottom: 0;
      left: 200px;
    }
  }
}

@media (max-width: 768px) {
  .Affiliates-wrapperWrapper {
    .Affiliates-image {
      margin-top: 30px;
    }
  }
}
@media (max-width: 576px) {
  .Affiliates-wrapperWrapper {
    .Affiliates-image {
      margin-top: 30px;
    }
    .Affiliates-heading {
      font-size: 35px;
    }
  }
}
