.Sidebar-wrapper {
  display: none;
}

@media (max-width: 1200px) {
  .Sidebar-wrapper {
    width: 350px;
    height: 100%;
    padding: 20px;
    background-color: #15242e;
    position: fixed;
    z-index: +111;
    top: 0;
    left: 0px;
    display: unset;
    transition: 0.5s;
    display: unset;
    overflow-y: scroll;

    .Sidelogo-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        width: 120px;
      }
      .cut-icon {
        font-size: 21px;
        color: #000;
        cursor: pointer;
      }
    }
    .Sidebar-wrapper.active {
      left: 0;
      transition: 350m;
    }

    .sidebar-itemswrapper {
      margin-top: 20px;

      ul {
        list-style: none;
        padding: 0;
        a {
          text-decoration: none;
          color: #fff;

          li {
            padding: 10px 16px;
            margin: 0px 0;
            // border-right: 5px solid #fff;
            height: 50px;
          }
          .serchbarweapps {
            display: none;
          }
        }
        a:hover {
          li {
            cursor: pointer;
            color: #fff;
            border-right: 3px solid #1abf5d;
          }
        }
      }
      .deliverylocatline {
        padding: 10px 16px;
        cursor: pointer;
        margin: 0px 0;
        // border-right: 5px solid #fff;
        height: 50px;
      }
    }
  }
}
@media (max-width: 768px) {
  .Sidebar-wrapper {
    display: unset;
    overflow-y: scroll;

    .sidebar-itemswrapper {
      ul {
        a {
          .serchbarweapps {
            display: unset;
            .serch-bartopside {
              width: 100%;
              position: relative;
              .css-1s2u09g-control {
                border: 1px solid #eceff0;
                border-radius: 23px;
              }
              .css-1pahdxg-control {
                border: 1px solid #eceff0;
                box-shadow: none;
                border-radius: 23px;
              }
              .serch-input {
                position: relative;
                width: 100%;
                padding: 10px 10px 10px 20px;
                border-radius: 30px;
                outline: none;
                border: 1px solid #eceff0;
                background-color: #f9f9f9;
              }

              .serch-btn-top-bg {
                position: absolute;
                right: 4px;
                top: 4px;
                background-color: #15242e;
                font-size: 19px;
                width: 30px;
                height: 30px;
                color: #fff;
                border: none;
                outline: none;
                border-radius: 25px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .Sidebar-wrapper {
    width: 100% !important;
    // transition: 0.5s;

    display: unset;
    overflow-y: scroll;

    .sidebar-itemswrapper {
      ul {
        a {
          li {
            font-size: 14px;
            .serch-bartopside {
              width: 100%;
              position: relative;
              .css-1s2u09g-control {
                border: 1px solid #eceff0;
                border-radius: 23px;
              }
              .css-1pahdxg-control {
                border: 1px solid #eceff0;
                box-shadow: none;
                border-radius: 23px;
                // height: 20px;
              }
              .serch-input {
                position: relative;
                width: 100%;
                padding: 10px 10px 10px 20px;
                border-radius: 30px;
                outline: none;
                border: 1px solid #eceff0;
                background-color: #f9f9f9;
              }

              .serch-btn-top-bg {
                position: absolute;
                right: 5px;
                top: 3px;
                background-color: #15242e;
                font-size: 19px;
                width: 37px;
                height: 37px;
                color: #fff;
                border: none;
                outline: none;
                border-radius: 25px;
              }
            }
          }
        }
      }
    }
  }
}

.slide-in-left {
  -webkit-animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
