.Deshbord-page-wrapper {
  height: auto;
  .welcome-wrapper {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .walcome-status-wrapper {
      .welcome-backline {
        font-size: 16px;
        text-transform: uppercase;
        color: #38465a;
        margin-bottom: 0;
      }

      .my-recent-order-line {
        font-size: 16px;
        text-transform: uppercase;
        color: #38465a;
        font-weight: 600;
        margin-bottom: 0;
      }
    }
    .logoutbtn {
      background: none;
      border: none;
      text-transform: uppercase;
      font-size: 16px;
      font-weight: 600;
      color: #f4a34d;
    }
  }
}
