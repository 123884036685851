.UserDeshbord-bg {
  background-color: #fafafa;
  padding: 60px 0px 150px 0px;

  .deshbordtabs-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .left-side-wrapp {
      width: 25%;
      height: 100%;

      .user-details-wrapper {
        width: 100%;
        display: flex;
        background-color: #fff;
        height: 80px;
        padding: 20px;
        align-items: center;
        border: 1px solid #e6e6e6;

        .userimg-wrapper {
          border-radius: 50px;
          width: 50px;
          height: 50px;
          // background-image: url("../../../images/User.png");
          background-position: center;
          background-size: cover;
          position: relative;
          i {
            position: absolute;
            font-size: 21px;
            color: #fff;
            background-color: transparent;
            display: none;
            padding: 15px;
            border-radius: 50px;
            width: 50px;
            height: 50px;
            cursor: pointer;
          }
        }
        .userimg-wrapper:hover {
          i {
            display: unset;
          }
        }
        .user-details-wrpapp {
          margin-left: 20px;
          .status-line {
            color: #585858;
            font-size: 13px;
            font-weight: 500;
            margin-bottom: 0;
          }
          .user-line {
            color: #585858;
            font-size: 12px;
            font-weight: 500;
            margin-bottom: 0;
          }
        }
      }
      .Deshbord-tab-wrapp {
        background-color: #fff;
        padding: 10px 10px 0 10px;
        margin-top: 20px;
        height: 100%;
        border: 1px solid #e6e6e6;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .dashboardTab {
          padding: 13px 36px;
          border-bottom: 1px solid #e6e6e6;
          margin-bottom: 0;
          color: #707070;
          font-size: 14px;
          text-transform: uppercase;
          .iconsboxsd {
            margin-right: 10px;
          }
          &:hover {
            color: #f4a34d;
            cursor: pointer;
          }
        }

        .dashboardTabActive {
          padding: 13px 36px;
          border-bottom: 1px solid #e6e6e6;
          margin-bottom: 0;
          color: #f4a34d;
          font-size: 14px;
          cursor: pointer;
          text-transform: uppercase;
          .iconsboxsd {
            margin-right: 10px;
          }
        }
      }
    }
    .right-side-wrapp {
      width: 72%;
      height: 100%;
    }
  }
}

@media (max-width: 992px) {
  .UserDeshbord-bg {
    background-color: #fafafa;
    padding: 60px 0px 150px 0px;

    .deshbordtabs-wrapper {
      flex-direction: column;
      .left-side-wrapp {
        width: 100%;
        height: 100%;

        .user-details-wrapper {
          width: 100%;
          display: flex;
          background-color: #fff;
          height: 80px;
          padding: 20px;
          align-items: center;
          border: 1px solid #e6e6e6;

          .userimg-wrapper {
            border-radius: 50px;
            width: 50px;
            height: 50px;
            background-image: url("../../../images/User.png");
            background-position: center;
            background-size: cover;
          }
          .user-details-wrpapp {
            margin-left: 20px;
            .status-line {
              color: #585858;
              font-size: 13px;
              font-weight: 500;
              margin-bottom: 0;
            }
            .user-line {
              color: #585858;
              font-size: 16px;
              font-weight: 500;
              margin-bottom: 0;
            }
          }
        }
        .Deshbord-tab-wrapp {
          background-color: #fff;
          padding: 10px 10px 0 10px;
          margin-top: 20px;
          height: 100%;
          border: 1px solid #e6e6e6;
          display: flex;
          flex-direction: column;
          justify-content: center;

          p {
            padding: 13px 36px;
            border-bottom: 1px solid #e6e6e6;
            margin-bottom: 0;
            color: #707070;
            font-size: 14px;
            text-transform: uppercase;
          }
          p:hover {
            color: #67c7f5;
            cursor: pointer;
          }
        }
      }
      .right-side-wrapp {
        width: 100%;
        margin-top: 30px;
      }
    }
  }
}
