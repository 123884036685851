.acordion {
  padding: 0px !important;
  overflow: initial;
  .AddMonyWrapper {
    width: 400px;
    background-color: #fff;
    .addresinputwrapp {
      padding: 20px 20px 0 20px;
      .addmonyheading {
        font-size: 17px;
        font-weight: 700;
      }
      .addamountinput {
        background-color: #f6f6f6;
        height: 50px;
        font-weight: 500px;
        border: none;
        border-radius: 5px;
        padding: 8px 16px;
        width: 100%;
        outline: none;
      }
      .selectoptionline {
        font-size: 11px;
        font-weight: 500;
      }
    }
  }
  .selectboxwrapper {
    margin-top: 10px;
    cursor: pointer;
    .dabitcardselectwrapp {
      border-top: 2px solid #f6f6f6;
      border-bottom: 2px solid #f6f6f6;
      padding: 20px;

      label {
        display: flex;
        align-items: center;
        cursor: pointer;
        input {
          margin-right: 5px;
        }
        span {
          font-size: 16px;
          font-weight: 400;
        }
      }
    }
    .giftwrpp {
      border-bottom: 2px solid #f6f6f6;
      padding: 20px;
      cursor: pointer;
      label {
        display: flex;
        align-items: center;
        cursor: pointer;
        input {
          margin-right: 5px;
        }
        span {
          font-size: 16x;
          font-weight: 400;
        }
      }
      .giftwrapper {
        background-color: #f6f6f6;
        height: 50px;
        font-weight: 500px;
        border: none;
        border-radius: 5px;
        padding: 8px 16px;
        width: 100%;
        margin-bottom: 10px;
      }
    }
    .accordion-item {
      border: none;
    }
    .sendbtnwrappp {
      padding: 20px;
      display: flex;
      justify-content: center;

      .sendbtn {
        background-color: #f99e42;
        color: #fff;
        padding: 10px 25px;
        border-radius: 5px;
        outline: none;
        font-weight: bold;
        border: none;
        width: 100%;
      }
    }
  }

  .react-responsive-modal-closeButton {
    background: #fff;
  }
}
