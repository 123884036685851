.sitemap-wrapperWrapper {
  padding: 0px 30px;
  .sitemap-wrapper {
    .CategoriesHeader {
      font-weight: 400;
      font-size: 18px;
      margin: 40px 0px;
    }
    .sitemap-wrapperItem {
      .data-title {
        font-weight: 600;
        margin-bottom: 20px;
        color: black;
        &:hover {
          text-decoration: underline;
        }
      }
      .sitemap-wrapperList {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        .sitemap-wrapperListItem {
          width: 33%;
          font-weight: 300;
          line-height: 50px;
          a {
            color: black;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}
