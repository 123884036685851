.pages-bg-img {
  // background-image: url("../../images/about-bg.png");
  background-repeat: no-repeat;
  background-size: cover;

  .sellpage-tag-wrapper {
    padding: 65px 0;

    .pages-uper-text {
      font-size: 33px;
      font-weight: bold;
      width: 42%;
      margin-top: 15px;
    }
    .pages-tag {
      background-color: #f4a34d;
      font-size: 16px;
      color: #fff;
      padding: 10px 40px;
      border-radius: 30px;
      border: none;
      outline: none;
      cursor: pointer;
      font-weight: 500;
      text-transform: uppercase;
    }
  }
}
@media (max-width: 1200px) {
  .pages-bg-img {
    .sellpage-tag-wrapper {
      padding: 55px 0;
      .pages-uper-text {
        width: 75%;
      }
    }
  }
}

@media (max-width: 1023px) {
  .pages-bg-img {
    .sellpage-tag-wrapper {
      padding: 55px 0;

      .pages-uper-text {
        width: 100%;
      }
    }
  }
}
@media (max-width: 768px) {
  .pages-bg-img {
    .sellpage-tag-wrapper {
      .pages-tag {
        font-size: 18px;
      }
      .pages-uper-text {
        width: 100%;
        font-size: 16px;
      }
    }
  }
}

@media (max-width: 568px) {
  .pages-bg-img {
    .sellpage-tag-wrapper {
      padding: 30px 0;
      .pages-tag {
        font-size: 16px;
      }
      .pages-uper-text {
        width: 100%;
        font-size: 14px;
      }
    }
  }
}
