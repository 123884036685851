.wishlist-wrapper {
  .carts-bg {
    background-color: #fff;
    border: 1px solid #e1e0e0;
    width: 100%;
    padding: 0;
    .product-topline {
      padding: 15px 0;
      width: 100%;
      display: flex;
      .producttag {
        width: 47%;
        text-align: center;
      }
      .price-tag {
        width: 18%;
        // text-align: center;
      }
    }

    .cartswrapped {
      border-top: 1px solid #e1e0e0;
      // border-bottom: 1px solid #e1e0e0;
      .carts-line {
        display: flex;
        padding: 20px 10px;
        align-items: center;
        border-bottom: 1px solid #e1e0e0;
        // justify-content: space-between;

        .products-name-wrapper {
          width: 50%;
          display: flex;
          align-items: center;
          i {
            width: 5%;
            text-align: center;
            cursor: pointer;
          }
          .imgs-bg {
            // background-color: #eeeeee;
            border-radius: 10px;
            // padding: 10px;
            width: 20%;
            margin-left: 15px;
            margin-right: 15px;
          }
          .product-name {
            font-size: 15px;
            color: #5b5b5b;
            margin-left: 20px;
            width: 50%;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }
        .product-price {
          width: 19%;
          color: #5b5b5b;
          font-size: 15px;
          display: flex;
          // justify-content: center;
          // text-align: center;
          .decrement-btn {
            border: 1px solid#f6f6f6;
            background-color: #fff;
            padding: 8px 10px;
            font-size: 11px;
          }
          .product-number {
            border: 1px solid#f6f6f6;
            background-color: #f8f8f8;
            font-size: 11px;
            width: 25px;
            height: 35px;
          }
          .increment-btn {
            border: 1px solid#f6f6f6;
            background-color: #fff;
            padding: 8px 10px;
            font-size: 11px;
          }

          .addto-cart-btn {
            background-color: #f4a34d;
            border: none;
            border-radius: 20px;
            padding: 5px 16px;
            outline: none;
            width: 100%;
            color: #ffffff;
            font-weight: 500;
            // margin-top: 20px;
          }
          .addto-cart-btn .loader-spinner .loading-gif {
            animation-name: spin;
            animation-duration: 2000ms;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
          }
          .addto-cart-btn:hover {
            background-color: #f4a34d;
            color: #fff;
          }
        }
      }
    }
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media (max-width: 1198px) {
  .wishlist-wrapper {
    .carts-bg {
      .cartswrapped {
        .carts-line {
          padding: 20px 5px;

          .product-price {
            width: 31%;
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .wishlist-wrapper {
    .carts-bg {
      .product-topline {
        .producttag {
        }
        .price-tag {
          width: 20%;
        }
      }

      .cartswrapped {
        .carts-line {
          .products-name-wrapper {
            width: 250px;
          }
          .product-price {
            width: 130px;
            justify-content: center;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .wishlist-wrapper {
    .carts-bg {
      overflow-x: scroll;
      padding: 0;
      .product-topline {
        width: 660px;
        .producttag {
          width: 252px;
        }
        .price-tag {
          width: 125px;
          text-align: center;
        }
      }

      .cartswrapped {
        border-top: 1px solid #e1e0e0;
        width: 660px;
        .carts-line {
          .products-name-wrapper {
            width: 250px;
          }
          .product-price {
            width: 125px;
            justify-content: center;
          }
        }
      }
    }
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
