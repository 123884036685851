.recent-orderwrapper {
  background-color: #fff;
  margin-bottom: 30px;
  .recent-order-toptab {
    padding: 10px;
    background-color: #efefef;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .order-place-wrapp {
      .order-place-line {
        color: #464646;
        font-size: 11px;
        margin-bottom: 0;
      }
      .order-date {
        margin-bottom: 0;
        font-weight: 600;
        font-size: 12px;
      }
    }
    .shipto-wrapper {
      .shipto-line {
        font-size: 10px;
        color: #464646;
        margin-bottom: 0;
        text-transform: uppercase;
      }
      .owner-order-name {
        color: #15242e;
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;
        margin-bottom: 0;
      }
      .short-addras {
        font-size: 12px;
        font-weight: 800;
        margin: 0;
      }
    }
    .total-wrapper {
      .total-tag {
        font-size: 12px;
        text-transform: uppercase;
        margin-bottom: 0;
      }
      .total-amounts {
        color: #15242e;
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 0;
      }
    }
    .order-details-wrapper {
      .order-number {
        font-size: 12px;
        color: #464646;
        margin-bottom: 0;
      }
      .vierorder-details-link {
        color: #303030;
        font-size: 12px;
        border: none;
        background: none;
        outline: none;
        font-weight: 900;
        cursor: pointer;
      }
    }
  }
  .order-items-wrapper {
    padding: 20px 20px 0 20px;
    background-color: #fff;
    .order-product-Sold-by {
      font-weight: normal;
      font-size: 14px;
      margin-bottom: 3px;
    }

    .deliverd-status {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 0;
    }
    .order-product-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 30px 0px 50px 0;
      width: 100%;
      border-bottom: 2px solid #efefef;
    }
    .order-productimg {
      width: 20%;
      height: 150px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .order-items-wrapper {
      width: 78%;
      .order-product-name {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 0;
      }
      .sold-bylin {
        font-size: 14px;
        margin-bottom: 0;
      }
      .return-elin {
        color: #505050;
        font-size: 12px;
        margin-bottom: 5px;
      }
      .price-tag {
        font-size: 14px;
        color: #ed1c24;
        font-weight: 900;
      }
    }
  }
}

@media (max-width: 767px) {
  .recent-orderwrapper {
    .recent-order-toptab {
      display: flex;
      align-items: flex-start;
      flex-direction: column;

      .order-place-wrapp {
        margin-bottom: 10px;
      }
      .shipto-wrapper {
        margin-bottom: 10px;
      }
      .total-wrapper {
        margin-bottom: 10px;
      }
    }
    .order-items-wrapper {
      .order-product-wrapper {
        display: flex;
        align-items: flex-start;
        .order-productimg {
          width: 100% !important;
          height: 220px !important;
        }
        .order-items-wrapper {
          width: 100% !important;
          padding-left: 0px !important;
        }
      }
    }
  }
}
