.shop-Sidebar-section {
  position: absolute;
  z-index: +11;
  top: 140px;
  padding: 20px;
  background-color: #fff;
  height: 100%;
  width: 100%;
  transition: 0.2s;
  overflow-y: scroll;
  left: 0;
  .filterslie-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 10px 25px;
  }
}

@media (mix-width: 1023px) {
  .shop-Sidebar-section {
    display: none;
  }
}
