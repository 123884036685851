.singupform-wrapper-modal {
  padding: 0 !important;

  .inner-container-signup {
    .title-text {
      margin: 0;
      padding: 1rem;
      background: #15242e;
      color: #fff;
      font-size: 1.5rem;
    }

    .form-wrapper {
      padding: 2rem;
      .form-input-wrapper {
        width: 100%;
        .form-element-group {
          display: flex;
          flex-direction: column;

          label {
            color: #bbbbbb;
            display: inline-block;
            color: #5e5e5e;
            font-size: 14px;
          }
          .form-input-wrapper {
            margin-bottom: 1.2rem;
          }

          input {
            border: 1px solid #dddddd;
            border-radius: 3px;
            font-size: 1rem;
            width: 100%;
            outline: none;
            background-color: #f6f6f6;
            padding: 12px;
          }
        }
      }

      .main-inputswrap {
        display: flex;
        .right-wrapeer,
        .left-wrapeer {
          width: 50%;
          .form {
            // display: flex;
            // justify-content: space-between;
            margin-bottom: 10px;
            position: relative;
            .password-input {
              position: relative;
              .fa-eye {
                position: absolute;
                top: 42px;
                right: 10px;
                font-size: 14px;
                color: #949494;
                cursor: pointer;
              }
              .fa-eye-slash {
                position: absolute;
                top: 42px;
                right: 10px;
                font-size: 14px;
                color: #8d8d8d;
                cursor: pointer;
              }
            }

            .form-element-group {
              width: 100%;
              display: flex;
              flex-direction: column;
              label {
                color: #bbbbbb;
                display: inline-block;
                color: #5e5e5e;
                font-size: 14px;
              }
              input {
                border: 1px solid #dddddd;
                border-radius: 3px;
                font-size: 1rem;
                width: 100%;
                outline: none;
                background-color: #f6f6f6;
                padding: 12px;
                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                  -webkit-appearance: none;
                  margin: 0;
                }
              }
              .phones {
                position: relative;

                input {
                  border: 1px solid #dddddd;
                  border-radius: 3px;
                  font-size: 1rem;
                  width: 100%;
                  padding-left: 60px;
                  outline: none;
                  background-color: #f6f6f6;
                }
              }
              .countyr-code-wrapp {
                position: absolute;
                top: 0;
                left: 0;
                background-color: transparent;
                height: 100%;
                width: 60px;
                font-size: 14px;
                border: none;
                outline: none;
              }
            }
            .input-res {
              padding-right: 1rem;
            }
          }
        }
      }
      .submit-btn {
        border: none;
        padding: 10px 20px;
        border-radius: 0.3rem;
        background-color: #f4a34d;
        color: #fff;
        font-size: 17px;
        text-transform: uppercase;
        font-weight: 500;
        width: 100%;
        margin-top: 10px;
      }
      .alreadyacountline {
        font-size: 13px;
        margin-top: 5px;
        span {
          color: #15242e;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
    .social-singinWraper {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      padding-bottom: 2rem;
      .singinlinewit {
        font-size: 10px;
        margin-bottom: 0;
      }
      .social-loginbtnwrapper {
        display: flex;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        .social-img {
          width: 20px;
          margin-right: 10px;
        }
      }
    }
  }
  .react-responsive-modal-closeButton {
    background: #fff;
    margin: 0px;
  }
}
.Toastify__toast-body {
  font-family: "Montserrat", sans-serif;
}

@media (max-width: 576px) {
  .singupform-wrapper-modal {
    .inner-container-signup {
      .form-wrapper {
        .main-inputswrap {
          flex-direction: column;
          .right-wrapeer,
          .left-wrapeer {
            width: 100%;
            .form {
              flex-direction: column;
              .form-element-group {
                width: 100%;
                margin-bottom: 10px;
              }
              .input-res {
                padding-right: 0rem;
              }
            }
          }
        }
      }
    }
  }
}
