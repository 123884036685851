.cart-bg {
  background-color: #f8f8f8;
  padding-top: 50px;
  padding-bottom: 125px;
  .cartwrapper {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .carts-bg {
      background-color: #fff;
      border: 1px solid #e1e0e0;
      width: 72%;
      padding: 0;
      .product-topline {
        padding: 15px 0;
        width: 100%;
        display: flex;
        .producttag {
          width: 50%;
          text-align: center;
        }
        .price-tag {
          width: 18%;
          text-align: center;
        }
      }

      .cartswrapped {
        border-top: 1px solid #e1e0e0;
        // border-bottom: 1px solid #e1e0e0;
        .carts-line {
          display: flex;
          padding: 20px 0;
          align-items: center;
          border-bottom: 1px solid #e1e0e0;

          .products-name-wrapper {
            width: 50%;
            display: flex;
            align-items: center;
            i {
              width: 10%;
              text-align: center;
              cursor: pointer !important;
            }
            .imgs-bg {
              // background-color: #eeeeee;
              border-radius: 10px;
              // padding: 10px;
              width: 20%;
              margin-left: 15px;
              margin-right: 15px;
            }
            .product-name {
              font-size: 15px;
              color: #5b5b5b;
              margin-left: 20px;
              width: 35%;
              display: -webkit-box;
              overflow: hidden;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
          }
          .product-price {
            width: 18%;
            color: #5b5b5b;
            font-size: 15px;
            display: flex;
            justify-content: center;

            text-align: center;
            .decrement-btn {
              border: 1px solid #f6f6f6;
              background-color: #fff;
              padding: 8px 10px;
              font-size: 11px;
            }
            .product-number {
              border: 1px solid #f6f6f6;
              background-color: #f8f8f8;

              font-size: 11px;
              width: 25px;
              height: 35px;
            }
            .increment-btn {
              border: 1px solid #f6f6f6;
              background-color: #fff;
              padding: 8px 10px;
              font-size: 11px;
            }
          }
        }
      }
      .carts-btn-wrapper {
        display: flex;
        justify-content: space-between;
        padding: 20px 20px;
        .apply-wrap {
          display: flex;
          align-items: center;

          input {
            width: 200px;
            height: 45px;
            background-color: #f5f5f5;
            outline: none;
            padding: 8px 16px;
            border: 1px solid #c2c2c2;
            &::placeholder {
              color: #9b9b9b;
              font-size: 15px;
            }
          }
          button {
            height: 45px;
            width: 130px;
            background-color: #ed1c24;
            font-size: 11px;
            border: none;
            outline: none;
            padding: 8px 16px;
            color: #fff;
            cursor: pointer;
          }
          button .loader-spinner .loading-gif {
            animation-name: spin;
            animation-duration: 2000ms;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
          }
        }
        .disableUpdBtn {
          background-color: #6666;
          border-radius: 30px;
          padding: 10px 16px;
          outline: none;
          border: none;
          text-transform: uppercase;
          font-size: 16px;
          font-weight: 500;
          color: #fff;
        }
        .update-cart-btn {
          border-radius: 30px;
          padding: 10px 16px;
          width: 170px;
          outline: none;
          border: none;
          text-transform: uppercase;
          font-size: 16px;
          font-weight: 500;
          background-color: #15242e;
          color: #fff;
        }
        .update-cart-btn .loader-spinner .loading-gif {
          animation-name: spin;
          animation-duration: 2000ms;
          animation-iteration-count: infinite;
          animation-timing-function: linear;
        }
      }
    }

    .cart-details {
      background-color: #fff;
      border: 1px solid #e1e0e0;
      padding: 20px 30px;
      width: 25%;
      .cart-total {
        font-size: 18px;
        font-weight: 900;
      }
      .subtotals {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 0;
        padding: 15px 0;
        border-top: 1px solid #e1e0e0;
        border-bottom: 1px solid #e1e0e0;
      }
      .shipping-wrapp {
        display: flex;
        width: 100%;
        align-items: center;
        padding: 20px 0;
        border-bottom: 1px solid #e1e0e0;
        .shipping-tag {
          font-size: 15px;
          font-weight: 900;
        }
        .shiping-details {
          text-align: end;

          .flate-rate {
            font-size: 12px;
            color: #878787;
          }
          .shipping-carts {
            font-size: 12px;
            color: #878787;
            margin-bottom: 10px;
          }
          .calculater-shping {
            color: #15242e;
            font-size: 12px;
            font-weight: 600;
          }
        }
      }
      .total-wrap {
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: 20px 0;
        .totalAmount {
          font-size: 18px;
          color: #f4a34d;
          font-weight: 900;
        }
      }
      .proceed-btn {
        border-radius: 30px;
        padding: 10px 16px;
        margin: auto;
        width: 100%;
        outline: none;
        border: none;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 500;
        background-color: #f4a34d;
        color: #fff;
        margin-top: 10px;
      }
      .proceed-btn .loader-spinner .loading-gif {
        animation-name: spin;
        animation-duration: 2000ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
      }
      @keyframes spin {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
    }
  }
}

@media (max-width: 1400px) {
  .cart-bg {
    .cartwrapper {
      .carts-bg {
        .cartswrapped {
          .carts-line {
            .products-name-wrapper {
              .product-name {
                width: 50%;
              }
            }
          }
        }
      }

      .cart-details {
        padding: 20px 25px;

        .proceed-btn {
          padding: 10px;

          font-size: 12px;
        }
        .update-cart-btn .loader-spinner .loading-gif {
          animation-name: spin;
          animation-duration: 2000ms;
          animation-iteration-count: infinite;
          animation-timing-function: linear;
        }
        @keyframes spin {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .cart-bg {
    .cartwrapper {
      flex-direction: column;
      .carts-bg {
        width: 100%;
        .cartswrapped {
          .carts-line {
            .products-name-wrapper {
              .product-name {
                width: 50%;
              }
            }
          }
        }
      }

      .cart-details {
        padding: 20px 25px;
        width: 100%;
        margin-top: 20px;
        .shipping-wrapp {
          justify-content: space-between;
        }
        .proceed-btn {
          padding: 20px;
          font-size: 14px;
        }
        .update-cart-btn .loader-spinner .loading-gif {
          animation-name: spin;
          animation-duration: 2000ms;
          animation-iteration-count: infinite;
          animation-timing-function: linear;
        }
        @keyframes spin {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .cart-bg {
    .cartwrapper {
      .carts-bg {
        width: 100%;
        .product-topline {
          justify-content: space-around;
          #price-mobiview {
            display: none;
          }
        }

        .cartswrapped {
          .carts-line {
            justify-content: space-between;

            .products-name-wrapper {
              width: 65%;

              .product-name {
                width: 60%;
              }
            }
            .product-price {
              width: 25%;
            }
            #productmobview {
              display: none;
            }
          }
        }
        .carts-btn-wrapper {
          flex-direction: column;
          padding: 20px;

          .apply-wrap {
            margin-bottom: 20px;
            input {
              width: 60%;
            }
            button {
              width: 40%;
              padding: 0;
              font-size: 12px;
            }
            button .loader-spinner .loading-gif {
              animation-name: spin;
              animation-duration: 2000ms;
              animation-iteration-count: infinite;
              animation-timing-function: linear;
            }
          }
          .update-cart-btn {
            margin-top: 20px;
          }
        }
      }
    }
  }
}

@media (max-width: 568px) {
  .cart-bg {
    background-color: #f8f8f8;
    padding-top: 50px;
    padding-bottom: 100px;
    .cartwrapper {
      .carts-bg {
        .cartswrapped {
          border-top: 1px solid #e1e0e0;
          .carts-line {
            .products-name-wrapper {
              width: 70%;
              .imgs-bg {
                width: 20%;
                margin-left: 5px;
                margin-right: 5px;
              }
              .product-name {
                width: 60%;
                margin-left: 10px;
                display: -webkit-box;
                overflow: hidden;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
              }
            }
            .product-price {
              width: 25%;
              color: #5b5b5b;
              font-size: 15px;

              text-align: center;
              .decrement-btn {
                padding: 3px 6px;
              }
              .product-number {
                padding: 3px 6px;
                height: 24px;
              }
              .increment-btn {
                padding: 3px 6px;
              }
            }
          }
        }
      }
    }
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
