.vendor-bg {
  background-color: #f3f3f3;
  padding: 90px 0 130px 0;
  .vendor-wrapper {
    background-color: #ffff;
    width: 100%;
    height: 100%;
    display: flex;
    .vendor-leftsideimgwrappr {
      width: 50%;
      background-color: #eefffa;
      display: flex;
      padding: 20px;
      align-items: center;
      justify-content: center;
    }
    .vendor-rightsideform-wrapper {
      width: 50%;
      padding: 24px 40px;
      height: 100%;
      .vendor-headingDiv {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0px;
        .vendorHeading {
          font-size: 21px;
          font-weight: 900;
          margin-bottom: 0;
          a {
            font-size: 16px;
            text-decoration: none;
            font-weight: 800;
            color: #15242e;
            text-transform: uppercase;
            &:hover {
              text-decoration: underline;
            }
          }
        }
        .vendorLogin-button {
          padding: 5px 30px;
          border: none;
          border-radius: 30px;
          font-size: 14px;
          background-color: #f4a34d;
          color: #fff;
        }
      }

      .vendorformwrapp {
        display: flex;
        flex-direction: column;

        .imputdiv-wrapper {
          display: flex;
          justify-content: space-between;
          margin-bottom: 15px;
          .firstname-input {
            background-color: #f3f3f3;
            border: 1px solid #f3f3f3;
            padding: 8px 16px;
            width: 49%;
            height: 50px;
            font-size: 13px;
            outline: none;
          }
        }
        .email-inputwrapp {
          background-color: #f3f3f3;
          border: 1px solid #f3f3f3;
          padding: 8px 16px;
          width: 100%;
          height: 50px;
          margin-bottom: 15px;
          font-size: 13px;
          outline: none;
        }
        .password-input {
          position: relative;
          .fa-eye {
            position: absolute;
            top: 19px;
            right: 13px;
            font-size: 14px;
            color: #949494;
            cursor: pointer;
          }
          .fa-eye-slash {
            position: absolute;
            top: 19px;
            right: 13px;
            font-size: 14px;
            color: #949494;
            cursor: pointer;
          }
        }
        .email-inputwrapp::-webkit-outer-spin-button,
        .email-inputwrapp::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
      .checkboxlines {
        font-size: 11px;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        font-size: 13px;
      }
      .Ragistr-btn {
        width: 40%;
        background-color: #15242e;
        color: #fff;
        padding: 10px;
        border-radius: 70px;
        outline: none;
        font-weight: 500;
        border: none;
      }
    }
  }
}

@media (max-width: 992px) {
  .vendor-bg {
    .vendor-wrapper {
      .vendor-rightsideform-wrapper {
        .Ragistr-btn {
          width: 60%;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .vendor-bg {
    padding: 40px 0 80px 0;
    .vendor-wrapper {
      flex-direction: column;
      .vendor-leftsideimgwrappr {
        width: 100%;
      }
      .vendor-rightsideform-wrapper {
        width: 100%;

        .vendorHeading {
          font-size: 18px;
        }
        .vendorformwrapp {
          .imputdiv-wrapper {
            margin-bottom: 10px;
            .firstname-input {
              width: 48%;
            }
          }
          .email-inputwrapp {
            margin-bottom: 10px;
          }
        }
        .checkboxlines {
          display: flex;
        }
      }
    }
  }
}
