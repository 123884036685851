.Collection-wrapper {
  padding: 20px 0;
  .slick-arrow {
    display: none !important;
  }
  .home-banner {
    position: relative;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.smallhomeoffers-card {
  display: none;
}

.homeoffers-card {
  display: flex;
  justify-content: space-between;
  .offers-cardwrap {
    width: 32%;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    .card-tag-line {
      font-size: 25px;
      font-weight: bold;
      line-height: 25px;
      margin-bottom: 10px;
    }
    .shopnow-card-link {
      color: #fff;
      cursor: pointer;
    }
    .off-tags {
      font-size: 15px;
      color: #d6f82b;
      margin-bottom: 8px;
    }
    .off-tags-red {
      font-size: 15px;
      color: #b74e28;
      margin-bottom: 8px;
    }
    .off-tags-white {
      font-size: 15px;
      color: #fff;
      margin-bottom: 8px;
    }
  }
}
.getfreshes-bg {
  margin: 20px 0;
  img {
    width: 100%;
  }
  .getfreshes-text-wrapp {
    height: 100%;
    width: 60%;
    float: right;
    .getallfood-text-wrap {
      padding: 20px 0;
      .allNatural-product {
        font-size: 14px;
        text-transform: uppercase;
        color: #405449;
        font-weight: 500;
      }
      .get-food {
        font-size: 41px;
        font-weight: 900;
        color: #405449;
        text-transform: uppercase;
        margin-bottom: 0;
        line-height: 35px;
      }
      .every-day {
        font-size: 41px;
        font-weight: 900;
        color: #84bc40;
        text-transform: uppercase;
        margin-bottom: 0;
      }
      .organic-food {
        color: #66746d;
        font-size: 14px;
        font-weight: 400;
        width: 60%;
      }
      .freeshshop-btn {
        background-color: #84bc40;
        padding: 15px 40px;
        outline: none;
        border: none;
        border-radius: 5px;
        font-size: 10px;
        font-weight: 500;
        margin-top: 13px;
        color: #fff;
        cursor: pointer !important;
        text-transform: uppercase;
      }
    }
  }
}

@media (max-width: 992px) {
  .Collection-wrapper {
    .home-banner {
      .shopnow-btn {
        bottom: 30px;
        left: 20px;
      }
    }
  }
}

@media (max-width: 568px) {
  .Collection-wrapper {
    .home-banner {
      .shopnow-btn {
        bottom: 6px;
        left: 10px;
        background-color: #15242e;
        color: #fff;
        font-weight: bold;
        width: 80px;
        height: 25px;
        outline: none;
        font-size: 8px;
        padding: 0;
        border: none;
      }
    }
  }
  .homeoffers-card {
    display: none;
  }
  .smallhomeoffers-card {
    display: unset;
    .slick-arrow {
      display: none !important;
    }
    .offers-cardwrap {
      width: 97% !important;
      border-radius: 5px;
      // padding: 25px;
      color: #fff;
      .card-tag-line {
        font-size: 25px;
        font-weight: bold;
        line-height: 25px;
        margin-bottom: 10px;
      }
      .shopnow-card-link {
        color: #fff;
        cursor: pointer;
      }
      .off-tags {
        font-size: 15px;
        color: #d6f82b;
        margin-bottom: 8px;
      }
      .off-tags-red {
        font-size: 15px;
        color: #b74e28;
        margin-bottom: 8px;
      }
      .off-tags-white {
        font-size: 15px;
        color: #fff;
        margin-bottom: 8px;
      }
    }
    .bg-card-first {
      background-image: url("../../images/card-1.png");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
    .bg-card-two {
      background-image: url("../../images/card-2.png");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
    .bg-card-three {
      background-image: url("../../images/card-3.png");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
}
