.RelatedProducts-wrapper {
  margin-top: 50px;
  margin-bottom: 50px;
  .products-line {
    margin-bottom: 10px;
    .related-products {
      font-size: 24px;
      color: #000;
      font-weight: 500;
    }
  }
  .relatedproducts-list {
    // border: 1px solid #e1e0e0;
    width: 100%;
    .products-listwrapper {
      display: flex;

      .product-wrapes {
        background-color: #fff;
        width: 19%;
        height: 370px;
        margin-right: 15px;
        .products-wrapper {
          border: 1px solid #f6f6f6;
          position: relative;
          height: 100%;
          cursor: pointer;
          .hover-wish-list-wrappper {
            position: absolute;
            cursor: pointer;
            right: 0;
            width: 25%;
            height: 50%;
            padding: 10px;
            transition: 2s;
            display: none;
            .wish-list-imgs {
              float: right;
              img {
                margin-bottom: 10px;
              }
            }
          }
        }
        .product-imgs-wrap {
          // padding: 20px;
          align-items: center;
          height: 200px;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .items-content-wrappers {
          padding: 15px;
          height: 200px;
          .items-name {
            font-size: 14px;
            font-weight: 500;
            height: 40px;
            margin-bottom: 10px;
            display: -webkit-box;
            overflow: hidden;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .instock {
            font-size: 11px;
            text-transform: uppercase;
            font-weight: bold;
            color: #f4a34d;
            margin-bottom: auto;
          }
          .last-price {
            font-size: 15px;
            color: #c3c8c9;
            margin-right: 10px;
          }
          .latest-price {
            font-size: 16px;
            font-weight: bold;
            color: #ed1c24;
          }
          .addto-cart-btn {
            background-color: transparent;
            border: 1px solid #f4a34d;
            border-radius: 20px;
            padding: 5px 20px;
            outline: none;
            width: 100%;
            color: #f4a34d;
            font-weight: 500;
            margin-top: 15px;
            .loader-spinner {
              .loading-gif {
                animation-name: spin;
                animation-duration: 2000ms;
                animation-iteration-count: infinite;
                animation-timing-function: linear;
              }
            }
          }
          .addto-cart-btn:hover {
            color: #fff;
            background-color: #f4a34d;
          }
        }
      }
      .products-wrapper:hover {
        .hover-wish-list-wrappper {
          display: block;
        }
      }
    }
  }
  .related-product-mobslider {
    background-color: #fff;
    border: 1px solid #e1e0e0;
    display: none;

    // display: flex;
    .product-wrapes {
      width: 50%;
      height: 350px;
      .products-wrapper {
        border-right: 1px solid #f6f6f6;
        border-bottom: 1px solid #f6f6f6;
        position: relative;
        height: 100%;
        .hover-wish-list-wrappper {
          position: absolute;
          cursor: pointer;
          width: 100%;
          height: 50%;
          padding: 10px;
          transition: 2s;
          display: none;
          .wish-list-imgs {
            float: right;
            height: 200px;
            img {
              margin-bottom: 10px;
            }
          }
        }
      }
      .product-imgs-wrap {
        // padding: 15px;
        height: 150px;
        align-items: center;
      }
      //Items Wrapper
      .items-content-wrappers {
        padding: 15px;
        .items-name {
          font-size: 13px;
          font-weight: 500;
          margin-bottom: 10px;
          height: 40px;
          display: -webkit-box;
          overflow: hidden;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
        .instock {
          font-size: 11px;
          text-transform: uppercase;
          font-weight: bold;
          color: #f4a34d;
          margin-bottom: auto;
        }
        .last-price {
          font-size: 15px;
          color: #c3c8c9;
          margin-right: 10px;
        }
        .latest-price {
          font-size: 16px;
          font-weight: bold;
          color: #ed1c24;
        }
        .addto-cart-btn {
          background-color: transparent;
          border: 1px solid #f4a34d;
          border-radius: 20px;
          padding: 5px 20px;
          outline: none;
          width: 100%;
          color: #f4a34d;
          font-weight: 500;
          margin-top: 20px;
          .loader-spinner {
            border-radius: 300px;
            .loading-gif {
              background-color: black;
              animation-name: spin;
              animation-duration: 2000ms;
              animation-iteration-count: infinite;
              animation-timing-function: linear;
            }
          }
        }

        .addto-cart-btn:hover {
          color: #fff;
          background-color: #f4a34d;
        }
      }
      //Items Wrapper
    }
    .products-wrapper:hover {
      .hover-wish-list-wrappper {
        display: block;
      }
    }
  }
}
@media (max-width: 1200px) {
  .RelatedProducts-wrapper {
    .relatedproducts-list {
      .products-listwrapper {
        .product-wrapes {
          width: 24%;
          height: 380px;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .RelatedProducts-wrapper {
    margin-top: 50px;
    margin-bottom: 50px;
    .products-line {
      margin-bottom: 10px;
      .related-products {
        font-size: 24px;
        color: #000;
        font-weight: 500;
      }
    }
    .relatedproducts-list {
      .products-listwrapper {
        .product-wrapes {
          width: 32%;
          height: 330px;

          .product-imgs-wrap {
            // padding: 10px;
            height: 150px;
          }

          .items-content-wrappers {
            //
            .addto-cart-btn {
              padding: 5px 0px;
            }

            //
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .RelatedProducts-wrapper {
    .relatedproducts-list {
      display: none;
      .products-listwrapper {
        display: flex;
      }
    }

    .related-product-mobslider {
      // display: flex;
      display: unset;
      .slick-arrow {
        display: none !important;
      }

      .product-wrapes {
        background-color: #fff !important;
        .product-imgs-wrap {
          // padding: 10px;
          height: 150px;
          img {
            width: 100%;
            height: 100%;
          }
        }

        .items-content-wrappers {
          padding: 15px;
          height: 150px;

          .last-price {
            font-size: 15px;
            color: #c3c8c9;
            margin-right: 10px;
          }
          .latest-price {
            font-size: 16px;
            font-weight: bold;
            color: #ed1c24;
          }
          .addto-cart-btn {
            padding: 10px;
            font-size: 11px;
          }
          .addto-cart-btn:hover {
            color: #fff;
            background-color: #f4a34d;
          }
        }
      }
    }
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
