.SideOfferBanner-wrapper {
  border: 2px solid #15242e;
  padding: 20px;
  border-radius: 15px;

  .sideofferinner-wrappepr {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    .spaical-line-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .spaical-linewrapp {
        font-weight: 500;
        font-size: 18px;
      }
      .save-amountWrapper {
        background-color: #15242e;
        color: #fff;
        padding: 14px;
        width: 70px;
        height: 70px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        border-radius: 50%;
        .save-linewrapp {
          font-size: 12px;
        }
        .save-amountwrap {
          font-size: 14;
          font-weight: 600;
        }
      }
    }
    .productimgswrapper {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-bottom: 10px;
      .productlineheadingwrapper {
        font-size: 12px;
        color: #15242e;
        width: 80%;
        font-weight: 500;
        -webkit-line-clamp: 1;
        display: -webkit-box;
        overflow: hidden;
        -webkit-box-orient: vertical;
        cursor: pointer;
        text-align: center;
      }
      .product-img-wrapp {
        height: 200px;
        cursor: pointer;
      }
    }
    .price-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      .Cureentpricewrapper {
        font-size: 18px;
        color: #15242e;
        font-weight: 800;
      }
      .lastpricewrapper {
        font-size: 15px;
        color: #686464;
        margin-left: 10px;
        font-weight: 800;
      }
    }
    .timeline-wrapper {
      display: flex;
      flex-direction: column;
      margin: 10px 0;
      align-items: center;
      .hurryupline-wrapp {
        text-align: center;
        font-size: 18px;
      }
      .Times-lineWrapper {
        font-size: 12px;
        padding: 10px;
        .dotlabel-wrapper {
          font-size: 12px;
        }
        .timesset-wrapper {
          display: flex;
          align-items: center;
          .Houre-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
          .hours-btn {
            padding: 8px;
            border-radius: 8px;
            text-align: center;
            font-size: 18px;
            width: 50px;
            height: 40px;
            margin-bottom: 5px;
            margin-left: 8px;
            background-color: #f6f6f6;
          }
          .houre-line {
            font-size: 11px;
            text-align: center;
            font-weight: bold;
            text-transform: uppercase;
          }
        }
      }
      .time-img {
        width: 200px;
        height: auto;
      }
    }
  }
}
