.trending-smilewrapper {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  .Trendingline {
    font-size: 24px;
    color: #3e445a;
    font-weight: 600;
    text-transform: uppercase;
  }
  .ternding-items-wrapepr {
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    flex-wrap: wrap;
    .trending-items {
      width: 17%;
      cursor: pointer;
      .trendingproduct-wrapp {
        text-align: center;
        .trendingProduct-img {
          border-radius: 60%;
          width: 200px;
          height: 200px;
          object-fit: cover;
          margin-bottom: 15px;
          border: 4px solid #c7c7c7;
        }
        .trendingProductline {
          font-size: 16px;
          color: #3e445a;
          font-weight: 500;
          width: 95%;
          margin: auto;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .trending-smilewrapper {
    .ternding-items-wrapepr {
      .trending-items {
        .trendingproduct-wrapp {
          .trendingProduct-img {
            width: 150px;
            height: 150px;
          }
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .trending-smilewrapper {
    .ternding-items-wrapepr {
      justify-content: flex-start;
      .trending-items {
        width: 31%;
        margin-left: 15px;
        .trendingproduct-wrapp {
          padding: 20px 0;
          .trendingProduct-img {
            width: 150px;
            height: 150px;
          }
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .trending-smilewrapper {
    .ternding-items-wrapepr {
      .trending-items {
        width: 50%;
        margin-left: 0;
        .trendingproduct-wrapp {
          padding: 20px 0;
          .trendingProduct-img {
            width: 150px;
            height: 150px;
          }
          .trendingProductline {
            width: 85%;
          }
        }
      }
    }
  }
}
@media (max-width: 567px) {
  .trending-smilewrapper {
    .ternding-items-wrapepr {
      .trending-items {
        width: 100%;
        .trendingproduct-wrapp {
          padding: 15px 0;
          .trendingProductline {
            width: 60%;
          }
        }
      }
    }
  }
}
