.login-popup-modal {
  padding: 0 !important;
  max-width: 100% !important;
  .inner-container-login {
    width: 550px;
    .title-text {
      margin: 0;
      padding: 1rem;
      background: #15242e;
      color: #fff;
      font-size: 1.5rem;
    }

    .content {
      display: flex;
      padding: 2rem 2rem 2rem 2rem;

      .right,
      .left {
        width: 50%;
      }
      .changePassword-wrapper {
        width: 100%;
      }

      .left,
      .changePassword-wrapper {
        padding-right: 1rem;
        margin-bottom: 10px;
        label {
          color: #5e5e5e;
          font-size: 16px;
          display: inline-block;
        }

        .form {
          .password-input {
            position: relative;
            .fa-eye {
              position: absolute;
              top: 42px;
              right: 10px;
              font-size: 14px;
              color: #949494;
              cursor: pointer;
            }
            .fa-eye-slash {
              position: absolute;
              top: 42px;
              right: 10px;
              font-size: 14px;
              color: #8d8d8d;
              cursor: pointer;
            }
          }
          .form-input {
            margin-bottom: 1.2rem;
          }
          input {
            border: 1px solid #dddddd;
            border-radius: 3px;
            font-size: 1rem;
            width: 100%;
            margin-bottom: 10px;
            padding: 12px;
            background-color: #f6f6f6;
            margin-bottom: 20px;
            outline: none;
          }

          .submit-btn {
            border: none;
            padding: 10px 20px;
            border-radius: 0.3rem;
            background-color: #f4a34d;
            color: #fff;
            font-size: 17px;
            text-transform: uppercase;
            font-weight: 500;
            width: 100%;
            height: 50px;
          }

          .forgott-password-link {
            margin-bottom: 17px;
            margin-top: 0px;
            .forgot-link {
              color: #15242e;
              font-weight: 600;
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }

      .right {
        padding-left: 1rem;

        .area-title {
          font-size: 1.5rem;
          color: #2c2c2c;
          margin-bottom: 1.5rem;
          text-transform: uppercase;
          margin-bottom: 1rem;
        }

        .heading-text {
          margin-bottom: 10px;
          color: #5e5e5e;
          font-size: 14px;
        }

        .points {
          margin-bottom: 90px;
          color: #5e5e5e;
          padding-left: 1.1rem;

          font-size: 14px;

          li {
            // margin-bottom: 1rem;
            list-style: disc;

            &:last-child {
              margin-bottom: 1rem;
            }
          }
        }

        .create-account-btn {
          border: none;
          padding: 10px 20px;
          border-radius: 0.3rem;
          background-color: #f4a34d;
          color: #fff;
          font-size: 17px;
          text-transform: uppercase;
          font-weight: 500;
          height: 50px;
        }
      }
    }
    .social-singinWraper {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      padding-bottom: 2rem;
      .singinlinewit {
        font-size: 10px;
        margin-bottom: 0;
      }
      .social-loginbtnwrapper {
        display: flex;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        .social-img {
          width: 20px;
          margin-right: 10px;
        }
      }
    }
  }
  .react-responsive-modal-closeButton {
    background: #fff;
  }
}
@media (max-width: 992px) {
  .login-popup-modal {
    .inner-container-login {
      width: 490px;
      .content {
        .right,
        .left {
          width: 50%;
        }

        .left {
          padding-right: 1rem;
        }

        .right {
          padding-left: 0rem;
          margin-top: 20px;
          .points {
            margin-bottom: 50px;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .login-popup-modal {
    .inner-container-login {
      width: 280px;
      .content {
        flex-direction: column;

        .right,
        .left {
          width: 100%;
        }

        .left {
          padding-right: 0rem;
        }

        .right {
          padding-left: 0rem;
          margin-top: 20px;
          .points {
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}
@media (max-width: 577px) {
  .login-popup-modal {
    .inner-container-login {
      width: 280px;
      .title-text {
        margin: 0;
      }
      .content {
        flex-direction: column;
        padding: 1rem;

        .right,
        .left {
          width: 100%;
        }

        .left {
          padding-right: 0rem;
        }

        .right {
          padding-left: 0rem;
          margin-top: 20px;
          .points {
            margin-bottom: 20px;
          }
          .create-account-btn {
            width: 100%;
          }
        }
      }
    }
  }
}
