.productdeswrapper {
  width: 100%;
  height: 100%;
  background-color: #fff;

  margin-top: 50px;
  border: 1px solid hsl(0, 2%, 88%);
  .description-link-wrapper {
    border-bottom: 1px solid hsl(0, 2%, 88%);
    padding: 20px 0;
    .description-heading {
      padding: 20px 40px;
      color: #a1abad;
      font-size: 17px;
      text-transform: uppercase;
      font-weight: 900;
      cursor: pointer;
    }
    .description-active {
      font-weight: 900;
      color: #000;
    }
  }
  .descripton-texts {
    padding: 25px 25px;
    p {
      color: #7d8d8f;
      font-size: 15px;
      span {
        color: #7d8d8f !important;
        font-family: inherit !important;
      }
    }
  }
}

@media (max-width: 768px) {
  .description-link-wrapper {
    display: flex;
    flex-direction: column;
    .description-heading {
      padding: 10px 20px !important;
    }
  }
}
