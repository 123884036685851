.thankupage-wrapper {
  .thankupagecontaint-wrapp {
    display: flex;
    padding: 80px 0 120px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    img {
      width: 120px;
    }
    .thankuline {
      margin-top: 20px;
      font-size: 71px;
      text-transform: capitalize;
      font-weight: bold;
      color: #15242e;
    }
    p {
      text-align: center;
      width: 70%;
      font-size: 14px;
      margin-bottom: 30px;
    }
    .continuebtn {
      background-color: #15242e;
      color: #fff;
      padding: 10px 20px;
      border-radius: 70px;
      outline: none;
      font-weight: bold;
      border: none;
    }
  }
}

@media (max-width: 992px) {
  .thankupage-wrapper {
    .thankupagecontaint-wrapp {
      padding: 50px 0 100px;

      p {
        width: 79%;
        font-size: 14px;
        margin-bottom: 19px;
      }
    }
  }
}

@media (max-width: 767px) {
  .thankupage-wrapper {
    .thankupagecontaint-wrapp {
      img {
        width: 120px;
      }
      .thankuline {
        font-size: 51px;
      }
      p {
        width: 90%;
        font-size: 14px;
        margin-bottom: 20px;
      }
    }
  }
}

@media (max-width: 490px) {
  .thankupage-wrapper {
    .thankupagecontaint-wrapp {
      padding: 50px 0 120px;
      img {
        width: 80px;
      }
      .thankuline {
        margin-top: 20px;
        font-size: 21px;
      }
      p {
        width: 100%;
        font-size: 10px;
        margin-bottom: 20px;
      }
      .continuebtn {
        font-size: 12px;
      }
    }
  }
}
