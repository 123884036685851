.sideform-wrapper {
  background-color: #f5f5f5;
  padding: 20px;
  .store-category-wrappers {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    cursor: pointer;
    .store-category-heading {
      font-weight: 700;

      font-size: 18px;
    }
    .store-category-subheading {
      font-size: 13px;
      margin-bottom: 10px;
      color: #5d5d5d;
    }
  }
  .sideads-imgwrapper {
    width: 100%;
    height: 100%;
  }
}

.productscategory-wrappernew {
  background-color: #f5f5f5;
  padding: 0 5px;
  .product-heading {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 0;
    padding: 8px 16px;
  }
  .product-category-itmes-wrapper {
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      padding: 8px 0px;
      li {
        label {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 7px 0px;
          cursor: pointer;
          font-size: 12px;
          span {
            .checkboxclas {
              margin-right: 10px;
            }
          }

          label {
            display: flex;
            align-items: center;
            cursor: pointer;
          }
          i {
            font-size: 8px;
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .sideform-wrapper {
    margin-top: 30px;
    .store-category-wrappers {
      .store-category-heading {
        font-weight: 700;
        font-size: 18px;
      }
    }
  }
}
