.Datepopup-modal {
  padding: 20px;
  border-radius: 5px;
  .loader-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #f4a34d;
  }
  .react-responsive-modal-closeButton {
    display: none;
  }

  .datetime-wrapepr {
    padding: 0px;

    .react-calendar {
      border-top: 1px solid #a0a096 !important;
      border-bottom: 1px solid #a0a096 !important;
      border: none;
      margin-bottom: 15px;
      margin-top: 15px;
    }
    .select-dateline {
      font-size: 18px;
      font-weight: 600;
    }
    .time-wrapper {
      margin-bottom: 50px;
      .select-timeline {
        font-size: 18px;
        font-weight: 600;
      }
      .select-time-wrapper {
        width: 100%;
        outline: none;
        border: 1px solid #a0a096;
        height: 30px;
        font-size: 14px;
        color: #15242e;
        margin-bottom: 15px;
      }
      .total-priceline {
        color: #445053;
        font-size: 16px;
        margin-bottom: 10px;
        margin-top: 10px;
        span {
          color: #f4a34d;
        }
      }
    }
    .contormdate-btn {
      width: 100%;
      height: 40px;
      border: none;
      outline: none;
      border-radius: 5px;
      background-color: #f4a34d;
      font-size: 12px;
      color: #fff;
      font-weight: 600;
    }
  }
}
