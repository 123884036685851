.navbar-bgwrapp {
  background-color: #15242e;

  .nav-main {
    display: flex;
    padding: 10px 0;
    align-items: center;
    background-color: #15242e;
    justify-content: space-between;
    border-bottom: 1px solid #414e56;

    .column-left {
      width: 200px;
      .threebar {
        display: none;
      }
    }
    .column-center {
      width: 69%;
      height: 65px;
      margin-left: 0px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .shop-by-categorywrapp {
        display: flex;
        width: 9%;
        padding: 7px 5px;
        font-size: 11px;
        position: relative;
        border-radius: 23px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #fff;
      }

      // .location-wrapeer {
      //   padding: 7px 5px;
      //   height: 45px;
      //   position: relative;
      //   width: 26%;
      //   border-radius: 23px;
      //   border: 1px solid #eceff0;
      //   background-color: #fff;
      //   cursor: pointer;
      //   display: flex;
      //   justify-content: space-between;
      //   align-items: center;
      //   .current-locationline {
      //     margin-left: 10px;
      //     font-size: 11px;
      //   }

      //   .down-arow {
      //     right: 25px;
      //     font-size: 13px;
      //     padding: 5px;
      //     color: #858585;
      //   }
      //   .location-description {
      //     font-size: 15px;
      //     color: #858585;
      //     width: 50%;
      //   }
      // }
      .serch-inputwrapperLoc {
        width: 100%;
        position: relative;
        .serch-bartop {
          width: 100%;

          input {
            position: relative;
            width: 100%;
            padding: 10px 10px 10px 20px;
            border-radius: 30px;
            outline: none;
            border: 1px solid #eceff0;
            background-color: #f9f9f9;
          }

          .serch-btn-top-bg {
            position: absolute;
            right: 5px;
            top: 5px;
            background-color: #f9f9f9;
            font-size: 19px;
            width: 37px;
            height: 37px;
            color: #fff;
            border: none;
            outline: none;
            border-radius: 25px;
          }
        }
        .ajexsBox-wrapper {
          width: 100%;
          position: absolute;
          padding: 5px 10px;
          z-index: 2;
          max-height: 400px;
          overflow-y: scroll;
          scroll-behavior: smooth;

          .ajesitems {
            display: flex;
            align-items: center;
            background-color: #fff;
            border: 1px solid #eceff0;
            list-style: none;
            padding: 8px 16px;
            border-radius: 3px;
            cursor: pointer;
            .ajexbox-imgwrapp {
              width: 30px;
              height: 30px;
              margin-right: 5px;
            }
            img {
              width: 100%;
              height: 100%;
            }
            span {
              font-size: 12px;
            }
          }

          .ajesitems:hover {
            display: flex;
            align-items: center;
            background-color: #f9f9f9;
            border: 1px solid #eceff0;
            list-style: none;
            padding: 8px 16px;
            border-radius: 3px;
            cursor: pointer;
            .ajexbox-imgwrapp {
              width: 30px;
              height: 30px;
              margin-right: 5px;
            }
            img {
              width: 100%;
              height: 100%;
            }
            span {
              font-size: 12px;
            }
          }
        }
        ::-webkit-scrollbar {
          width: 5px;
        }
      }
      .serch-inputwrapper {
        width: 88%;
        margin-left: 0px;
        margin-right: 0px;
        position: relative;
        .fa-search {
          position: absolute;
          top: 10px;
          left: 10px;
          color: rgb(155, 153, 153);
          z-index: 1;
        }
        .css-319lph-ValueContainer {
          padding: 2px 30px !important;
        }
        .css-1d8n9bt {
          padding: 2px 30px !important;
        }
        .input-search-bar {
          width: 100%;
          height: 45px;
          position: relative;

          input {
            width: 100%;
            height: 45px;
            border-radius: 7px;
            border: none;
            outline: none;
            padding: 0px 0px 0px 36px;
            position: relative;
            font-size: 14px;
          }
          i {
            position: absolute;
            top: 16px;
            font-size: 14px;
            left: 11px;
            color: #adb8b9;
          }
        }
        .serch-bartop {
          width: 100%;
          font-size: 16px;

          .css-1s2u09g-control {
            border: none;
            border-radius: 5px;
            // padding: 0px 70px 0px 18px;
          }
          .css-1pahdxg-control {
            border: none;
            box-shadow: none;
            border-radius: 5px;
          }
          .css-1pahdxg-control:hover {
            border-color: transparent;
          }

          input {
            position: relative;
            width: 100%;
            padding: 10px 70px 105px 20px;
            border-radius: 0px;
            outline: none;
            border: 1px solid #eceff0;
            height: 33px;
            background-color: #f9f9f9;
          }

          .serch-btn-top-bg {
            position: absolute;
            right: 0px;
            top: 0px;
            font-weight: 400;
            background-color: #f4a34d;
            font-size: 14px;
            width: 117px;
            height: 100%;
            color: #fff;
            border: none;
            outline: none;
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
          }
          .serch-icon-topinput {
            position: absolute;
            left: 0px;
            top: 0px;
            font-size: 11px;
            width: 30px;
            background: none;
            height: 100%;
            color: rgb(228, 228, 228);
            border: none;
            outline: none;
            border-radius: 0px;
          }
        }
        .ajexsBox-wrapper {
          width: 100%;
          position: absolute;
          padding: 5px 10px;
          z-index: 2;
          max-height: 400px;
          overflow-y: scroll;
          scroll-behavior: smooth;

          .ajesitems {
            display: flex;
            align-items: center;
            background-color: #fff;
            border: 1px solid #eceff0;
            list-style: none;
            padding: 8px 16px;
            border-radius: 3px;
            cursor: pointer;
            .ajexbox-imgwrapp {
              width: 30px;
              height: 30px;
              margin-right: 5px;
            }
            img {
              width: 100%;
              height: 100%;
            }
            span {
              font-size: 12px;
            }
          }
          .ajesitems.selected {
            background-color: #ed1c24 !important;
          }
          .ajesitems:hover {
            display: flex;
            align-items: center;
            background-color: #f9f9f9;
            border: 1px solid #eceff0;
            list-style: none;
            padding: 8px 16px;
            border-radius: 3px;
            cursor: pointer;
            .ajexbox-imgwrapp {
              width: 30px;
              height: 30px;
              margin-right: 5px;
            }
            img {
              width: 100%;
              height: 100%;
            }
            span {
              font-size: 12px;
            }
          }
        }
        ::-webkit-scrollbar {
          width: 5px;
        }
      }
      .cammparee-imgwrapepr {
        transform: rotate(90deg);
        width: 25px;
        cursor: pointer;
      }
    }
    .column-right {
      display: flex;
      width: 9%;
      // margin-left: 20px;
      justify-content: space-between;
      align-items: center;
      color: #ffff;

      .search-txt {
        display: none;
      }
      .search-btn {
        i {
          display: none;
        }
      }

      span {
        margin-right: 10px;
        margin-left: 10px;
        font-weight: 400;
      }
      .cart-bgwrap {
        position: relative;
        cursor: pointer;
        link {
          color: #fff;
        }
        .cart-counting {
          background-color: #f4a34d;
          width: 17px;
          text-align: center;
          height: 17px;
          color: #fff;
          font-size: 10px;
          position: absolute;
          right: -22px;
          border-radius: 30px;
        }
        .shoping-icon {
          width: 23px;
          height: 22px;
          margin-top: -6px;
        }
      }
      .cart-link {
        color: #fff;
      }
      .heart-iconlink {
        color: #ffff;
        cursor: pointer;
        position: relative;
        .hrart-icon {
          font-size: 20px;
        }
        .wishlist-counting {
          background-color: #f4a34d;
          width: 15px;
          text-align: center;
          height: 15px;
          color: #fff;
          font-size: 10px;
          position: absolute;
          right: -20px;
          top: -7px;
          border-radius: 30px;
        }
      }
      .user-img {
        cursor: pointer;
        font-size: 20px;
        color: white;

        img {
          cursor: pointer;
        }
        Link {
          .user-iconnav {
            color: #fff;
          }
        }
      }
    }
  }
}

@media (max-width: 1400px) {
  .nav-main {
    justify-content: space-between !important;
    .column-left {
      display: flex;
      align-items: center;
    }
    .column-center {
      width: 69%;

      .serch-inputwrapper {
        width: 100%;
        margin-left: 0px;
        margin-right: 0px;
      }
    }
    .column-right {
      margin-left: 0px;

      .user-img {
        font-size: 18px !important;
      }
      .heart-iconlink {
        .hrart-icon {
          font-size: 18px !important;
        }
      }

      .cart-bgwrap {
        margin-right: 0px !important;
        font-size: 18px;
        .shoping-icon {
          font-size: 21px !important;
        }
      }
    }
  }
}
@media (max-width: 1200px) {
  .nav-main {
    justify-content: space-between !important;
    .column-left {
      display: flex;
      align-items: center;
      .threebar {
        margin-right: 15px;
        display: unset !important;
        font-size: 21px;
        color: #fff !important;
      }
    }
    .column-center {
      width: 65% !important;
      .shop-by-categorywrapp {
        display: none !important;
      }
      .serch-inputwrapper {
        width: 100% !important;
        margin-left: 0px;
        margin-right: 0px;
      }
    }
  }
}

@media (max-width: 768px) {
  .nav-main {
    justify-content: space-between;
    .column-center {
      display: none !important;
      margin-left: 0;
      width: 40%;

      .location-wrapeer {
        display: none;
      }
      .serch-inputwrapper {
        display: none;
      }
      .cammparee-imgwrapepr {
        display: none;
      }
    }

    .column-right {
      margin-left: 0px;
      width: 15% !important;

      .user-img {
        cursor: pointer;
        font-size: 18px !important;
      }
      .heart-iconlink {
        .hrart-icon {
          font-size: 18px !important;
        }
      }

      span {
        margin-left: 10px;
        font-weight: 400;
      }
      .cart-bgwrap {
        position: relative;
        margin-left: 0px;
        margin-right: 0px !important;
        font-size: 18px;
        .shoping-icon {
          font-size: 21px !important;
        }
        .cart-counting {
          width: 9px;
          height: 9px;
          color: #fff;
          font-size: 6px;
          right: -10px;
        }
      }
    }
  }
}

@media (max-width: 568px) {
  .nav-main {
    padding: 10px 0px;
    justify-content: space-between !important;
    .column-left {
      display: flex;
      align-items: center;
      .logo-img {
        width: 50%;
        margin-left: 10px;
      }
      .threebar {
        display: block;
        font-size: 16px;
        margin-right: 0;
      }
    }
    .column-center {
      margin-left: 0;
      display: none !important;

      .location-wrapeer {
        display: none;
      }
      .serch-inputwrapper {
        display: none;
      }
      .shop-by-categorywrapp {
        display: none;
      }
    }
    .column-right {
      margin-right: 11px;
      width: 26% !important;

      .user-img {
        cursor: pointer;
        font-size: 18px !important;
      }
      .heart-iconlink {
        .hrart-icon {
          font-size: 18px !important;
        }
      }

      span {
        margin-left: 10px;
        font-weight: 400;
      }
      .cart-bgwrap {
        position: relative;
        margin-left: 0px;
        margin-right: 0px !important;
        font-size: 18px;
        .shoping-icon {
          font-size: 18px !important;
          width: 20px;
        }
        .cart-counting {
          width: 9px;
          height: 9px;
          color: #fff;
          font-size: 6px;
          right: -10px;
        }
      }
    }
  }
}
