.New-product-right-side {
  .product-heading-wrapper {
    display: flex;
    justify-content: space-between;
    .newproduct-tag {
      font-size: 24px;
      font-weight: 500;
    }
    .viewallbtn {
      background-color: #f4a34d;
      color: #fff;
      text-transform: uppercase;
      font-size: 13px;
      padding: 10px 26px;
      border-radius: 50px;
      border: none;
      outline: none;
      cursor: pointer;
    }
  }
  .products-items-wrapper {
    margin-top: 0px;
    .newproduct-firstrow {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .product-wrapper {
        border: 1px solid #f6f6f6;
        position: relative;
        height: 370px;
        margin: 10px 10px;

        .hover-wish-list-wrappper {
          position: absolute;
          cursor: pointer;
          width: 25%;
          height: 50%;
          padding: 10px;
          transition: 2s;
          right: 0;
          display: none;
          .wish-list-imgs {
            float: right;
            img {
              margin-bottom: 10px;
            }
          }
        }
        .product-imgs-wrap {
          // padding: 15px;
          align-items: center;
          width: 100%;
          height: 200px;
          cursor: pointer;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .items-content-wrappers {
          padding: 10px 20px;
          cursor: pointer;
          .items-name {
            font-size: 14px;
            font-weight: 500;
            height: 40px;
            margin-bottom: 10px;
            display: -webkit-box;
            overflow: hidden;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .instock {
            font-size: 11px;
            text-transform: uppercase;
            font-weight: bold;
            color: #f4a34d;
            margin-bottom: auto;
          }
          .last-price {
            font-size: 15px;
            color: #c3c8c9;
            margin-right: 10px;
          }
          .latest-price {
            font-size: 16px;
            font-weight: bold;
            color: #ed1c24;
          }
          .addto-cart-btn {
            background-color: transparent;
            border: 1px solid #f4a34d;
            border-radius: 20px;
            padding: 5px 20px;
            outline: none;
            width: 100%;
            color: #f4a34d;
            font-weight: 500;
            margin-top: 20px;
          }
          .addto-cart-btn .loader-spinner .loading-gif {
            animation-name: spin;
            animation-duration: 2000ms;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
          }
          .addto-cart-btn:hover {
            background-color: #f4a34d;
            color: #fff;
          }
          .addto-cart-btn-already {
            background-color: transparent;
            border: 1px solid #f4a34d;
            border-radius: 20px;
            padding: 5px 20px;
            outline: none;
            width: 100%;
            color: #f4a34d;
            font-weight: 500;
            margin-top: 20px;
          }
        }
      }
      .product-wrapper:hover {
        .hover-wish-list-wrappper {
          display: block;
        }
      }
    }
  }
  .mob-responsive-slide {
    display: none;
    margin-top: 15px;
    .slick-arrow {
      display: none !important;
    }
    .product-slider-wrapper {
      width: 100%;
      display: flex;
      .product-wrapper {
        border: 1px solid #f6f6f6;
        position: relative;
        height: 370px;
        width: 100%;

        .hover-wish-list-wrappper {
          position: absolute;
          cursor: pointer;
          width: 100%;
          height: 50%;
          padding: 10px;
          transition: 2s;
          display: none;
          .wish-list-imgs {
            float: right;
            img {
              margin-bottom: 10px;
            }
          }
        }

        .product-imgs-wrap {
          align-items: center;
          height: 200px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .items-content-wrappers {
          padding: 15px;
          .items-name {
            font-size: 14px;
            font-weight: 500;
            height: 40px;
            margin-bottom: 10px;
            display: -webkit-box;
            overflow: hidden;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .instock {
            font-size: 11px;
            text-transform: uppercase;
            font-weight: bold;
            color: #f4a34d;
            margin-bottom: auto;
          }
          .last-price {
            font-size: 15px;
            color: #c3c8c9;
            margin-right: 10px;
          }
          .latest-price {
            font-size: 15px;
            font-weight: bold;
            color: #ed1c24;
          }
          .addto-cart-btn {
            background-color: transparent;
            border: 1px solid #f4a34d;
            border-radius: 20px;
            padding: 5px 20px;
            outline: none;
            width: 100%;
            color: #f4a34d;
            font-weight: 500;
            margin-top: 15px;
          }
          .addto-cart-btn:hover {
            background-color: #f4a34d;
            color: #fff;
          }
        }
      }
      .product-wrapper:hover {
        .hover-wish-list-wrappper {
          display: block;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .New-product-right-side {
    .product-heading-wrapper {
      .viewallbtn {
        padding: 06px 20px;
      }
    }
    .products-items-wrapper {
      .newproduct-firstrow {
        justify-content: flex-start;
        .product-wrapper {
          width: 31%;
          margin-left: 15px;
          .addto-cart-btn {
            padding: 5px 9px !important;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .New-product-right-side {
    .products-items-wrapper {
      .newproduct-firstrow {
        display: none;
      }
    }
    // sdgdg
    .mob-responsive-slide {
      display: block;
    }
  }
}
.viewcartparanew {
  font-size: 11px;
  margin-bottom: 10px;
  .viewcartlink {
    margin-left: 5px;
    color: #15242e;
    font-weight: 700;
  }
}
@media (max-width: 448px) {
  .New-product-right-side {
    height: 360px;
    .product-heading-wrapper {
      .newproduct-tag {
        font-size: 17px;
      }
    }

    .mob-responsive-slide {
      .product-slider-wrapper {
        .product-wrapper {
          height: 320px;
          .product-imgs-wrap {
            // padding: 20px;
            height: 150px;
          }
          .items-content-wrappers {
            padding: 15px;
            .addto-cart-btn {
              padding: 5px 6px;
            }
          }
        }
      }
    }
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
