// .react-responsive-modal-modal {
//   max-width: 60% !important;
//   height: auto;
//   padding: 0;
// }
.form-popup-modal {
  width: 100% !important;
}
.react-responsive-modal-closeButton {
  top: 2px;
  right: 1px;
  // border-radius: 10px;
}

@media (max-width: 768px) {
  .react-responsive-modal-modal {
    max-width: 90% !important;
  }
  .form-popup-modal {
    width: 100% !important;
  }
  .react-responsive-modal-closeButton {
    top: 2px;
    right: 1px;
  }
}
