.product-bg {
  background-color: #f8f8f8;
  padding-top: 30px;
  padding-bottom: 30px;

  .product-wrapper {
    background-color: #fff;
    padding: 20px;
    display: flex;

    .product-img-wrapper {
      width: 50%;
      display: flex;
      justify-content: space-between;

      .leftsideimg-wrapper {
        display: flex;
        flex-direction: column;
        width: 20%;
        height: 90%;
        .leftimgssection {
          width: 100px;
          height: 100px;
          margin-top: 0;
          border: 2px solid #f5f6fb;
          margin-bottom: 10px;
          cursor: pointer;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
    .proucts-main-img {
      width: 80% !important;
      // height: 500px;
      // display: flex;
      // align-items: center;
      // justify-content: center;
      img {
        width: 100%;
      }
      div {
        .full_background_white {
          background-color: #fff;
        }
        // height: 100% !important;
        // width: 100% !important;

        // img {
        //   width: 100% !important;
        //   height: 100% !important;
        // }
      }
    }

    .products-details-wrapper {
      width: 50%;
      height: 100%;
      padding: 0px 30px 10px 10px;

      .products-links-wrap {
        margin-bottom: 10px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 90%;
        margin-left: 14px;
        .slash-wrap {
          font-size: 11px;
          color: #6d6d6d;
          i {
            z-index: -1;
            transform: rotate(80deg);
          }
        }
        a {
          text-decoration: none;
          color: #6d6d6d;
          padding: 10px 0px;
          font-size: 14px;
        }
      }
      h6 {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 10px;
        margin-top: 0px;
        padding-left: 14px;
      }
      .amount-section {
        padding-left: 14px;
        margin-bottom: 15px;
        p {
          font-size: 12px;
          margin-bottom: 10px;
        }
        del {
          font-size: 16px;
          color: #aaaaaa;
        }
        span {
          color: #ed1c24;
          font-size: 22px;
          font-weight: bold;
          margin-left: 10px;
        }
        .stock-update {
          background-color: #15242e;
          padding: 4px 10px;
          font-size: 12px;
          color: #fff;
          margin-left: 20px;
        }
      }

      .singleproduct-wrapper {
        border-bottom: 1px solid#f6f6f6;
        border-top: 1px solid#f6f6f6;
        display: flex;
        padding: 10px 0;
        align-items: center;
        margin-left: 20px;
        .quantity-wrapper {
          width: 50%;
          .quantitywrapp {
            display: flex;
            align-items: center;
            .Quantitybtn-wrapepr {
              margin-left: 15px;
              .decrement-btn {
                border: 1px solid#f6f6f6;
                background-color: #fff;
                padding: 8px 10px;
                font-size: 11px;
              }
              .product-number {
                border: 1px solid#f6f6f6;
                background-color: #f8f8f8;
                font-size: 11px;
                width: 40px;
                height: 34px;
              }

              .increment-btn {
                border: 1px solid#f6f6f6;
                background-color: #fff;
                padding: 8px 10px;
                font-size: 11px;
              }
            }
          }
          .products-price-wrapper {
            margin-top: 15px;
            del {
              font-size: 16px;
              color: #aaaaaa;
            }
            span {
              color: #ed1c24;
              font-size: 22px;
              font-weight: bold;
              margin-left: 10px;
            }
          }
        }
        .bye-btnwrapper {
          width: 50%;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          .ByeNow-btn {
            background-color: #f4a34d;
            padding: 8px 16px;
            border-radius: 30px;
            font-size: 14px;
            color: #fff;
            text-transform: uppercase;
            outline: none;
            border: none;
            margin-bottom: 10px;
            width: 75%;
          }
          .addtobasket-btn {
            background-color: #213340;
            padding: 8px 16px;
            border-radius: 30px;
            font-size: 14px;
            color: #fff;
            text-transform: uppercase;
            outline: none;
            border: none;
            margin-bottom: 10px;
            width: 75%;
          }
          .addto-wishlist-btn {
            background-color: #fff;
            border: 1px solid#213340;
            padding: 8px 12px;
            border-radius: 30px;
            font-size: 13px;
            color: #213340;
            text-transform: uppercase;
            outline: none;
            width: 75%;
          }
        }
      }

      .productBid-wrapper {
        margin-left: 15px;
        margin-bottom: 20px;

        .Conditionline-wrapp {
          font-size: 14px;
          margin-bottom: 10px;
        }
        .bid-line-wrappper {
          display: flex;
          width: 100%;
          margin: 20px 0px;
          align-items: center;
          // justify-content: space-between;
          .amountsection {
            .decrement-btn {
              border: 1px solid#f6f6f6;
              background-color: #fff;
              padding: 8px 10px;
              font-size: 11px;
            }
            .product-number {
              border: 1px solid#f6f6f6;
              background-color: #f8f8f8;
              font-size: 11px;
              width: 50px;
              height: 35px;
            }
            .increment-btn {
              border: 1px solid#f6f6f6;
              background-color: #fff;
              padding: 8px 10px;
              font-size: 11px;
            }
          }
        }
        .bid-btn {
          background-color: #15242e;
          padding: 8px 26px;
          border-radius: 30px;
          font-size: 14px;
          color: #fff;
          text-transform: uppercase;
          outline: none;
          border: none;
          margin-left: 30px;
        }
        .bid-btnDisabled {
          background-color: #c9c9c9;
          padding: 8px 26px;
          border-radius: 30px;
          font-size: 14px;
          color: #fff;
          text-transform: uppercase;
          outline: none;
          border: none;
          cursor: not-allowed;
        }
        .BuyNow-bidbtn {
          background-color: #f4a34d;
          padding: 8px 26px;
          border-radius: 30px;
          font-size: 14px;
          color: #fff;
          text-transform: uppercase;
          outline: none;
          border: none;
          margin-bottom: 0px;
          margin-left: 30px;
        }
      }
      .add-cart-wrpaeer {
        margin-left: 20px;
        margin-top: 20px;
        margin-bottom: 10px;
        border-bottom: 2px solid #f6f6f6;
        .decrement-btn {
          border: 1px solid#f6f6f6;
          background-color: #fff;
          padding: 8px 10px;
          font-size: 11px;
        }
        .product-number {
          border: 1px solid#f6f6f6;
          background-color: #f8f8f8;
          font-size: 11px;
          width: 25px;
          height: 35px;
        }
      }
      .increment-btn {
        border: 1px solid#f6f6f6;
        background-color: #fff;
        padding: 8px 10px;
        font-size: 11px;
      }
      .add-to-cart-btn {
        background-color: #15242e;
        padding: 8px 16px;
        border-radius: 30px;
        font-size: 14px;
        color: #fff;
        text-transform: uppercase;
        outline: none;
        border: none;
        margin-left: 20px;
      }
      .add-to-cart-btn2 {
        background-color: #fff;
        padding: 8px 16px;
        border-radius: 30px;
        border: 2px solid #15242e;
        font-size: 14px;
        color: #15242e;
        text-transform: uppercase;
        outline: none;
        margin-left: 20px;
      }
      .optionsize-wrappers {
        display: flex;
        flex-direction: column;
        width: 40%;
        margin-top: 20px;

        .sizeoptin-wrapp {
          width: 100%;
          background-color: #f6f6f6;
          font-size: 14px;
          margin-bottom: 20px;
          display: flex;
          .sizeoption-drop {
            width: 240px;
            padding: 10px 16px;
            border: 2px solid #f6f6f6;
            background-color: #f8f8f8;
            margin-left: 10px;
          }
          .coloroption-drop {
            border: none;
            width: 100%;
            border: 2px solid #f6f6f6;
            background-color: #f8f8f8;
            padding: 10px 16px;
          }
        }
      }
      .viewcartinfowrpp {
        padding-left: 20px;
        margin-bottom: 15px;
        P {
          font-size: 12px;
          margin-bottom: 10px;
          .viewcartlink {
            margin-left: 5px;
            color: #15242e;
            font-weight: 700;
          }
        }
      }
    }
    .wishlist-wrap {
      margin-left: 20px;
      padding: 8px 0;
      border-bottom: 2px solid#f6f6f6;
      span {
        font-size: 14px;
        i {
          color: #ed1c24;
        }
      }
    }
    .sku-text-wrapper {
      margin-left: 14px;
      padding: 23px 0;
      border-top: 2px solid#f6f6f6;
      border-bottom: 2px solid#f6f6f6;
      span {
        font-size: 14px;
        color: #66717a;
        margin-left: 10px;
      }
    }

    .share-wrapper {
      padding: 23px 0;
      margin-left: 14px;
      a {
        font-size: 16px;
        margin-left: 20px;
        color: #000;
      }
    }
  }
}

@media (max-width: 1400px) {
  .product-bg {
    .product-wrapper {
      .proucts-main-img {
        width: 75%;
      }
    }
  }
}

@media (max-width: 1200px) {
  .product-bg {
    .product-wrapper {
      .product-img-wrapper {
        width: 50%;
        flex-direction: column;
        justify-content: space-between;

        .leftsideimg-wrapper {
          order: 2;
          flex-direction: row;
          justify-content: space-around;
          width: 100%;
          .leftimgssection {
            width: 105px;
            height: 100px;
          }
        }
      }
      .proucts-main-img {
        width: 100%;
        order: 1;
        height: 400px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .product-bg {
    .product-wrapper {
      display: flex;
      flex-direction: column;

      .product-img-wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .leftsideimg-wrapper {
          display: flex;

          .leftimgssection {
            width: 150px;
            height: 150px;
            margin-top: 20px;
          }
        }
      }
      .proucts-main-img {
        width: 100% !important;
        order: 1;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .products-details-wrapper {
        width: 100%;
        height: 100%;
        padding: 25px 0px 18px 0px;
      }
    }
  }
}

@media (max-width: 768px) {
  .product-bg {
    .product-wrapper {
      .product-img-wrapper {
        .leftsideimg-wrapper {
          .leftimgssection {
            width: 110px;
            height: 120px;
            margin-top: 10px;
          }
        }
      }
      // .proucts-main-img {
      //   width: 100% !important;
      //   order: 1;
      //   height: 100%;
      //   display: flex;
      //   align-items: center;
      //   justify-content: center;
      // }
    }
  }
}

@media (max-width: 568px) {
  .product-bg {
    .product-wrapper {
      .product-img-wrapper {
        .leftsideimg-wrapper {
          .leftimgssection {
            width: 60px;
            height: auto;
            margin-top: 10px;
          }
        }
      }
      .proucts-main-img {
        width: 100%;
        order: 1;
        height: auto;
      }
      .products-details-wrapper {
        .products-links-wrap {
          margin-bottom: 15px;

          a {
            text-decoration: none;
            color: #6d6d6d;
            padding: 10px 4px;
            font-size: 13px;
          }
        }
        h6 {
          font-size: 18px;
          padding-left: 1px;
          margin-top: 0;
          margin-bottom: 10px;
        }
        .amount-section {
          padding-left: 1px;

          del {
            font-size: 14px;
          }
          span {
            font-size: 18px;
          }
        }
        .stock-update {
          margin-left: 1px;
        }
        .add-cart-wrpaeer {
          margin-left: 1px;
        }

        .add-to-cart-btn {
          font-size: 12px;
        }
      }
      .wishlist-wrap {
        margin-left: 2px;
        padding: 23px 0;
        border-bottom: 2px solid#f6f6f6;
        span {
          font-size: 14px;
          i {
            color: #ed1c24;
          }
        }
        &:hover {
          cursor: pointer;
        }
      }
      .sku-text-wrapper {
        margin-left: 2px;
        padding: 23px 0;
        border-bottom: 2px solid#f6f6f6;
        span {
          font-size: 14px;
          color: #66717a;
          margin-left: 10px;
        }
      }

      .share-wrapper {
        padding: 23px 0;
        margin-left: 2px;
        a {
          font-size: 16px;
          margin-left: 20px;
          color: #000;
        }
      }

      .productBid-wrapper {
        margin-left: 2px !important;
        .Conditionline-wra.pp {
          font-size: 14px;
          margin-bottom: 10px;
        }
        .bid-line-wrappper {
          flex-direction: column;
          align-items: flex-start !important;
          .amountsection {
            margin-bottom: 10px;
          }
          .bid-btn {
            margin-bottom: 10px;
          }
          .BuyNow-bidbtn {
            margin-bottom: 20px;
          }
        }
      }

      .singleproduct-wrapper {
        flex-direction: column;
        margin-left: 2px !important;
        .quantity-wrapper {
          width: 100% !important;
          margin-bottom: 10px;
        }
        .bye-btnwrapper {
          width: 100% !important;
          align-items: center !important;
          .ByeNow-btn,
          .addtobasket-btn,
          .addto-wishlist-btn {
            width: 100% !important;
          }
          // .addtobasket-btn {
          //   background-color: #213340;
          //   padding: 8px 16px;
          //   border-radius: 30px;
          //   font-size: 14px;
          //   color: #fff;
          //   text-transform: uppercase;
          //   outline: none;
          //   border: none;
          //   margin-bottom: 10px;
          //   width: 70%;
          // }
          // .addto-wishlist-btn {
          //   background-color: #fff;
          //   border: 1px solid#213340;
          //   padding: 8px 16px;
          //   border-radius: 30px;
          //   font-size: 14px;
          //   color: #213340;
          //   text-transform: uppercase;
          //   outline: none;
          //   margin-bottom: 10px;
          //   width: 70%;
          // }
        }
      }
    }
  }
}
