.feedback-wrapper {
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  border: 1px solid #f6f6f6;
  .give-feedbackline {
    font-size: 16px;
    font-weight: 800;
    margin-bottom: 0;
  }
  .whatthink-line {
    font-size: 11px;
    font-weight: 500;
  }
  .Smiles-wrapper {
    padding: 15px 0;
    display: flex;
    width: 50%;
    justify-content: space-between;
    .smiles-bg {
      font-size: 21px;
      border-radius: 40px;
      padding: 10px;
      cursor: pointer;
    }
    .feedbackActive {
      color: #ffba44;
      border-radius: 40px;
      padding: 10px;
      background-color: #eaedf5;
    }
  }
  .feedback-inpt-wrapper {
    width: 100%;
    .dohavethoughtline {
      font-size: 11px;
    }
    .textarea-wrap {
      width: 100%;
      height: 100%;
      border-radius: 20px;
      border: 1px solid #b3c7ff;
      padding: 10px;
      // margin-top: 10px;
      margin: 20px 0;
      outline: none;
    }
  }
  .mayourfeed-wrapper {
    .may-wefollowupline {
      font-size: 11px;
      font-weight: 500;
    }
    .feed-radiowrapper {
      display: flex;
      align-items: center;
      padding: 10px 0;
      .radiobtn-wrapp {
        width: 80px;
      }
    }
  }
  .feed-btn-wrapper {
    .feedback-sent-btn {
      border: none;
      height: 42px;
      width: 100px;
      outline: none;
      background-color: #f4a34d;
      font-size: 13px;
      color: #fff;
      font-weight: bold;
      border-radius: 3px;
      margin-right: 10px;
    }
    .feedback-cancel-btn {
      border: none;
      height: 42px;
      width: 100px;
      outline: none;
      font-size: 13px;
      font-weight: bold;
      border-radius: 3px;
      background-color: transparent;
      border: 1px solid #e2e2e2;
      color: #606b87;
    }

    .feedback-cancel-btn:hover {
      border: none;
      background-color: #1abf5d;
      color: #fff;
    }
  }
}
