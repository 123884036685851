.cards-wrapper {
  background-color: #fff;
  padding: 20px;
  .Addnewcard-wrappeer {
    display: flex;
    // justify-content: flex-end;
    margin-bottom: 10px;

    .stripe-btns {
      border-radius: 30px;
      padding: 10px 28px;
      width: 170px;
      outline: none;
      border: none;
      text-transform: uppercase;
      font-size: 16px;
      background-color: #f19c43;
      color: #fff;
    }
  }

  .card-details-wrapper {
    background-color: #f7f6fb;
    padding: 15px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    .loader-spinner .loading-gif {
      animation-name: spin;
      animation-duration: 2000ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      color: #f4a34d;
    }

    .remove-btn {
      font-size: 16px;
      // background-color: #15242E
      padding: 10px 50px;
      text-decoration: underline;
      border: none;
      outline: none;
      cursor: pointer;
      font-weight: 600;
      // color: #15242E
      color: #ed1c24;
      border-radius: 20px;
    }
  }
}

@media (max-width: 576px) {
  .cards-wrapper {
    padding: 10px;
    .Addnewcard-wrappeer {
      .adddnewtag {
        font-size: 14px;
        color: #15242e;
        font-weight: bold;
        margin-bottom: 10px;
      }
    }

    .card-details-wrapper {
      padding: 15px 2px;
      display: flex;
      align-items: center;
      .remove-btn {
        font-size: 12px;
        padding: 10px 12px;
      }
    }
  }
}
