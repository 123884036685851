.recent-order-toptab {
  padding: 10px;
  background-color: #efefef;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .order-place-wrapp {
    .order-place-line {
      color: #464646;
      font-size: 11px;
      margin-bottom: 0;
    }
    .order-date {
      margin-bottom: 0;
      font-weight: 600;
      font-size: 12px;
    }
  }
  .shipto-wrapper {
    .shipto-line {
      font-size: 10px;
      color: #464646;
      margin-bottom: 0;
      text-transform: uppercase;
    }
    .owner-order-name {
      color: #15242e;
      font-size: 12px;
      font-weight: 500;
      text-transform: uppercase;
      margin-bottom: 0;
    }
    .short-addras {
      font-size: 12px;
      font-weight: 800;
    }
  }
  .total-wrapper {
    .total-tag {
      font-size: 12px;
      text-transform: uppercase;
      margin-bottom: 0;
    }
    .total-amounts {
      color: #15242e;
      font-size: 12px;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 0;
    }
  }
  .order-details-wrapper {
    .order-number {
      font-size: 12px;
      color: #464646;
      margin-bottom: 0;
    }
    .vierorder-details-link {
      color: #303030;
      font-size: 12px;
      border: none;
      background: none;
      outline: none;
      font-weight: 900;
      cursor: pointer;
    }
  }
}
.order-items-wrapper {
  padding: 20px 20px 0 20px;
  background-color: #fff;
  .deliverd-status {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
  }
  .cancelOrder {
    padding: 10px 65px;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 600;
    background-color: #15242e;
    color: white;
    cursor: pointer;
  }
  .order-product-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 0px 50px 0;
    .order-product-wrapperImage {
      width: 70%;
      display: flex;
      .order-productimg {
        width: 30%;
        text-align: center;
        img {
          width: 100%;
        }
      }
      .order-items-info {
        width: 67%;
        margin-left: 30px;
        .order-product-name {
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 0;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          // width: 50%;
        }
        .sold-bylin {
          font-size: 14px;
          margin-bottom: 0;
        }
        .return-elin {
          color: #505050;
          font-size: 12px;
          margin-bottom: 5px;
        }
        .price-tag {
          font-size: 14px;
          color: #ed1c24;
          font-weight: 900;
        }
      }
    }
    .orders-btns {
      width: 33%;
      .trackpackage-btn {
        width: 225px;
        padding: 10px 20px;
        border-radius: 10px;
        background: none;
        text-align: center;
        outline: navajowhite;
        border: 2px solid #efefef;
        margin-bottom: 10px;
        margin-left: 20px;
        font-size: 14px;
        font-weight: 600;
        cursor: pointer;
        i {
          font-size: 11px;
          margin-left: 5px;
        }
      }
      .addtocart-btn {
        width: 180px;
        padding: 10px 20px;
        border-radius: 10px;
        background: none;
        text-align: center;
        background-color: #f4a34d;
        margin-bottom: 10px;
        font-size: 14px;
        color: #fff;
        margin-left: 20px;
        font-weight: 600;
        cursor: pointer;
        i {
          font-size: 11px;
          margin-left: 5px;
        }
      }
    }
  }
}

@media (max-width: 1400px) {
  .OrderPage-wrapper {
    .recent-orderwrapper {
      .recent-order-toptab {
        display: flex;
        align-items: flex-start;

        .order-place-wrapp {
          margin-bottom: 10px;
        }
        .shipto-wrapper {
          margin-bottom: 10px;
        }
        .total-wrapper {
          margin-bottom: 10px;
        }
      }
      .order-items-wrapper {
        .order-product-wrapper {
          display: flex;

          .orders-btns {
            .trackpackage-btn {
              font-size: 12px;
              width: 198px !important;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1200px) {
  .OrderPage-wrapper {
    .recent-orderwrapper {
      .recent-order-toptab {
        display: flex;
        align-items: flex-start;

        .order-place-wrapp {
          margin-bottom: 10px;
        }
        .shipto-wrapper {
          margin-bottom: 10px;
        }
        .total-wrapper {
          margin-bottom: 10px;
        }
      }
      .order-items-wrapper {
        .order-product-wrapper {
          display: flex;
          align-items: flex-start;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .order-product-wrapper {
    display: flex;
    .order-product-wrapperImage {
      width: 60% !important;
      flex-direction: column !important;
      .order-productimg {
        width: 40% !important;
      }
      .order-items-info {
        width: 100% !important;
        margin-left: 0px !important;
        margin-top: 20px;
      }
    }
    .orders-btns {
      width: 40% !important;
    }
  }
}

@media (max-width: 576px) {
  .order-product-wrapper {
    display: flex;
    flex-direction: column;
    .order-product-wrapperImage {
      width: 100% !important;
      flex-direction: column !important;
      .order-productimg {
        width: 100% !important;
        height: 220px !important;
      }
      .order-items-info {
        width: 100% !important;
        margin-left: 0px !important;
        margin-top: 20px;
      }
    }
    .orders-btns {
      margin-top: 20px !important;
      width: 100% !important;
      .trackpackage-btn {
        margin-left: 0px !important;
      }
    }
  }
}
