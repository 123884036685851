.shipingwrapper {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #e6e6e6;
  margin-bottom: 20px;
  .shipingAddreswrapper {
    width: 30%;
    display: flex;
    flex-direction: column;
    h6 {
      margin-bottom: 15px;
    }
    span {
      font-size: 13px;
      color: #828891;
      margin-bottom: 5px;
    }
    .card-method {
      display: flex;
      align-items: center;

      img {
        margin-right: 10px;
      }
      span {
        font-size: 12px;
        color: #000;
        font-weight: bold;
      }
    }
    .orderSunnary-wrapper {
      ul {
        padding: 0;
        list-style: none;
        margin: 0;
        li {
          display: flex;
          justify-content: space-between;
          span {
            b {
              color: #000;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .shipingwrapper {
    flex-direction: column;

    .shipingAddreswrapper {
      width: 100%;
      display: flex;
    }
  }
}
