.accounts-rightside-wrapper {
  width: 100%;
  padding: 30px;
  background-color: #fff;
  .form-wrapper {
    display: flex;
    flex-direction: column;

    .file {
      position: relative;
      display: flex;
      align-items: center;
      input[type="file"] {
        display: none;
      }
      label {
        font-size: 1rem;
        font-weight: 300;
        cursor: pointer;
        outline: 0;
        user-select: none;
        border: 1px solid #e6e6e6;
        border-style: solid;
        border-radius: 4px;
        border-width: 1px;
        background-color: hsl(0, 0%, 100%);
        color: hsl(0, 0%, 29%);
        padding-left: 16px;
        padding-right: 16px;
        padding-top: 16px;
        padding-bottom: 16px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          border-color: 1px solid #e6e6e6;
        }

        &:active {
          background-color: hsl(0, 0%, 96%);
        }

        i {
          padding-right: 5px;
          color: #f4a34d;
        }
      }
    }
    label {
      margin-bottom: 10px;
      p {
        margin-bottom: 5px;
        font-weight: 500;
        span {
          color: #f4a34d;
        }
      }
      input {
        width: 100%;
        height: 35px;
        border: 1px solid #e6e6e6;
        outline: none;
        font-size: 14px;
        padding: 8px 11px;
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
    }
    .save-changes-btn {
      background-color: #f4a34d;
      color: #fff;
      font-size: 16px;
      padding: 13px 8px;
      width: 17%;
      border: none;
      border-radius: 5px;
      outline: none;
      font-weight: 500;
      margin-top: 20px;
      overflow: hidden;
    }

    // .eff-4{
    //   width:140px;
    //   height:50px;
    //   left:-140px;
    //   background:#34495e;
    //   position:absolute;
    //   transition:all .5s ease;
    //   z-index:1;
    // }
    // .button-4:hover .eff-4{
    //   left:0;
    // }
    // .button-4:hover a{
    //   color:#fff;
    // }
  }
}

@media (max-width: 768px) {
  .accounts-rightside-wrapper {
    width: 100%;
    margin-top: 20px;
    padding: 10px;

    .form-wrapper {
      display: flex;
      flex-direction: column;

      .save-changes-btn {
        width: 50%;
      }
    }
  }
}
