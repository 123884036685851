.Valuebanner-wrapper {
  width: 100%;
  padding: 5px 0 30px;

  .slick-arrow {
    display: none !important;
  }
  .value-banner {
    position: relative;
    height: 495px;
    width: 100%;
    background-size: cover;
    // background-image: url("../../../images/banner-2.png");

    img {
      width: 100%;
    }
  }
}
@media (max-width: 1440px) {
  .Valuebanner-wrapper {
    .value-banner {
      background-position: bottom;
      height: 450px;
    }
  }
}

@media (max-width: 1200px) {
  .Valuebanner-wrapper {
    .value-banner {
      background-position: bottom;
      height: 350px;
    }
  }
}

@media (max-width: 992px) {
  .Valuebanner-wrapper {
    .value-banner {
      background-position: bottom;
      height: 300px;
    }
  }
}
@media (max-width: 767px) {
  .Valuebanner-wrapper {
    .value-banner {
      background-position: bottom;
      height: 250px;
    }
  }
}
@media (max-width: 576px) {
  .Valuebanner-wrapper {
    .value-banner {
      background-position: bottom;
      height: 120px;
    }
  }
}
