.userreview-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  .user-wrapperd {
    display: flex;
    align-items: center;
    img {
      width: 35px;
      height: 35px;
      margin-right: 10px;
    }
    span {
      font-size: 16px;
    }
  }
  .recviddatewrapp {
    font-size: 14px;
  }
  .comment-wrapper {
    font-size: 13px;
  }
}
