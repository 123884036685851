.products-storetab-wrapper {
  background-color: #f1f1f1;
  padding: 20px;
  margin-top: 50px;
  .productstoretab-wrapper {
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    display: flex;
    .storetabwrpper {
      display: flex;
      width: 20%;
      justify-content: space-between;

      .storetab-details-wrapper {
        display: flex;
        flex-direction: column;
        b {
          color: #15242e;
        }
        .posive-feedback {
          font-size: 12px;
          margin-top: 5px;
          margin-bottom: 0px;
        }
      }
      .store-tablogo {
        height: 120px;
        width: 120px;
        border-radius: 50%;
      }
    }
    .visit-store {
      background-color: #f4a34d;
      padding: 8px 30px;
      border-radius: 20px;
      outline: none;
      border: none;
      font-size: 14px;
      color: #ffff;
    }
  }

  .store-categorey-details {
    margin-top: 20px;
    .store-categoryhading {
      font-size: 18px;
      color: #15242e;
      font-weight: 500;
      margin-bottom: 0;
      small {
        font-size: 11px;
        cursor: pointer;
      }
    }
    .category-tabson-store-wrapp {
      display: flex;
      flex-wrap: wrap;
      .storecategory-tab {
        width: 10%;
        margin: 10px;
        text-align: center;
        background-color: #e6e6e6;
        border-radius: 20px;
        border: none;
        outline: none;
        padding: 5px;
        font-size: 12px;
        font-weight: 500;
        cursor: pointer;
      }
    }
  }
}

@media (max-width: 1200px) {
  .products-storetab-wrapper {
    .productstoretab-wrapper {
      .storetabwrpper {
        width: 30%;
      }
    }

    .store-categorey-details {
      .category-tabson-store-wrapp {
        .storecategory-tab {
          width: 12%;
        }
      }
    }
  }
}

@media (max-width: 1023px) {
  .products-storetab-wrapper {
    .productstoretab-wrapper {
      .storetabwrpper {
        width: 40%;
      }
    }

    .store-categorey-details {
      .category-tabson-store-wrapp {
        flex-wrap: wrap;
        .storecategory-tab {
          width: 20%;
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .products-storetab-wrapper {
    .productstoretab-wrapper {
      flex-direction: column;
      .storetabwrpper {
        width: 100%;
      }
      .visit-store {
        margin-top: 20px;
      }
    }

    .store-categorey-details {
      .store-categoryhading {
        font-size: 14px;
      }
      .category-tabson-store-wrapp {
        flex-wrap: wrap;
        .storecategory-tab {
          width: 100%;
        }
      }
    }
  }
}
