.filter-wrapper {
  background-color: #fff;
  padding-right: 38px;
  .Filterby-pricename {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0;
    padding: 8px 0px;
  }

  input {
    width: 100%;
    outline: none;
  }

  .Filter-changes {
    display: flex;
    justify-content: space-between;
    label {
      font-size: 16px;
      font-weight: 500;
    }
  }
}
