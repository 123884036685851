.Spacial-offerWrapper {
  display: flex;
  margin-bottom: 80px;
  justify-content: space-between;
  .lefttime-wrapper {
    width: 25%;
  }
  .right-sideWrapper {
    width: 70%;
  }
}
@media (max-width: 1198px) {
  .Spacial-offerWrapper {
    .lefttime-wrapper {
      width: 30%;
    }
    .right-sideWrapper {
      width: 65%;
    }
  }
}
@media (max-width: 992px) {
  .Spacial-offerWrapper {
    flex-direction: column;
    .lefttime-wrapper {
      width: 100%;
    }
    .right-sideWrapper {
      width: 100%;
      margin-top: 20px;
    }
  }
}
