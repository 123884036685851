.shopbyCategory-wrapper {
  padding: 10px;
  width: 100%;
  .shopbycategory-tag-wrapper {
    font-size: 24px;
    font-weight: 600;
  }
  .shopbycategorys-wrapp {
    padding: 15px 0px;
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
    .shopitemscategory-wrapper {
      width: 23%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      cursor: pointer;
      margin: 0px 8px;
      .shopitemscategory-wrapper-div {
        height: 200px;
        width: 100%;
        overflow: hidden;
        .category-img-wrapp {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .categoryimgname-wrapp {
        font-size: 14px !important;
        margin-top: 5px;
        display: -webkit-box;
        overflow: hidden;
        text-align: center;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }
  }
}

@media (max-width: 992px) {
  .shopbyCategory-wrapper {
    .shopbycategorys-wrapp {
      .shopitemscategory-wrapper {
        .categoryimgname-wrapp {
          font-size: 12px;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .shopbyCategory-wrapper {
    .shopbycategorys-wrapp {
      .shopitemscategory-wrapper {
        width: 22%;
        .categoryimgname-wrapp {
          font-size: 10px;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .shopbyCategory-wrapper {
    .shopbycategorys-wrapp {
      .shopitemscategory-wrapper {
        width: 30%;
        .categoryimgname-wrapp {
          font-size: 10px;
        }
      }
    }
  }
  .container,
  .container-sm {
    max-width: 650px;
  }
}

@media (max-width: 576px) {
  .shopitemscategory-wrapper {
    width: 45% !important;
  }
}
@media (max-width: 481px) {
  .shopitemscategory-wrapper {
    width: 100% !important;
  }
}
