.Deshbord-page-wrapper {
  height: auto;
  .welcome-wrapper {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .walcome-status-wrapper {
      .OrderDetailsline {
        font-size: 16px;
        text-transform: uppercase;
        color: #38465a;
        margin-bottom: 0;
        font-weight: bold;
      }
      .order-dates-wrapper {
        display: flex;
        justify-content: space-between;
        .orders-date {
          font-size: 12px;
          margin-right: 20px;
        }
        .order-number {
          font-size: 12px;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .Deshbord-page-wrapper {
    .welcome-wrapper {
      .order-dates-wrapper {
        flex-direction: column;

        .orders-date {
          margin-bottom: 5px;
        }
      }
    }
  }
}
